/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';
import { css } from '@emotion/react';
import qs from 'query-string';
import {
  datePickerToStringFormat,
  convertPhoneNumber,
  handleDownloadTemplate,
  arrayToStringParser,
  stringToArrayParser,
  fullCalendarTimeFormat,
} from 'helpers';
import Layout from 'components/layout';
import Sidebar from 'components/pages/PTP/Applicants/Sidebar/Sidebar';
import api from 'api/server';
import { Table, Input } from 'components/common';
import tableConfig from 'components/config/PTP/Applicants/tableConfig';
import {
  containerStyles,
  inputContainerStyles,
  tableHeaderContainerStyles,
  tableBodyStyles,
} from 'components/pages/PTP/Applicants/Applicant.styles';

const PTPApplicant = () => {
  const history = useHistory();
  const PART_TIME = 'PART_TIME';
  const queryString = history.location.search;
  const queryObject = qs.parse(queryString) || {};
  const modObj = {
    ...queryObject,
    program_type: PART_TIME,
    payment_types: typeof (queryObject.payment_types) === 'string' ? [queryObject.payment_types] : queryObject.payment_types,
    follow_up_statuses: typeof (queryObject.follow_up_statuses) === 'string' ? [queryObject.follow_up_statuses] : queryObject.follow_up_statuses,
    applicant_statuses: typeof (queryObject.applicant_statuses) === 'string' ? [queryObject.applicant_statuses] : queryObject.applicant_statuses,
  };
  const [loading, setLoading] = useState(true);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [filterCondition, setFilterCondition] = useState({
    program: '',
    lastBatch: '',
    paymentType: '',
    search: '',
    programLocation: '',
    applicantStatuses: [],
    followUpStatus: [],
  });
  const [dataApplicants, setDataApplicants] = useState([]);
  const [pagination, setPagination] = useState({});
  const [isCsvDownloading, setCsvDownloading] = useState(false);

  async function fetchData() {
    setLoading(true);
    try {
      const { data } = await api.getAllPtpApplicants(modObj);
      setDataApplicants(data.data.results);
      setPagination({
        current: data.data.current_page,
        total: data.data.last_page,
        totalData: data.data.total,
      });
    } catch (error) {
      if (error.response.status === 401) {
        Cookies.remove('access_token');
        history.replace('/sign-in');
      }
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
    setFilterCondition({
      program: queryObject.program_code ? { label: queryObject.program_code, value: queryObject.program_code } : '',
      lastBatch: queryObject.last_batch ? { label: queryObject.last_batch, value: queryObject.last_batch } : '',
      paymentType: stringToArrayParser(queryObject.payment_types),
      followUpStatus: stringToArrayParser(queryObject.follow_up_statuses),
      testResults: stringToArrayParser(queryObject.test_results),
      programLocation: queryObject.program_location,
      search: queryObject.search,
      applicantStatuses: stringToArrayParser(queryObject.applicant_statuses),
    });
    setDateRange(
      [queryObject.applied_start_date ? new Date(queryObject.applied_start_date) : null,
        queryObject.applied_end_date ? new Date(queryObject.applied_end_date) : null,
      ],
    );
  }, [queryString]);

  const applicantData = dataApplicants.map((d) => ({
    key: d.id,
    id: d.id,
    date: d.applied_date,
    email: d.email,
    name: d.name,
    phone: convertPhoneNumber(d.phone_number),
    batch: d.last_batch,
    price: d.final_price,
    program: 'ptp',
    payment: d.payment_type,
    paymentStatus: d.payment_status,
    lastContactedDate: fullCalendarTimeFormat(d.contacted_date),
    followUpStatus: d.follow_up_status?.replaceAll('_', ' '),
    code: d.last_batch.split('-')[0],
  }));

  const csvHeaders = [
    { label: 'Applicant ID', key: 'id' },
    { label: 'Name', key: 'name' },
    { label: 'Email', key: 'email' },
    { label: 'Price', key: 'final_price' },
    { label: 'Phone Number', key: 'phone_number' },
    { label: 'Applied date', key: 'applied_date' },
    { label: 'Batch', key: 'last_batch' },
    { label: 'Payment Type', key: 'payment_type' },
    { label: 'Payment Status', key: 'payment_status' },
  ];

  async function fetchDataCSV() {
    setCsvDownloading(true);
    try {
      const { data } = await api.getAllPtpApplicants({ ...modObj, limit: 20000 });
      handleDownloadTemplate(data.data.results, csvHeaders, 'ptp_applicant_export');
    } catch (error) {
      console.log(error, 'error fetch data csv');
    } finally {
      setCsvDownloading(false);
    }
  }

  const handleChange = (e) => {
    setFilterCondition({
      ...filterCondition,
      [e.target.name]: e.target.value,
    });
  };

  const handleSelect2Change = (e, name) => {
    setFilterCondition({
      ...filterCondition,
      [name]: e,
    });
  };

  const handleResetFilter = () => {
    setFilterCondition({
      program: '',
      lastBatch: '',
      paymentType: '',
      search: '',
      programLocation: '',
      applicantStatuses: [],
      followUpStatus: [],
    });
    setDateRange([null, null]);
    fetchData().then(() => {
      history.push({
        pathname: history.pathname,
      });
    });
  };

  const handleSubmitFilter = (e) => {
    e.preventDefault();
    const queryFilter = {
      program_code: filterCondition.program?.value,
      payment_types: arrayToStringParser(filterCondition.paymentType),
      follow_up_statuses: arrayToStringParser(filterCondition.followUpStatus),
      last_batch: filterCondition.lastBatch?.value,
      search: filterCondition.search,
      program_location: filterCondition.programLocation,
      applicant_statuses: arrayToStringParser(filterCondition.applicantStatuses),
      applied_start_date: dateRange[0] ? datePickerToStringFormat(JSON.stringify(dateRange[0])) : '',
      applied_end_date: dateRange[1] ? datePickerToStringFormat(JSON.stringify(dateRange[1])) : '',
    };
    const queryParam = qs.stringify(queryFilter, { skipNull: true, skipEmptyString: true });
    history.push({
      pathname: history.pathname,
      search: queryParam,
    });
  };

  return (
    <Layout
      sidebar={(
        <Sidebar
          startDate={startDate}
          endDate={endDate}
          setDateRange={setDateRange}
          filterCondition={filterCondition}
          setFilterCondition={setFilterCondition}
          handleChange={handleChange}
          handleSelect2Change={handleSelect2Change}
          handleSubmit={handleSubmitFilter}
          handleReset={handleResetFilter}
          handleDownloadCSV={fetchDataCSV}
          isCsvDownloading={isCsvDownloading}
        />
      )}
    >
      <div css={containerStyles}>
        <span>
          total record:&nbsp;
          {pagination.totalData}
        </span>
        <form action="" css={css`width: 20%;`} onSubmit={handleSubmitFilter}>
          <Input
            containerStyles={inputContainerStyles}
            type="text"
            name="search"
            value={filterCondition.search}
            onChange={handleChange}
            placeholder="search by name / email"
          />
        </form>
      </div>
      {
        !loading && (
          <Table
            containerStyles={css`height: 80vh;`}
            tableHeaderContainerCustomStyles={tableHeaderContainerStyles}
            tableBodyStyles={tableBodyStyles}
            tableConfig={tableConfig}
            history={history}
            pagination={pagination}
            setPagination={setPagination}
            data={applicantData}
            isHoverable
          />
        )
      }
    </Layout>
  );
};
export default PTPApplicant;
