import { css } from '@emotion/react';

export const containerStyles = css`
  width: 90%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const childrenContainerStyles = css`
  width: 100%;
  margin: 0 auto 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: none;
`;

export const navbarContainerStyles = css`
  width: calc(90% - 40px);
  padding: 0 10px;
  border: none;
`;

export const cardStyles = css`
  display: flex;
  flex-direction: column;
  margin: 0 0 15px;
  border: 1px solid black;
  width: 60%;
  padding: 8px 16px;
  border-radius: 5px;

  label {
    font-weight: bold;
    padding: 5px 10px;
    width: fit-content;
    text-transform: capitalize;
  }

  p {
    padding: 5px 0;
    margin: 0;
    font-size: 16px;

    &:first-letter {
      text-transform: uppercase;
    }
  }
`;

export const paragraphStyles = css`
  &.timeline {
    box-shadow: rgb(0 0 0 / 10%) 0px 1px 5px 0px;
    
    div {
      box-shadow: 0;
      width: unset!important;
      
      div {
        font-weight: bold;
        font-size: 20px;
        padding: 5px 10px;
      }

      p {
        margin: 0;
        font-size: 16px;

        &::first-letter {
          text-transform: uppercase;
        }
      }
    }
  }
`;

export const activitiesContainerStyles = css`
  height: 75vh;
  overflow-y: scroll;
`;

export const loadMoreButtonStyles = css`
  width: fit-content;
  color: #fff;
  text-transform: capitalize;
  margin: 20px 0 0;
`;
