/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import PropTypes from 'prop-types';

import { selectContainerStyles, selectStyles, selectLabelStyles } from './Select.styles';

const Select = ({
  className,
  containerStyles,
  styles,
  label,
  labelStyles,
  name,
  value,
  onChange,
  options,
  placeholder,
  disabled,
  ...rest
}) => (
  <div css={[selectContainerStyles, containerStyles]}>
    <label css={[selectLabelStyles, labelStyles]}>{label}</label>
    <select
      css={[selectStyles, styles]}
      className={className}
      name={name}
      value={value}
      disabled={disabled}
      onChange={onChange}
      {...rest}
    >
      {placeholder ? <option value="">{placeholder}</option> : <></>}
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  </div>
);

Select.propTypes = {
  containerStyles: PropTypes.shape({}),
  styles: PropTypes.shape({}),
  label: PropTypes.string,
  labelStyles: PropTypes.shape({}),
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  placeholder: PropTypes.string,
};

Select.defaultProps = {
  containerStyles: css``,
  styles: css``,
  label: '',
  labelStyles: css``,
  disabled: false,
  className: '',
  placeholder: '',
  value: '',
  onChange: () => {},
};

export default Select;
