/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import qs from 'query-string';
import { useParams, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import api from 'api/server';
import {
  fetchBatchOptions,
  fetchApplicantStatusOptions,
  fetchStudentStatusOptions,
  fetchPaymentMethodOptions,
  fetchPaymentStatusOptions,
} from 'helpers';
import {
  Button, Select, Select2,
} from 'components/common';
import interviewerOptions from 'constants/interviewers';

import {
  containerStyles,
  rowContainerStyles,
  inputContainerStyles,
  textareaStyles,
} from './MoveProgram.styles';

const MoveProgram = ({
  data, setData, handleChange, handleSubmit, handleSelect2Change,
}) => {
  const { id } = useParams();
  const query = qs.parse(useLocation().search);
  const [options, setOptions] = useState({
    paymentOptions: [],
    batchOptions: [],
    applicantStatusOptions: [],
    studentStatusOptions: [],
    paymentStatusOptions: [],
  });

  async function fetchPersonalData() {
    const response = await api.getApplicantPersonalData(id);
    const appData = response.data.data;

    setData((prevState) => ({
      ...prevState,
      paymentTypeId: +appData.payment_type_id,
      currentBatchId: +appData.current_batch_id,
      updatedBatchId: '',
      applicantStatusId: appData.applicant_status_id,
      studentStatusId: appData.student_status_id,
    }));
  }

  const getPaymentOptions = () => fetchPaymentMethodOptions().then((resp) => resp.map((d) => ({
    label: d.label,
    value: d.id,
  })));

  const getBatchOptions = () => fetchBatchOptions(query.program === 'ftp').then((resp) => resp.map((d) => ({
    label: d.label,
    value: d.id,
  })));

  const getApplicantStatusOptions = () => fetchApplicantStatusOptions()
    .then((resp) => resp.map((d) => ({
      label: d.label,
      value: d.id,
    })));

  const getStudentStatusOptions = () => fetchStudentStatusOptions()
    .then((resp) => resp.map((d) => ({
      label: d.label,
      value: d.id,
    })));

  const getPaymentStatusOptions = () => fetchPaymentStatusOptions()
    .then((resp) => resp.map((d) => ({
      label: d.label,
      value: d.id,
    })));

  useEffect(async () => {
    const [
      paymentOptions,
      batchOptions,
      applicantStatusOptions,
      studentStatusOptions,
      paymentStatusOptions,
    ] = await Promise.all([
      getPaymentOptions(),
      getBatchOptions(),
      getApplicantStatusOptions(),
      getStudentStatusOptions(),
      getPaymentStatusOptions(),
      fetchPersonalData(),
    ]);
    setOptions((prevState) => ({
      ...prevState,
      paymentOptions,
      batchOptions,
      applicantStatusOptions,
      studentStatusOptions,
      paymentStatusOptions,
    }));
  }, []);

  async function handleSubmitAndRefetch(e) {
    await handleSubmit(e);
    fetchPersonalData();
  }

  return (
    <div css={containerStyles}>
      <div css={rowContainerStyles}>
        <label>current batch</label>
        <Select
          name="currentBatchId"
          options={options.batchOptions}
          onChange={handleChange}
          value={data.currentBatchId}
          disabled
        />
      </div>
      <div css={rowContainerStyles}>
        <label>batch</label>
        <Select2
          placeholder="updated batch"
          defaultValue={{ label: 'updated batch', value: '' }}
          options={options.batchOptions}
          onChange={(e) => handleSelect2Change(e)}
          value={data.updatedBatchId}
        />
      </div>
      <div css={rowContainerStyles}>
        <label>payment type</label>
        <Select
          name="paymentTypeId"
          options={options.paymentOptions}
          onChange={handleChange}
          value={data.paymentTypeId}
          placeholder="select payment type"
        />
      </div>
      <div css={rowContainerStyles}>
        <label>student status</label>
        <Select
          name="studentStatusId"
          options={options.studentStatusOptions}
          onChange={handleChange}
          value={data.studentStatusId}
          placeholder="select student status"
        />
      </div>
      <div css={rowContainerStyles}>
        <label>applicant status</label>
        <Select
          name="applicantStatusId"
          options={options.applicantStatusOptions}
          onChange={handleChange}
          value={data.applicantStatusId}
          placeholder="select applicant status"
        />
      </div>
      <div css={rowContainerStyles}>
        <label>payment status</label>
        <Select
          name="paymentStatus"
          options={options.paymentStatusOptions}
          onChange={handleChange}
          value={data.paymentStatus}
          placeholder="select payment status"
        />
      </div>
      <div css={rowContainerStyles}>
        <label>PIC</label>
        <Select
          containerStyles={inputContainerStyles}
          name="pic"
          options={interviewerOptions}
          onChange={handleChange}
          value={data.pic}
          placeholder="choose interviewer"
        />
      </div>
      <div css={rowContainerStyles}>
        <label>notes</label>
        <textarea
          name="notes"
          css={textareaStyles}
          rows="2"
          value={data.notes}
          onChange={handleChange}
        />
      </div>
      <div css={rowContainerStyles}>
        <Button
          type="button"
          onClick={handleSubmitAndRefetch}
        >
          update program
        </Button>
      </div>
    </div>
  );
};

export default MoveProgram;

MoveProgram.propTypes = {
  data: PropTypes.shape({
    currentBatchId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    updatedBatchId: PropTypes.string,
    paymentTypeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    phase: PropTypes.string,
    studentStatusId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    paymentStatus: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    applicantStatusId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    pic: PropTypes.string,
    notes: PropTypes.string,
  }).isRequired,
  setData: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSelect2Change: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};
