/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import Snackbar from '../Snackbar/Snackbar';

import {
  reactSelectContainerStyles,
  labelStyles,
  reactSelectStyles,
} from './CustomCreatableSelect.styles';

const Option = ({ isSelected, label, ...rest }) => (
  <div>
    <components.Option {...rest}>
      <input
        type="checkbox"
        checked={isSelected}
        onChange={() => null}
      />
      <label>{label}</label>
    </components.Option>
  </div>
);

const CustomCreatableSelect = ({
  filterCondition,
  setFilterCondition,
  containerStyles,
  label,
  options,
  value,
  placeholder,
  name,
}) => {
  const [isToastOpen, setToast] = useState(false);
  const [msg, setMsg] = useState('');
  // const defaultValue = options.map(({ label: optionLabel, value: optionValue }) => (
  //   {
  //     label: optionLabel, value: optionValue,
  //   }));

  const handleMultiValueChange = (d, filterName) => {
    setMsg('');
    if (filterCondition[filterName].length === 1 && !d.length) {
      setToast(true);
      setMsg('At least one option has to be ticked');
    } else {
      setFilterCondition((prevState) => ({
        ...prevState,
        [filterName]: d,
      }));
    }
  };
  return (
    <div css={[reactSelectContainerStyles, containerStyles]}>
      {placeholder && <span css={labelStyles}>{placeholder}</span>}
      <CreatableSelect
        css={reactSelectStyles(label)}
        classNamePrefix="select"
        name={name}
        options={options}
        value={value}
        // value={value.length ? value : defaultValue}
        onChange={(e) => handleMultiValueChange(e, name)}
        isSearchable
        placeholder=""
        isMulti
        components={{ Option }}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
      />
      <Snackbar
        message={msg}
        setOpen={setToast}
        open={isToastOpen}
      />
    </div>
  );
};

CustomCreatableSelect.propTypes = {
  containerStyles: PropTypes.shape({}),
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  ),
  value: PropTypes.oneOfType([
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
    PropTypes.arrayOf(PropTypes.shape({})),
  ]).isRequired,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  filterCondition: PropTypes.shape({}).isRequired,
  setFilterCondition: PropTypes.func.isRequired,
};

Option.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
};

CustomCreatableSelect.defaultProps = {
  containerStyles: css``,
  label: '',
  options: [],
  placeholder: '',
  name: '',
};

export default CustomCreatableSelect;
