import { css } from '@emotion/react';

import color from 'components/common/color';

export const modalStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    boxShadow: '0px 3px 30px #0000000d',
    width: '800px',
    overflow: 'hidden',
  },
};

export const formStyles = css`
  display: flex;
  flex-direction: column;
  padding: 10px;
`;

export const datepickerContainerStyles = css`
width: 100%;
display: flex;
justify-content: center;

.react-datepicker-wrapper {
  width: 100%;

  .react-datepicker__input-container {
    width: 100%;
    position: relative;

    input[type=text] {
      width: calc(100% - 12px);
      padding: 5px;
      height: 25px;
      border-radius: 4px;
      border: 1px solid ${color.BLACK};
      font-size: 12.5px;
      color: ${color.BLACK};
      outline: none;

      &:hover {
        border: 1px solid ${color.ORANGE};
      }

      &::placeholder {
        text-transform: capitalize;
        font-size: 12px;
      }
    }
  }

  .react-datepicker-popper {
    z-index: 100;
  }
`;

export const formRowContainerStyles = css`
  display: flex;
  flex-direction: column;
  margin: 0 0 12px;
`;

export const inputStyles = css`
  border-radius: 4px;
  height: 25px;
`;

export const selectStyles = css`
  height: 40px;

  option {
    text-transform: none;
  }
`;

export const selectInterviewStyles = css`
  text-transform: lowercase;
`;
