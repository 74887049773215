/* eslint-disable */
import React from 'react';
import { fullCalendarFormat } from 'helpers';

const tableConfig = [
  {
    key: 'id',
    label: 'ID',
    ratio: 0.05,
    textAlign: 'left',
    component: (d) => (
      <span style={{ marginLeft: '5px' }}>
        {d}
      </span>
    ),
  },
  {
    key: 'registered_batch',
    label: 'applied batch',
    ratio: 0.15,
    textAlign: 'left',
    component: (d) => (
      <span style={{ marginLeft: '5px' }}>
        {d}
      </span>
    ),
  },
  {
    key: 'name',
    label: 'name',
    ratio: 0.2,
    textAlign: 'left',
    component: (d) => (
      <span style={{ marginLeft: '5px' }}>
        {d}
      </span>
    ),
  },
  {
    key: 'payment_type',
    label: 'payment type',
    ratio: 0.1,
    textAlign: 'left',
    component: (d) => (
      <span style={{ fontSize: 12 }}>
        {/* {fullCalendarFormat(d)} */}
        {d ? d.toUpperCase() : ''}
      </span>
    ),
  },
  {
    key: 'payment_status',
    label: 'payment status',
    ratio: 0.15,
    textAlign: 'center',
    component: (d) => (
      <span style={{ marginLeft: '5px' }}>
        {/* {fullCalendarFormat(d)} */}
        {d ? d.replace('_', ' ').toUpperCase() : ''}
      </span>
    ),
  },
  {
    key: 'payment_due',
    label: 'payment due date',
    ratio: 0.125,
    component: (d) => (
      <span style={{ marginLeft: '5px' }}>
        {fullCalendarFormat(d)}
        {/* {d} */}
      </span>
    ),
  },
  {
    key: 'enrolled_batch',
    label: 'enrolled batch',
    ratio: 0.125,
    textAlign: 'left',
    component: (d) => (
      <span style={{ marginLeft: '5px' }}>
        {d}
      </span>
    ),
  },
  {
    key: 'status',
    label: 'student status',
    ratio: 0.1,
    textAlign: 'center',
    component: (d) => (
      <span style={{ marginLeft: '5px' }}>
        {d ? d.replace('_', ' ') : ''}
      </span>
    ),
  },
];

export default tableConfig;
