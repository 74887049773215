/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  fetchBatchOptions,
  fetchPaymentMethodOptions,
  fetchStudentStatusOptions,
} from 'helpers';

import Sidebar from 'components/layout/Sidebar/Sidebar';
import {
  CustomDatepicker as Datepicker,
  Select2,
  Select,
  Button,
  CreatableSelect,
} from 'components/common';
import {
  filterConditionsContainerStyles,
  datepickerContainerStyles,
  select2ContainerStyles,
  selectContainerStyles,
  buttonContainerStyles,
  buttonStyles,
} from './Sidebar.styles';

const FTPSidebar = ({
  startDate,
  endDate,
  setDateRange,
  filterCondition,
  setFilterCondition,
  handleChange,
  handleSelect2Change,
  handleSubmit,
  handleReset,
}) => {
  const [options, setOptions] = useState({
    batchOptions: [],
    studentStatusOptions: [],
    paymentMethodOptions: [],
  });

  const getBatchOptions = () => fetchBatchOptions(true).then((resp) => resp);

  const getStudentStatusOptions = () => fetchStudentStatusOptions().then((resp) => resp);
  const getPaymentMethodOptions = () => fetchPaymentMethodOptions().then((resp) => resp);

  useEffect(async () => {
    const [
      batchOptions,
      studentStatusOptions,
      paymentMethodOptions,
    ] = await Promise.all([
      getBatchOptions(),
      getStudentStatusOptions(),
      getPaymentMethodOptions(),
    ]);
    setOptions((prevState) => ({
      ...prevState,
      batchOptions,
      studentStatusOptions,
      paymentMethodOptions,
    }));
  }, []);

  return (
    <Sidebar>
      <div css={filterConditionsContainerStyles}>
        <Datepicker
          containerStyles={datepickerContainerStyles}
          startDate={startDate}
          endDate={endDate}
          setDateRange={setDateRange}
          placeholder="date range"
        />
        <Select2
          containerStyles={select2ContainerStyles}
          defaultValue={{ label: 'registered batch', value: '' }}
          options={options.batchOptions}
          placeholder="registered batch"
          onChange={(e) => handleSelect2Change(e, 'appliedBatch')}
          value={filterCondition.appliedBatch}
        />
        <Select2
          containerStyles={select2ContainerStyles}
          defaultValue={{ label: 'enrolled batch', value: '' }}
          options={options.batchOptions}
          placeholder="enrolled batch"
          onChange={(e) => handleSelect2Change(e, 'enrolledBatch')}
          value={filterCondition.enrolledBatch}
        />
        <CreatableSelect
          containerStyles={select2ContainerStyles}
          options={options.paymentMethodOptions}
          placeholder="payment type"
          value={filterCondition.paymentType}
          filterCondition={filterCondition}
          setFilterCondition={setFilterCondition}
          name="paymentType"
        />
        <Select
          containerStyles={selectContainerStyles}
          name="studentStatus"
          options={options.studentStatusOptions}
          placeholder="student status"
          value={filterCondition.studentStatus}
          onChange={handleChange}
        />
      </div>
      <div css={buttonContainerStyles}>
        <Button type="button" onClick={handleSubmit} styles={buttonStyles}>apply</Button>
        <Button type="button" onClick={handleReset} className="reset" styles={buttonStyles}>reset filter</Button>
      </div>
    </Sidebar>
  );
};

FTPSidebar.propTypes = {
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  setDateRange: PropTypes.func.isRequired,
  filterCondition: PropTypes.shape({
    appliedBatch: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
    enrolledBatch: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
    application_result: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
    test_result: PropTypes.string,
    appliedProgramCode: PropTypes.string,
    paymentType: PropTypes.string,
    paymentStatus: PropTypes.string,
    studentStatus: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  setFilterCondition: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSelect2Change: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
  // handleDownloadCSV: PropTypes.func.isRequired,
  // isCsvDownloading: PropTypes.bool.isRequired,
};

FTPSidebar.defaultProps = {
  startDate: null,
  endDate: null,
};

export default FTPSidebar;
