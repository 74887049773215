import api from 'api/server';

export async function fetchPaymentMethodOptions() {
  const { data } = await api.getAllPaymentTypes();
  const paymentMethodArray = data.data.map((d) => ({
    label: d.value.toLowerCase(),
    value: d.name,
    id: d.id,
  }));
  return paymentMethodArray;
}

export async function fetchPaymentStatusOptions() {
  const { data } = await api.getAllPaymentStatuses();
  const paymentStatusArray = data.data.map((d) => ({
    label: d.value,
    value: d.name,
  }));
  return paymentStatusArray;
}

export async function fetchProgramOptions() {
  const { data } = await api.getAllPrograms('?limit=50');
  const programsArray = data.data
    .map((d) => ({
      label: d.code,
      value: d.code,
    }));
  return programsArray;
}

export async function fetchProgramCodeOptions() {
  const { data } = await api.getAllProgramCodes('?limit=50');
  const programCodesArray = data.data.map((d) => ({
    label: d.value,
    value: d.name,
  }));
  return programCodesArray;
}

export async function fetchFollowUpStatusOptions() {
  const { data } = await api.getAllFollowUpStatuses();
  const followUpStatusArr = data.data.map((d) => ({
    label: d.value.toLowerCase(),
    value: d.name,
    id: d.id,
  }));
  return followUpStatusArr;
}

export async function fetchApplicantStatusOptions() {
  const { data } = await api.getAllApplicantStatuses();
  const applicantStatusArr = data.data.map((d) => ({
    label: d.value,
    value: d.name,
    id: d.id,
  }));
  return applicantStatusArr;
}

export async function fetchStudentStatusOptions() {
  const { data } = await api.getAllStudentStatuses();
  const studentStatusArr = data.data.map((d) => ({
    label: d.value,
    value: d.name,
    id: d.id,
  }));
  return studentStatusArr;
}

export async function fetchCampusLocationOptions() {
  const { data } = await api.getAllCampusLocations();
  const campusLocationsArr = data.data.map((d) => ({
    label: d.location,
    value: d.code,
    id: d.id,
  }));
  return campusLocationsArr;
}

export async function fetchBatchOptions(bool) {
  const { data } = await api.getAllBatchesOptions({ is_full_time: bool });
  const batchesArray = data.data.results.map((d) => ({
    label: d.code,
    value: d.code,
    id: d.id,
  }));
  return batchesArray;
}
