/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';
import { css } from '@emotion/react';
import { CommonLoading } from 'react-loadingg';
import qs from 'query-string';
import api from 'api/server';
import Layout from 'components/layout';
import {
  fullCalendarFormat,
  datePickerToStringFormat,
  arrayToStringParser,
  stringToArrayParser,
} from 'helpers';
import { Button, Table, Input } from 'components/common';
import tableConfig from 'components/config/Batch/tableConfig';
import {
  AddBatchModal,
  EditBatchModal,
  Sidebar,
} from 'components/pages/batch';
import * as styles from 'components/pages/batch/batch.styles';

const Batch = () => {
  const history = useHistory();
  const queryString = history.location.search || '';
  const queryObject = qs.parse(queryString, { }) || {};
  const modObj = {
    ...queryObject,
    limit: 25,
  };
  const [loading, setLoading] = useState(true);
  const [dataBatches, setDataBatches] = useState([]);
  const [pagination, setPagination] = useState({});
  const [isAddModalOpen, setAddModal] = useState(false);
  const [isEditModalOpen, setEditModal] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [options, setOptions] = useState({
    batchOptions: [],
    batchTypeOptions: [],
    campusLocations: [],
    programOptions: [],
  });
  const [filterCondition, setFilterCondition] = useState({
    registrationDate: [null, null],
    classDate: [null, null],
    registrationStatus: '',
    programs: [],
    graduationDate: [null, null],
    search: '',
  });
  const [zIndex, setZIndex] = useState('');

  const fetchBatchTypes = async () => {
    const { data } = await api.getBatchTypes();
    const modifiedArr = data.data.map(({ id, value }) => ({
      label: value,
      value: id,
    }));
    return modifiedArr;
  };

  const fetchAllBatches = async () => {
    const { data } = await api.getAllBatchesOptions();
    const modifiedArr = data.data.results.map(({ code }) => ({ code }));
    return modifiedArr;
  };

  const fetchAllPrograms = async () => {
    const { data } = await api.getAllPrograms();
    const modifiedArr = data.data.map(({ id, code, name }) => ({
      id,
      value: code,
      label: `${code} - ${name}`,
    }));
    return modifiedArr;
  };

  const fetchAllCampusLocations = async () => {
    const { data } = await api.getAllCampusLocations();
    const arr = data.data.map(({ location, id, code }) => ({
      code,
      value: id,
      label: `${code} - ${location}`,
    }));
    return arr;
  };

  useEffect(async () => {
    const [
      batchOptions,
      batchTypeOptions,
      programOptions,
      campusLocations,
    ] = await Promise.all([
      fetchAllBatches(),
      fetchBatchTypes(),
      fetchAllPrograms(),
      fetchAllCampusLocations(),
    ]);
    setOptions((prevState) => ({
      ...prevState,
      batchOptions,
      batchTypeOptions,
      campusLocations,
      programOptions,
    }));
  }, []);

  const compareDate = (start, end) => {
    const now = new Date();
    if (!start || !end) {
      return 'invalid';
    }
    const startDate = new Date(start);
    const endDate = new Date(end);
    if (startDate <= now && now <= endDate) {
      return 'open';
    }
    return 'closed';
  };

  async function fetchData() {
    setLoading(true);
    try {
      const { data } = await api.getAllBatches(modObj);
      const response = data.data;
      if (data) {
        setDataBatches(response.results);
        setPagination({
          current: response.current_page || 1,
          total: response.last_page || 1,
          totalData: response.total || response.results.length,
        });
      }
    } catch (error) {
      if (error.response.status === 401) {
        Cookies.remove('access_token');
        history.replace('/sign-in');
      }
    } finally {
      setLoading(false);
    }
  }

  const handleQueryDate = (date) => {
    if (queryObject[date]) return new Date(queryObject[date]);
    return null;
  };

  useEffect(() => {
    fetchData();
    setFilterCondition(() => ({
      registrationDate: [null, null],
      classDate: [handleQueryDate('filter_class_start_from'),
        handleQueryDate('filter_class_start_until')],
      registrationStatus: queryObject.filter_registration_status,
      graduationDate: [handleQueryDate('filter_graduation_date_from'),
        handleQueryDate('filter_graduation_date_until')],
      programs: stringToArrayParser(queryObject.filter_program),
      search: queryObject.search || '',
    }));
  }, [queryString]);

  const batchesData = dataBatches.map((d) => ({
    key: d.id,
    id: d.id,
    callAttempt: d.call,
    date: fullCalendarFormat(d.created_at),
    programName: d.program?.name,
    name: (
      <span
        role="button"
        onKeyPress={() => {}}
        onKeyDown={() => {}}
        tabIndex={-1}
        onClick={() => {
          setSelectedData(d);
          setEditModal(true);
        }}
        css={styles.buttonNameStyles}
      >
        {d.code}
      </span>
    ),
    openDate: fullCalendarFormat(d.registration_start_date),
    closedDate: fullCalendarFormat(d.registration_end_date),
    classDate: fullCalendarFormat(d.class_start_date),
    graduationDate: fullCalendarFormat(d.graduation_date),
    earlyBirdPrice: d.discount_price?.toLocaleString('en-US'),
    normalPrice: d.base_price?.toLocaleString('en-US'),
    status: compareDate(d.registration_start_date, d.registration_end_date),
  }));

  const handleSubmitFilter = (e) => {
    e.preventDefault();
    const queryFilter = {
      filter_graduation_date_from: filterCondition.graduationDate[0] ? datePickerToStringFormat(JSON.stringify(filterCondition.graduationDate[0])) : '',
      filter_graduation_date_until: filterCondition.graduationDate[1] ? datePickerToStringFormat(JSON.stringify(filterCondition.graduationDate[1])) : '',
      filter_class_start_from: filterCondition.classDate[0] ? datePickerToStringFormat(JSON.stringify(filterCondition.classDate[0])) : '',
      filter_class_start_until: filterCondition.classDate[1] ? datePickerToStringFormat(JSON.stringify(filterCondition.classDate[1])) : '',
      filter_registration_status: filterCondition.registrationStatus,
      filter_program: arrayToStringParser(filterCondition.programs),
      search: filterCondition.search,
    };
    const queryParam = qs.stringify(queryFilter, { skipNull: true, skipEmptyString: true });
    history.push({
      pathname: history.pathname,
      search: queryParam,
    });
  };

  const handleResetFilter = () => {
    setFilterCondition({
      registrationDate: [null, null],
      classDate: [null, null],
      registrationStatus: '',
      programs: [],
      graduationDate: [null, null],
      search: '',
    });
    fetchData().then(() => {
      history.push({
        pathname: history.pathname,
      });
    });
  };

  useEffect(() => {
    if (isAddModalOpen || isEditModalOpen) {
      setZIndex(-1);
    } else {
      setZIndex(1);
    }
  }, [isAddModalOpen, isEditModalOpen]);

  return (
    <Layout
      containerStyles={`z-index: ${zIndex};`}
      sidebar={(
        <Sidebar
          programOptions={options.programOptions}
          filterCondition={filterCondition}
          setFilterCondition={setFilterCondition}
          handleSubmit={handleSubmitFilter}
          handleReset={handleResetFilter}
        />
      )}
    >
      {
        loading ? <CommonLoading /> : (
          <>
            <div css={styles.searchContainerStyles}>
              <Button
                type="button"
                styles={styles.buttonStyles}
                onClick={() => setAddModal(true)}
              >
                create new batch
              </Button>
              <form onSubmit={handleSubmitFilter}>
                <Input
                  name="batch"
                  type="text"
                  value={filterCondition.search}
                  onChange={(e) => {
                    setFilterCondition((prevState) => ({
                      ...prevState,
                      search: e.target.value,
                    }));
                  }}
                  placeholder="search batch"
                  styles={styles.inputStyles}
                />
              </form>
            </div>
            <Table
              containerStyles={css`height: 80vh; width: 100%;`}
              tableHeaderContainerCustomStyles={styles.tableHeaderContainerStyles}
              tableBodyStyles={styles.tableBodyStyles}
              tableConfig={tableConfig}
              history={history}
              pagination={pagination}
              setPagination={setPagination}
              data={batchesData}
              isHoverable={false}
            />
            <AddBatchModal
              isModalOpen={isAddModalOpen}
              setModal={setAddModal}
              setDataBatches={setDataBatches}
              options={options}
              setPagination={setPagination}
            />
            <EditBatchModal
              isModalOpen={isEditModalOpen}
              setModal={setEditModal}
              data={selectedData}
              setSelectedData={setSelectedData}
              setDataBatches={setDataBatches}
              options={options}
            />
          </>
        )
      }
    </Layout>
  );
};
export default Batch;
