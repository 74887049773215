import { css } from '@emotion/react';

export const layoutStyles = css`
  font-family: "Roboto", sans-serif;
  position: relative;
  z-index: 1;
`;

export const navbarContainerStyles = css`
  padding: 0 0 0 10px;
  position: relative;
`;

export const childrenStyles = css`
  overflow-y: auto;
  padding: 0 0 0 10px;
  position: relative;
  z-index: -1;

  &::-webkit-scrollbar {
    display: none;
  }
`;
