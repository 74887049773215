import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getSession } from 'helpers';

import ROUTES from './Router.routes';

const ProtectedRoute = ({
  component: Component,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      const { match } = props;
      if (getSession()) {
        if (match.path !== ROUTES.SIGNIN) {
          return <Component {...props} />;
        }
        if (match.path === ROUTES.SIGNIN) {
          return <Redirect to={ROUTES.APPLICANTS} />;
        }
      }
      if (match.path === ROUTES.SIGNIN) {
        return <Component {...props} />;
      }
      return <Redirect to={ROUTES.SIGNIN} />;
    }}
  />
);

ProtectedRoute.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
  }),
  component: PropTypes.func.isRequired,
};

ProtectedRoute.defaultProps = {
  match: {},
};

export default ProtectedRoute;
