import { css } from '@emotion/react';

import color from 'components/common/color';

export const layoutContainerStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  padding: 0 20px;

  @media screen and (max-width: 1279px) {
    height: calc(100vh - 40px);
    padding: 20px;
  }
`;

export const textStyles = css`
  font-size: 16px;
  font-weight: 500;

  &.error {
    color: ${color.DANGER_RED};
    margin: 20px 0 0;
  }
`;

export const errMesasgeStyles = css`
  font-size: 16px;
  text-transform: capitalize;
  margin: 10px 0 0;
  color: ${color.DANGER_RED};
`;

export const signinContainerStyles = css`
  background: #f7f7f7;
  border-radius: 4px;
  border: 1px solid #e6e6e6;
  padding: 20px 30px 40px;
  margin: 25px 0 0;
  width: 20%;

  @media screen and (max-width: 1279px) {
    width: unset;
  }
`;

export const inputContainerStyles = css`
  width: 100%;
  margin: 0 0 10px;
  
  input {
    width: calc(100% - 10px);
    height: 35px;
    border: 1px solid #e0e0e0;
  }
`;

export const buttonGoogleStyles = css`
  display: flex;
  align-items: center;
  padding: 0;
  height: 50px;
  margin: 15px 0 0;
  background: #4286f5;
  width: 200px;
  max-width: 250px;
  border-radius: 4px;
`;

export const iconContainerStyles = css`
  width: 20%;
  height: 100%;
  display: flex;
  border-color: #4286f5;
  border-radius: 4px 0 0 4px;
  align-items: center;
  justify-content: center;
  background: ${color.WHITE};

  i {
    color: black;
    font-size: 20px;
  }
`;

export const textContainerStyles = css`
  width: 80%;
  height: 100%;
  color: ${color.WHITE};
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
`;
