import { css } from '@emotion/react';

export const tableHeaderContainerStyles = css`
  width: 2000px;
`;

export const tableBodyStyles = css`
  width: 2000px;
`;

export const containerStyles = css`
  display: flex;
  align-items: center;
  padding: 0 50px 0 20px;
  justify-content: space-between;

  span {
    font-size: 18px;
    
    &::first-letter {
      text-transform: capitalize;
    }
  }
`;

export const inputContainerStyles = css`
  height: 40px;
  width: 100%;

  input {
    height: 30px;
    width: 100%;
  }
`;
