function convertPhoneNumber(num) {
  let result = num;
  const twoFrontDigits = result.slice(0, 2);
  const threeFrontDigits = result.slice(0, 3);
  if (threeFrontDigits === '+62') {
    result = `0${num.slice(3, num.length)}`;
  } else if (twoFrontDigits !== '08') {
    result = `0${num.slice(2, num.length)}`;
  } else {
    result = `${result.slice(0, 4)}${result.slice(4, 8)}${result.slice(8, result.length - 1)}`;
  }
  return result;
}

export default convertPhoneNumber;
