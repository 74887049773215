/** @jsxImportSource @emotion/react */
import ReactPaginate from 'react-paginate';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import qs from 'query-string';

import { paginationContainerStyle } from './Pagination.styles';

const Pagination = ({
  containerStyles,
  history,
  pagination,
  setPagination,
}) => {
  const { current, total } = pagination;
  const queryParam = qs.parse(history.location.search);

  const handlePagination = (e) => {
    const page = e.selected + 1;
    setPagination((prevState) => ({
      ...prevState,
      current: page,
    }));
    const newQueryParam = {
      ...queryParam,
      page,
    };
    history.push({
      pathname: history.pathname,
      search: qs.stringify(newQueryParam),
    });
  };

  return (
    <div css={[paginationContainerStyle(current, total), containerStyles]}>
      <ReactPaginate
        previousLabel="<"
        nextLabel=">"
        marginPagesDisplayed={1}
        pageRangeDisplayed={2}
        pageCount={total}
        forcePage={current - 1}
        className="pagination"
        onPageChange={handlePagination}
      />
    </div>
  );
};

Pagination.propTypes = {
  containerStyles: PropTypes.shape({}),
  history: PropTypes.shape({
    pathname: PropTypes.string,
    push: PropTypes.func,
    location: PropTypes.shape({
      search: PropTypes.string,
    }),
  }).isRequired,
  pagination: PropTypes.shape({
    current: PropTypes.number,
    total: PropTypes.number,
  }).isRequired,
  setPagination: PropTypes.func.isRequired,
};

Pagination.defaultProps = {
  containerStyles: css``,
};

export default Pagination;
