/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { Timeline, TimelineEvent } from 'react-event-timeline';
import { Notifications } from '@material-ui/icons';
import { calendarFormat } from 'helpers';
import { containerStyles, paragraphStyles } from './ActivityLog.styles';

const ActivityLog = ({ data }) => (
  <div css={containerStyles}>
    <Timeline>
      {data.map((d) => (
        <div key={d.id}>
          <TimelineEvent
            css={paragraphStyles}
            className="timeline"
            createdAt={`Updated at ${calendarFormat(d.updated_at)}`}
            icon={<Notifications />}
          >
            {
              d.change_log.split('|').map((l) => (
                <p key={`${l}${d.id}`}>{l ? l.replace('_', ' ') : null}</p>
              ))
            }
          </TimelineEvent>
        </div>
      ))}
    </Timeline>
  </div>
);

ActivityLog.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    created_at: PropTypes.string,
    updated_at: PropTypes.string,
    actory: PropTypes.string,
    change_log: PropTypes.string,
  })).isRequired,
};

export default ActivityLog;
