/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';

import { sidebarStyles, logoStyles, sidebarContainerStyles } from './Sidebar.styles';

const Sidebar = ({ children }) => (
  <div css={sidebarStyles}>
    <div css={logoStyles}>
      <img src="/h8.jpeg" alt="me" width="150" height="150" />
    </div>
    <div css={sidebarContainerStyles}>
      {children}
    </div>
  </div>
);

Sidebar.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Sidebar;
