/* eslint-disable */
/** @jsxImportSource @emotion/react */
import { fullCalendarTimeFormat } from 'helpers';
import { linkStyles } from 'components/pages/Applicant/PaymentDetail/PaymentDetail.styles';

const tableConfig = [
  {
    key: 'id',
    label: 'id',
    ratio: 0.05,
    textAlign: 'center',
    component: (d) => (
      <span style={{ marginLeft: '10px' }}>
        {d}
      </span>
    ),
  },
  {
    key: 'amount',
    label: 'amount',
    ratio: 0.175,
    textAlign: 'center',
    component: (d) => (
      <span style={{ marginLeft: '10px' }}>
        {d ? `Rp ${d.toLocaleString('en').replaceAll(',', '.')}` : ''}
      </span>
    ),
  },
  {
    key: 'isPaid',
    label: 'paid',
    ratio: 0.05,
    textAlign: 'center',
    component: (d) => (
      <span style={{ marginLeft: '5px' }}>
        {d ? 'true' : 'false'}
      </span>
    ),
  },
  {
    key: 'paidAt',
    label: 'paid at',
    ratio: 0.225,
    textAlign: 'center',
    component: (d) => (
      <span style={{ }}>
        {d ? fullCalendarTimeFormat(d) : ''}
      </span>
    ),
  },
  {
    key: 'invoiceExternalUrl',
    label: 'invoice external url',
    ratio: 0.2,
    textAlign: 'center',
    component: (d) => (
      <span css={linkStyles(d)}>
        <a
          href={d}
          target="_blank"
        >
          {d ? 'invoice url' : ''}
        </a>
      </span>
    ),
  },
  {
    key: 'createdAt',
    label: 'invoice created at',
    ratio: 0.225,
    textAlign: 'center',
    component: (d) => (
      <span style={{ }}>
        {d ? fullCalendarTimeFormat(d) : ''}
      </span>
    ),
  },
  {
    key: 'paymentStatus',
    label: 'payment status',
    ratio: 0.175,
    textAlign: 'center',
    component: (d) => (
      <span style={{ marginLeft: '10px' }}>
        {d ? d.replaceAll('_', ' ') : ''}
      </span>
    ),
  },
  {
    key: 'action',
    label: 'notes',
    ratio: 0.25,
    textAlign: 'left',
    component: (d) => d,
  },
];

export default tableConfig;
