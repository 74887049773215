import { css } from '@emotion/react';

import color from 'components/common/color';

export const layoutContainerStyles = (zIndex) => css`
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: ${zIndex};
`;

export const childrenContainerStyles = css`
  width: 100%;
  margin: 0 auto 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const navbarContainerStyles = css`
  width: calc(90% - 40px);
  // width: 90%;
  margin: 5px 0px 10px;
  padding: 0 10px;
  border: none;
`;

export const buttonRowStyles = css`
  display: flex;
  width: 100%;
  justify-content: flex-start;
`;

export const notificationContainerStyles = css`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const notificationBannerStyles = (status) => css`
  background: ${status ? '#39A388' : '#FCD2D1'};
  width: 100%;
  display: flex;
  align-items: center;
  height: 30px;
  padding: 8px;
  margin: 20px 0;

  span {
    font-weight: 600;
    
    &:first-letter {
      text-transform: uppercase;
    }
  }
`;

export const containerStyles = css`
  display: flex;
  padding: 5px 0 10px;
  flex-direction: column;
  width: 100%;
`;

export const buttonStyles = css`
  width: fit-content;
`;

export const tableContainerStyles = css`
  margin: 20px auto 0;
  width: 100%;
`;

export const tableHeaderContainerStyles = css`
  width: 100%;
`;

export const tableBodyStyles = css`
  width: 100%;
`;

export const tableDetailSectionStyles = css`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0 0;
`;

export const tableDetailContainerStyles = css`
  width: 30%;
  border: 1px solid ${color.BLACK};
  border-collapse: collapse;

  tr {
    display: flex;
  }

  td {
    width: 50%;
    display: flex;
    padding: 5px 10px;
    border-right: 1px solid ${color.BLACK};

    &:last-of-type {
      border: none;
    }
  }
`;

export const tableDetailHeaderStyles = css`
  border-bottom: 1px solid ${color.BLACK};

  th {
    display: flex;
    padding: 5px 10px;
    border-right: 1px solid ${color.BLACK};
    width: 50%;
    align-self: flex-start;

    &:last-of-type {
      border: none;
    }
  }
`;

export const modalStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    boxShadow: '0px 3px 30px #0000000d',
    width: '300px',
    overflow: 'hidden',
  },
};

export const dataContainerStyles = css`
  display: flex;
  flex-direction: column;
  margin: 0 0 10px;

  label {
    font-family: "Roboto", sans-serif;
    text-transform: capitalize;
    margin: 0 0 3px;
  };
`;

export const inputStyles = css`
  margin: 5px 0;
  height: 30px;
`;

export const selectContainerStyles = css`
  width: 100%;
`;

export const buttonSubmitStyles = css`
  margin: 15px 0;
`;

export const linkStyles = (d) => css`
  a {
    color: ${color.ORANGE};
    text-decoration: none;
    text-transform: none;
    font-size: 16px;
    font-weight: 600;
    pointer-events: ${d ? 'default' : 'none'};
  }
`;
