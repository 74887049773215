/* eslint-disable */
import React from 'react';

const tableConfig = [
  {
    key: 'date',
    label: 'date and time',
    ratio: 0.2,
    textAlign: 'left',
    component: (d) => (
      <span>
        {d}
      </span>
    ),
  },
  {
    key: 'type',
    label: 'type',
    ratio: 0.1,
    textAlign: 'left',
    component: (d) => (
      <span>
        {d}
      </span>
    ),
  },
  {
    key: 'location',
    label: 'location',
    ratio: 0.1,
    textAlign: 'left',
    component: (d) => (
      <span>
        {d}
      </span>
    ),
  },
  {
    key: 'link',
    label: 'link',
    ratio: 0.3,
    textAlign: 'left',
    component: (d) => (
      <span style={{ textTransform: 'lowercase' }}>
        {d}
      </span>
    ),
  },
  {
    key: 'totalInvite',
    label: 'total invite',
    ratio: 0.1,
    textAlign: 'left',
    component: (d) => (
      <span>
        {d}
      </span>
    ),
  },
  {
    key: 'interviewer',
    label: 'interviewer',
    ratio: 0.2,
    textAlign: 'left',
    component: (d) => (
      <span>
        {d}
      </span>
    ),
  },
];

export default tableConfig;
