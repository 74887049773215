import axiosInstance from 'helpers/axiosInstance';

export default {
  getInterviewApplicants: (qs = '') => axiosInstance().get('applicant/search', { params: qs }),
  getAllScheduledApplicants: () => axiosInstance().get('applicant-tests'),
  getTestInfo: (id) => axiosInstance().get(`scheduled-tests/${id}`),
  getTestInvites: (id) => axiosInstance().get(`scheduled-tests/${id}/invitees`),
  editBulkTestInvites: (data) => axiosInstance().put('applicant-tests/bulk-update', data),
  createTestInvites: (data) => axiosInstance().post('scheduled-tests', data),
};
