import { css } from '@emotion/react';

import color from '../color';

export const buttonStyles = css`
  width: 100%;
  text-align: center;
  font-family: "Roboto", sans-serif;
  vertical-align: middle;
  height: 40px;
  font-size: 16px;
  line-height: 1.5;
  border-radius: 5px;
  border: 1px solid ${color.TRANSPARENT};
  cursor: pointer;
  white-space: pre;
  padding: 8px;
  text-transform: capitalize;
  transition: all 0.3s ease-in-out;
  outline: none;
  background-color: ${color.BLUE};
  color: ${color.WHITE};

  &:hover {
    opacity: 0.8;
  };
  
  &:disabled {
    background-color: ${color.GREY};
    cursor: not-allowed;
  };
`;
