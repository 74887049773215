/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  fetchPaymentMethodOptions,
  // fetchPaymentStatusOptions,
  fetchProgramOptions,
  fetchBatchOptions,
} from 'helpers';

import Sidebar from 'components/layout/Sidebar/Sidebar';
import {
  CustomDatepicker as Datepicker,
  Select2,
  Select,
  Button,
} from 'components/common';
import {
  filterConditionsContainerStyles,
  datepickerContainerStyles,
  select2ContainerStyles,
  selectContainerStyles,
  buttonContainerStyles,
  buttonStyles,
} from './Sidebar.styles';

const PTPStudentSidebar = ({
  startDate,
  endDate,
  setDateRange,
  filterCondition,
  handleChange,
  handleSelect2Change,
  handleSubmit,
  handleReset,
  // handleDownloadCSV,
  // isCsvDownloading,
}) => {
  const [programOptions, setProgramOptions] = useState([]);
  const [batchOptions, setBatchOptions] = useState([]);
  // const [paymentStatusOptions, setPaymentStatusOptions] = useState([]);
  const [paymentMethodOptions, setPaymentMethodOptions] = useState([]);

  useEffect(() => {
    fetchBatchOptions(false)
      .then((data) => {
        setBatchOptions(data);
      });
  }, []);

  // useEffect(() => {
  //   fetchPaymentStatusOptions()
  //     .then((data) => {
  //       setPaymentStatusOptions(data);
  //     });
  // }, []);

  useEffect(() => {
    fetchProgramOptions('PART_TIME')
      .then((data) => {
        setProgramOptions(data);
      });
  }, []);

  useEffect(() => {
    fetchPaymentMethodOptions()
      .then((data) => {
        setPaymentMethodOptions(data);
      });
  }, []);

  return (
    <Sidebar>
      <div css={filterConditionsContainerStyles}>
        <Datepicker
          containerStyles={datepickerContainerStyles}
          startDate={startDate}
          endDate={endDate}
          setDateRange={setDateRange}
          placeholder="date range"
        />
        <Select
          containerStyles={selectContainerStyles}
          name="enrolledProgramCode"
          options={programOptions}
          placeholder="program"
          value={filterCondition.enrolledProgramCode}
          onChange={handleChange}
        />
        <Select2
          containerStyles={select2ContainerStyles}
          defaultValue={{ label: 'registered batch', value: '' }}
          options={batchOptions}
          placeholder="registered batch"
          onChange={(e) => handleSelect2Change(e, 'appliedBatch')}
          value={filterCondition.appliedBatch}
        />
        <Select2
          containerStyles={select2ContainerStyles}
          defaultValue={{ label: 'enrolled batch', value: '' }}
          options={batchOptions}
          placeholder="enrolled batch"
          onChange={(e) => handleSelect2Change(e, 'enrolledBatch')}
          value={filterCondition.enrolledBatch}
        />
        <Select
          containerStyles={selectContainerStyles}
          name="paymentOption"
          options={paymentMethodOptions}
          placeholder="payment option"
          value={filterCondition.paymentOption}
          onChange={handleChange}
        />
        {/* <Select
          containerStyles={selectContainerStyles}
          name="paymentStatus"
          options={paymentStatusOptions}
          placeholder="payment status"
          value={filterCondition.paymentStatus}
          onChange={handleChange}
        /> */}
      </div>
      <div css={buttonContainerStyles}>
        <Button type="button" onClick={handleSubmit} styles={buttonStyles}>apply</Button>
        <Button type="button" onClick={handleReset} className="reset" styles={buttonStyles}>reset filter</Button>
        {/* <Button
          disabled
          type="button"
          className="download"
          styles={buttonStyles}
          onClick={handleDownloadCSV}
        >
          {isCsvDownloading ? 'Downloading CSV...' : 'Download CSV'}
        </Button> */}
      </div>
    </Sidebar>
  );
};

PTPStudentSidebar.propTypes = {
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  setDateRange: PropTypes.func.isRequired,
  filterCondition: PropTypes.shape({
    appliedBatch: PropTypes.string,
    enrolledBatch: PropTypes.string,
    application_result: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
    test_result: PropTypes.string,
    enrolledProgramCode: PropTypes.string,
    paymentOption: PropTypes.string,
    paymentStatus: PropTypes.string,
    student_status: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSelect2Change: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
  // handleDownloadCSV: PropTypes.func.isRequired,
  // isCsvDownloading: PropTypes.bool.isRequired,
};

PTPStudentSidebar.defaultProps = {
  startDate: null,
  endDate: null,
};

export default PTPStudentSidebar;
