/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  fetchProgramOptions,
  fetchBatchOptions,
  fetchPaymentMethodOptions,
  fetchCampusLocationOptions,
  fetchFollowUpStatusOptions,
  fetchApplicantStatusOptions,
} from 'helpers';
import Sidebar from 'components/layout/Sidebar/Sidebar';
import {
  CustomDatepicker as Datepicker,
  Select2,
  Select,
  Button,
  CreatableSelect,
} from 'components/common';

import {
  filterConditionsContainerStyles,
  datepickerContainerStyles,
  select2ContainerStyles,
  selectContainerStyles,
  buttonContainerStyles,
  buttonStyles,
} from './Sidebar.styles';

const PtpApplicantSidebar = ({
  startDate,
  endDate,
  setDateRange,
  filterCondition,
  setFilterCondition,
  handleChange,
  handleSelect2Change,
  handleSubmit,
  handleReset,
  // handleDownloadCSV,
  // isCsvDownloading,
}) => {
  const [options, setOptions] = useState({
    batchOptions: [],
    paymentMethodOptions: [],
    paymentStatusOptions: [],
    followUpStatusOptions: [],
    programOptions: [],
    campusLocationOptions: [],
    applicantStatusOptions: [],
  });

  const getPaymentOptions = () => fetchPaymentMethodOptions().then((data) => {
    const modifiedArr = data.filter((d) => d.value !== 'ISA');
    return modifiedArr;
  });
  const getBatchOptions = () => fetchBatchOptions(true).then((resp) => resp.map((d) => ({
    label: d.label,
    value: d.label,
  })));
  const getFollowUpStatusOptions = () => fetchFollowUpStatusOptions().then((resp) => resp);
  const getCampusLocationOptions = () => fetchCampusLocationOptions()
    .then((resp) => resp.map((d) => ({
      label: d.label,
      value: d.label,
    })));
  const getProgramOptions = () => fetchProgramOptions('PART_TIME').then((resp) => resp);
  const getApplicantStatusOptions = () => fetchApplicantStatusOptions().then((resp) => resp);

  useEffect(async () => {
    const [
      batchOptions,
      paymentMethodOptions,
      followUpStatusOptions,
      programOptions,
      campusLocationOptions,
      applicantStatusOptions,
    ] = await Promise.all([
      getBatchOptions(),
      getPaymentOptions(),
      getFollowUpStatusOptions(),
      getProgramOptions(),
      getCampusLocationOptions(),
      getApplicantStatusOptions(),
    ]);
    setOptions((prevState) => ({
      ...prevState,
      batchOptions,
      paymentMethodOptions,
      followUpStatusOptions,
      programOptions,
      campusLocationOptions,
      applicantStatusOptions,
    }));
  }, []);

  return (
    <Sidebar>
      <div css={filterConditionsContainerStyles}>
        <Datepicker
          containerStyles={datepickerContainerStyles}
          startDate={startDate}
          endDate={endDate}
          setDateRange={setDateRange}
          placeholder="date range"
        />
        <Select2
          containerStyles={select2ContainerStyles}
          defaultValue={{ label: 'program', value: '' }}
          options={options.programOptions}
          placeholder="program"
          onChange={(e) => handleSelect2Change(e, 'program')}
          value={filterCondition.program}
        />
        <Select2
          containerStyles={select2ContainerStyles}
          defaultValue={{ label: 'batch enrolled', value: '' }}
          options={options.batchOptions}
          placeholder="batch enrolled"
          onChange={(e) => handleSelect2Change(e, 'lastBatch')}
          value={filterCondition.lastBatch}
        />
        <CreatableSelect
          name="paymentType"
          containerStyles={select2ContainerStyles}
          options={options.paymentMethodOptions}
          placeholder="payment type"
          filterCondition={filterCondition}
          setFilterCondition={setFilterCondition}
          value={filterCondition.paymentType}
        />
        <Select
          containerStyles={selectContainerStyles}
          name="programLocation"
          options={options.campusLocationOptions}
          placeholder="campus location"
          value={filterCondition.programLocation}
          onChange={handleChange}
        />
        <CreatableSelect
          name="followUpStatus"
          containerStyles={select2ContainerStyles}
          options={options.followUpStatusOptions}
          placeholder="follow up status"
          filterCondition={filterCondition}
          setFilterCondition={setFilterCondition}
          value={filterCondition.followUpStatus}
        />
        <CreatableSelect
          name="applicantStatuses"
          containerStyles={select2ContainerStyles}
          defaultValue={{ label: 'applicant status', value: '' }}
          options={options.applicantStatusOptions}
          placeholder="applicant status"
          filterCondition={filterCondition}
          setFilterCondition={setFilterCondition}
          value={filterCondition.applicantStatuses}
        />
      </div>
      <div css={buttonContainerStyles}>
        <Button type="button" onClick={handleSubmit} styles={buttonStyles}>apply</Button>
        <Button type="button" onClick={handleReset} className="reset" styles={buttonStyles}>reset filter</Button>
      </div>
    </Sidebar>
  );
};

PtpApplicantSidebar.propTypes = {
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  setDateRange: PropTypes.func.isRequired,
  filterCondition: PropTypes.shape({
    program: PropTypes.string.isRequired,
    testResults: PropTypes.arrayOf(PropTypes.shape({})),
    lastBatch: PropTypes.string.isRequired,
    programLocation: PropTypes.string,
    paymentType: PropTypes.string,
    followUpStatus: PropTypes.arrayOf(PropTypes.shape({})),
    applicantStatuses: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  setFilterCondition: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSelect2Change: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
  // handleDownloadCSV: PropTypes.func.isRequired,
  // isCsvDownloading: PropTypes.bool.isRequired,
};

PtpApplicantSidebar.defaultProps = {
  startDate: null,
  endDate: null,
};

export default PtpApplicantSidebar;
