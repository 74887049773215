import axios from 'axios';
import Cookies from 'js-cookie';

const axiosInstance = () => {
  const token = Cookies.get('access_token');
  return axios.create({
    baseURL: `${process.env.REACT_APP_ADMISSION_API_URL}/admission/v2`,
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      'x-api-key': `${process.env.REACT_APP_ADMISSION_API_KEY}`,
      Authorization: `Bearer ${token}`,
    },
  });
};

export default axiosInstance;
