import { css } from '@emotion/react';

import color from 'components/common/color';

export const filterConditionsContainerStyles = css`
  margin-bottom: 35px;
  width: calc(100% - 40px);
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  // overflow-y: scroll;

  // &::-webkit-scrollbar {
  //   width: 3px;
  // }
  // &::-webkit-scrollbar-thumb {
  //   background-color: darkgrey;
  // }
  // &::-webkit-scrollbar-track {
  //   box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  // }
`;

export const datepickerContainerStyles = css`
  margin-bottom: 20px;
  width: 100%;
`;

export const select2ContainerStyles = css`
  margin-bottom: 20px;
  width: 100%;
`;

export const selectContainerStyles = css`
  width: 100%;
  display: flex;
  margin-bottom: 20px;
`;

export const buttonContainerStyles = css`
  width: calc(100% - 40px);
  padding: 0 15px;
`;

export const buttonStyles = css`
  &.reset {
    margin: 10px 0;
    background-color: #D83A56;
  };

  &.download {
    color: ${color.BLUE};
    background: ${color.GREY};

    a {
      text-decoration: none;
      color: ${color.BLUE};
    }
  };
`;
