/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import Navbar from './Navbar/Navbar';
import { layoutStyles, childrenStyles, navbarContainerStyles } from './layout.styles';

const Layout = ({
  containerStyles,
  childrenContainerStyles,
  navbarStyles,
  sidebar,
  children,
}) => (
  <div css={[layoutStyles, containerStyles]}>
    {sidebar}
    <Navbar navbarContainerStyles={[navbarContainerStyles, navbarStyles]} />
    <div css={[childrenStyles, childrenContainerStyles]}>
      {children}
    </div>
  </div>
);

Layout.propTypes = {
  containerStyles: PropTypes.shape({}),
  childrenContainerStyles: PropTypes.shape({}),
  navbarStyles: PropTypes.shape({}),
  sidebar: PropTypes.node,
  children: PropTypes.node.isRequired,
};

Layout.defaultProps = {
  containerStyles: css``,
  navbarStyles: css``,
  childrenContainerStyles: css``,
  sidebar: '',
};

export default Layout;
