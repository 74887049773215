/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import Sidebar from 'components/layout/Sidebar/Sidebar';
import {
  CustomDatepicker as Datepicker,
  Select,
  Button,
  CreatableSelect,
} from 'components/common';

import * as styles from './Sidebar.styles';

const BatchSidebar = ({
  programOptions,
  filterCondition,
  setFilterCondition,
  handleSubmit,
  handleReset,
}) => {
  const handleMultiValueChange = (d, name) => {
    setFilterCondition((prevState) => ({
      ...prevState,
      [name]: d,
    }));
  };

  const handleChange = (e) => {
    setFilterCondition((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleDateChange = (e, name) => {
    setFilterCondition((prevState) => ({
      ...prevState,
      [name]: e,
    }));
  };

  return (
    <Sidebar>
      <div css={styles.filterConditionsContainerStyles}>
        <Datepicker
          containerStyles={styles.datepickerContainerStyles}
          startDate={filterCondition.registrationDate[0]}
          endDate={filterCondition.registrationDate[1]}
          setDateRange={(e) => handleDateChange(e, 'registrationDate')}
          placeholder="registration date"
        />
        <Datepicker
          containerStyles={styles.datepickerContainerStyles}
          startDate={filterCondition.classDate[0]}
          endDate={filterCondition.classDate[1]}
          setDateRange={(e) => handleDateChange(e, 'classDate')}
          placeholder="class start date"
        />
        <Select
          containerStyles={styles.selectContainerStyles}
          name="registrationStatus"
          options={[
            {
              label: 'open',
              value: 'open',
            },
            {
              label: 'close',
              value: 'close',
            },
            {
              label: 'upcoming',
              value: 'upcoming',
            },
          ]}
          placeholder="registration status"
          onChange={handleChange}
          value={filterCondition.registrationStatus}
        />
        <CreatableSelect
          containerStyles={styles.select2ContainerStyles}
          options={programOptions}
          placeholder="programs"
          value={filterCondition.programs}
          onChange={(d) => handleMultiValueChange(d, 'programs')}
        />
        <Datepicker
          containerStyles={styles.datepickerContainerStyles}
          startDate={filterCondition.graduationDate[0]}
          endDate={filterCondition.graduationDate[1]}
          setDateRange={(e) => handleDateChange(e, 'graduationDate')}
          placeholder="graduation date"
        />
      </div>
      <div css={styles.buttonContainerStyles}>
        <Button type="button" onClick={handleSubmit} styles={styles.buttonStyles}>apply</Button>
        <Button type="button" onClick={handleReset} className="reset" styles={styles.buttonStyles}>reset filter</Button>
      </div>
    </Sidebar>
  );
};

BatchSidebar.propTypes = {
  programOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  filterCondition: PropTypes.shape({
    registrationDate: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
    classDate: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
    registrationStatus: PropTypes.string,
    programs: PropTypes.arrayOf(PropTypes.shape({})),
    graduationDate: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
  }).isRequired,
  setFilterCondition: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
};

BatchSidebar.defaultProps = {
  // startDate: null,
  // endDate: null,
};

export default BatchSidebar;
