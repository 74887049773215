/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import signOut from 'api/user/signout';

import { Button, Icon } from 'components/common';
import * as styles from './Navbar.styles';

const Navbar = ({ navbarContainerStyles }) => {
  const history = useHistory();
  const { id } = useParams();
  const { pathname, search } = useLocation();
  const routerModule = pathname.split('/')[1];
  const routerSubmodule = pathname.split('/')[2] || '';
  const [module, setModule] = useState('Full Time Program');
  const [menubar, setMenuBar] = useState(routerModule);
  const [isApplicantModuleShown, setApplicantModule] = useState(false);
  const program = new URLSearchParams(search).get('program');

  const handleMenuBar = (menu) => {
    if (menu !== menubar) {
      setMenuBar(menu);
      if (menu === 'dashboard' || menu === 'batch') {
        history.push(`/${menu}`);
      }
    }
    if ((routerModule !== 'ptp' || routerModule !== 'ftp') && !routerModule) {
      setApplicantModule(true);
    }
  };

  const handleButtonModuleBackground = (mod) => {
    if (routerModule === mod) {
      return 'color';
    }
    return 'default';
  };

  const handleButtonSubmoduleBackground = (submod, mod = 'ftp') => {
    if (routerSubmodule === submod && routerModule === mod) {
      return 'color';
    }
    return 'default';
  };

  const handleApplicantModuleBackground = (mod) => {
    if (routerSubmodule === mod) {
      return 'color';
    }
    return 'default';
  };

  const handleLogout = () => {
    signOut()
      .then((response) => {
        if (response.status === 200) {
          Cookies.remove('access_token');
          history.push('/sign-in');
        }
      })
      .catch((err) => console.log(err, 'e'));
  };

  useEffect(() => {
    if (routerSubmodule) {
      setApplicantModule(true);
    }
  }, []);

  useEffect(() => {
    switch (routerModule) {
      case 'ftp':
        setModule('Full time Applicants');
        break;
      case 'ptp':
        setModule('Part time Applicants');
        break;
      case 'batch':
        setModule('Batch');
        break;
      case ('dashboard'):
        setModule('Dashboard');
        break;
      default:
        setModule('applicant details');
    }
  }, []);

  return (
    <div css={[styles.navbarStyles, navbarContainerStyles]}>
      <div css={styles.breadCrumbLogoutContainerStyles}>
        <div css={styles.breadcrumbContainerStyles}>
          <span>{module}</span>
          {
            routerSubmodule && (
              <>
                <Icon name="chevron-right" />
                <Icon name="chevron-right" />
              </>
            )
          }
          <span>{routerSubmodule.split('-').join(' ')}</span>
        </div>
        <Button type="button" css={styles.logoutButtonStyles} onClick={handleLogout}>
          logout
        </Button>
      </div>
      <div css={styles.buttonsContainerStyles}>
        <div>
          {/* <Button
            type="button"
            css={styles.buttonModuleStyles(handleButtonModuleBackground('dashboard'))}
            onClick={() => handleMenuBar('dashboard')}
          >
            dashboard
          </Button> */}
          <Button
            type="button"
            css={styles.buttonModuleStyles(handleButtonModuleBackground('batch'))}
            onClick={() => handleMenuBar('batch')}
          >
            batch
          </Button>
        </div>
        <Button
          type="button"
          css={styles.buttonModuleStyles(handleButtonModuleBackground('ftp'))}
          onClick={() => handleMenuBar('ftp')}
        >
          full time program
        </Button>
        <Button
          type="button"
          css={styles.buttonModuleStyles(handleButtonModuleBackground('ptp'))}
          onClick={() => handleMenuBar('ptp')}
        >
          part time program
        </Button>
      </div>
      <div css={styles.subButtonsContainerStyles}>
        {
          menubar === 'ftp' && (
            <div css={styles.ftpContainerStyles}>
              <a
                href="/ftp/applicants"
              >
                <Button
                  css={styles.buttonSubmoduleStyles(handleButtonSubmoduleBackground('applicants'))}
                  type="button"
                >
                  applicants
                </Button>
              </a>
              <a
                href="/ftp/students"
              >
                <Button
                  css={styles.buttonSubmoduleStyles(handleButtonSubmoduleBackground('students'))}
                  type="button"
                >
                  students
                </Button>
              </a>
            </div>
          )
        }
        {
          menubar === 'ptp' && (
            <div className="ptp" css={styles.ptpContainerStyles}>
              <a
                href="/ptp/applicants"
              >
                <Button
                  styles={styles.buttonSubmoduleStyles(handleButtonSubmoduleBackground('applicants', 'ptp'))}
                  type="button"
                >
                  applicants
                </Button>
              </a>
              <a
                href="/ptp/students"
              >
                <Button
                  styles={styles.buttonSubmoduleStyles(handleButtonSubmoduleBackground('students', 'ptp'))}
                  type="button"
                >
                  students
                </Button>
              </a>
            </div>
          )
        }
      </div>
      {
        id && isApplicantModuleShown && (
          <div css={styles.applicantModuleStyles(program)}>
            <a
              href={`/${id}/personal-data${program ? `${`?program=${program}`}` : ''}`}
            >
              <Button
                styles={styles.buttonApplicantModuleStyles(handleApplicantModuleBackground('personal-data'))}
                type="button"
              >
                personal data
              </Button>
            </a>
            {
              program !== 'ptp' && (
                <a
                  href={`/${id}/test-history?program=ftp`}
                >
                  <Button
                    styles={styles.buttonApplicantModuleStyles(handleApplicantModuleBackground('test-history'))}
                    type="button"
                  >
                    test history
                  </Button>
                </a>
              )
            }
            <a
              href={`/${id}/history-call${program ? `${`?program=${program}`}` : ''}`}
            >
              <Button
                styles={styles.buttonApplicantModuleStyles(handleApplicantModuleBackground('history-call'))}
                type="button"
              >
                history call
              </Button>
            </a>
            <a
              href={`/${id}/activity-log${program ? `${`?program=${program}`}` : ''}`}
            >
              <Button
                styles={styles.buttonApplicantModuleStyles(handleApplicantModuleBackground('activity-log'))}
                type="button"
              >
                activity log
              </Button>
            </a>
            <a
              href={`/${id}/move-batch${program ? `${`${program ? `${`?program=${program}`}` : ''}`}` : ''}`}
            >
              <Button
                styles={styles.buttonApplicantModuleStyles(handleApplicantModuleBackground('move-batch'))}
                type="button"
              >
                move batch
              </Button>
            </a>
            <a
              href={`/${id}/payment-detail${program ? `${`${program ? `${`?program=${program}`}` : ''}`}` : ''}`}
            >
              <Button
                styles={styles.buttonApplicantModuleStyles(handleApplicantModuleBackground('payment-detail'))}
                type="button"
              >
                payment detail
              </Button>
            </a>
          </div>
        )
      }
    </div>
  );
};

Navbar.propTypes = {
  navbarContainerStyles: PropTypes.oneOfType(
    [PropTypes.shape({}), PropTypes.arrayOf(PropTypes.shape({}))],
  ),
};

Navbar.defaultProps = {
  navbarContainerStyles: css``,
};

export default Navbar;
