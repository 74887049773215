/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import qs from 'query-string';
import {
  Button, Modal, Select, Input,
} from 'components/common';
import { millionsSeparator, numeralTextConverter } from 'helpers';
import api from 'api/server';

import formValidator from '../formValidator';
import * as styles from './EditBatchModal.styles';

const EditBatchModal = ({
  isModalOpen,
  setModal,
  data,
  setSelectedData,
  setDataBatches,
  options,
}) => {
  const modalRef = useRef(null);
  const history = useHistory();
  const queryString = history.location.search || '';
  const queryObject = qs.parse(queryString) || {};
  const modObj = {
    ...queryObject,
    limit: 25,
  };
  const [selectedBatch, setSelectedBatch] = useState({
    batchType: '',
    program: '',
    location: '',
    registrationStartDate: '',
    registrationEndDate: '',
    prepStartDate: '',
    prepEndDate: '',
    classStartDate: '',
    classEndDate: '',
    classDaySchedule: '',
    classTimeSchedule: '',
    graduationDate: '',
    discountPrice: '',
    discountEndDate: '',
    basePrice: '',
  });
  const [notif, setNotif] = useState({
    type: null,
    message: '',
  });

  const handleSplitDate = (d) => {
    if (d) {
      return d.split('T')[0];
    }
    return '';
  };

  useEffect(() => {
    if (data.id) {
      setSelectedBatch({
        id: +data.id,
        batchType: +data.batch_type.id,
        program: +data.program.id,
        code: data.code,
        location: +data.campus_location.id,
        registrationStartDate: handleSplitDate(data.registration_start_date),
        registrationEndDate: handleSplitDate(data.registration_end_date),
        prepStartDate: handleSplitDate(data.class_preparation_start_date),
        prepEndDate: handleSplitDate(data.class_preparation_end_date),
        classStartDate: handleSplitDate(data.class_start_date),
        classEndDate: handleSplitDate(data.class_end_date),
        graduationDate: handleSplitDate(data.graduation_date),
        classDaySchedule: JSON.parse(data.schedule).day,
        classTimeSchedule: JSON.parse(data.schedule).time,
        discountPrice: data.discount_price,
        discountEndDate: handleSplitDate(data.discount_end_date),
        basePrice: data.base_price,
      });
    }
  }, [data]);

  useEffect(() => {
    if (notif) {
      setTimeout(() => setNotif(false), 3500);
    }
    if (notif.type === 'success' && notif) {
      setTimeout(() => setModal(false), 4000);
    }
  }, [notif]);

  const handleChange = (e) => {
    setSelectedBatch((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleModifiedDatePayload = (date) => {
    if (date) {
      return `${date}T00:00:00Z`;
    }
    return null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setNotif({ type: null, message: '' });
    const payload = {
      program_id: +selectedBatch.program,
      campus_location_id: +selectedBatch.location,
      base_price: numeralTextConverter(selectedBatch.basePrice),
      discount_price: +selectedBatch.discountPrice || null,
      discount_end_date: handleModifiedDatePayload(selectedBatch.discountEndDate),
      registration_start_date: handleModifiedDatePayload(selectedBatch.registrationStartDate),
      registration_end_date: handleModifiedDatePayload(selectedBatch.registrationEndDate),
      class_preparation_start_date: handleModifiedDatePayload(
        selectedBatch.prepStartDate,
      ),
      class_preparation_end_date: handleModifiedDatePayload(
        selectedBatch.prepEndDate,
      ),
      class_start_date: handleModifiedDatePayload(selectedBatch.classStartDate),
      class_end_date: handleModifiedDatePayload(selectedBatch.classEndDate),
      graduation_date: selectedBatch.graduationDate
        ? handleModifiedDatePayload(selectedBatch.graduationDate) : null,
      schedule: JSON.stringify({
        day: selectedBatch.classDaySchedule,
        time: selectedBatch.classTimeSchedule,
      }),
    };
    const response = formValidator(payload, 'edit');
    if (response !== 'valid') {
      setNotif({
        type: 'error',
        message: response.message,
      });
    } else {
      try {
        const { data: editRespData } = await api.editBatch(selectedBatch.id, payload);
        if (editRespData.status) {
          modalRef.current.scrollIntoView({ behavior: 'smooth', inline: 'start' });
          setNotif({
            type: 'success',
            message: 'success edit batch',
          });
          setSelectedData(editRespData.data);
        }
        const respBatches = await api.getAllBatches(modObj);
        setDataBatches(respBatches.data.data.results);
      } catch (error) {
        setNotif({
          type: 'error',
          message: error.response.message,
        });
      }
    }
  };

  return (
    <Modal
      styles={styles.modalStyles}
      isModalOpen={isModalOpen}
      setModal={setModal}
      closeModal={() => setModal(false)}
    >
      <form onSubmit={handleSubmit}>
        {
          notif.type && (
            <span css={styles.notifStyles(notif.type)}>{notif.message}</span>
          )
        }
        <div ref={modalRef} css={styles.formRowStyles}>
          <Select
            placeholder="choose batch type"
            name="batchType"
            containerStyles={styles.selectContainerStyles}
            onChange={handleChange}
            options={[
              {
                label: 'full time',
                value: 2,
              },
              {
                label: 'part time',
                value: 3,
              },
            ]}
            disabled
            value={selectedBatch.batchType}
          />
        </div>
        <div css={styles.formRowStyles}>
          <Select
            placeholder="choose program"
            name="program"
            containerStyles={styles.selectContainerStyles}
            onChange={handleChange}
            options={options.programOptions}
            value={selectedBatch.program}
            disabled
          />
        </div>
        <div css={styles.formRowStyles}>
          <Select
            placeholder="choose location"
            name="location"
            containerStyles={styles.selectContainerStyles}
            onChange={handleChange}
            options={options.campusLocations}
            value={selectedBatch.location}
            disabled
          />
        </div>
        <div css={styles.formRowStyles}>
          <span>Current Batch</span>
          <Input
            type="text"
            placeholder="batch number preview"
            name="code"
            containerStyles={styles.selectContainerStyles}
            styles={styles.inputStyles}
            onChange={handleChange}
            disabled
            value={selectedBatch.code}
          />
        </div>
        <div css={styles.halfFormRowStyles}>
          <div css={[styles.formRowStyles, styles.halfWidthColumnStyles]}>
            <label>registration start date</label>
            <Input
              type="date"
              placeholder="registration start date"
              name="registrationStartDate"
              containerStyles={styles.selectContainerStyles}
              styles={styles.inputStyles}
              onChange={handleChange}
              value={selectedBatch.registrationStartDate}
            />
          </div>
          <div css={[styles.formRowStyles, styles.halfWidthColumnStyles]}>
            <label>registration end date</label>
            <Input
              type="date"
              placeholder="batch number preview"
              name="registrationEndDate"
              containerStyles={styles.selectContainerStyles}
              styles={styles.inputStyles}
              onChange={handleChange}
              value={selectedBatch.registrationEndDate}
              min={selectedBatch.registrationStartDate}
            />
          </div>
        </div>
        <div css={styles.halfFormRowStyles}>
          <div css={[styles.formRowStyles, styles.halfWidthColumnStyles]}>
            <label>class preparation start date</label>
            <Input
              type="date"
              name="prepStartDate"
              containerStyles={styles.selectContainerStyles}
              styles={styles.inputStyles}
              onChange={handleChange}
              value={selectedBatch.prepStartDate}
            />
          </div>
          <div css={[styles.formRowStyles, styles.halfWidthColumnStyles]}>
            <label>class preparation end date</label>
            <Input
              type="date"
              name="prepEndDate"
              containerStyles={styles.selectContainerStyles}
              styles={styles.inputStyles}
              onChange={handleChange}
              value={selectedBatch.prepEndDate}
              min={selectedBatch.prepStartDate}
            />
          </div>
        </div>
        <div css={styles.halfFormRowStyles}>
          <div css={[styles.formRowStyles, styles.halfWidthColumnStyles]}>
            <label>class start date</label>
            <Input
              type="date"
              placeholder="batch number preview"
              name="classStartDate"
              containerStyles={styles.selectContainerStyles}
              styles={styles.inputStyles}
              onChange={handleChange}
              value={selectedBatch.classStartDate}
            />
          </div>
          <div css={[styles.formRowStyles, styles.halfWidthColumnStyles]}>
            <label>class end date</label>
            <Input
              type="date"
              placeholder="batch number preview"
              name="classEndDate"
              containerStyles={styles.selectContainerStyles}
              styles={styles.inputStyles}
              onChange={handleChange}
              value={selectedBatch.classEndDate}
              min={selectedBatch.classStartDate}
            />
          </div>
        </div>
        <div css={styles.halfFormRowStyles}>
          <div css={[styles.formRowStyles, styles.halfWidthColumnStyles]}>
            <label>class day schedule</label>
            <Input
              type="text"
              placeholder="Senin - Jumat"
              name="classDaySchedule"
              containerStyles={styles.selectContainerStyles}
              styles={styles.inputStyles}
              onChange={handleChange}
              value={selectedBatch.classDaySchedule}
            />
          </div>
          <div css={[styles.formRowStyles, styles.halfWidthColumnStyles]}>
            <label>class time schedule</label>
            <Input
              type="text"
              placeholder="08:00 - 17:00"
              name="classTimeSchedule"
              containerStyles={styles.selectContainerStyles}
              styles={styles.inputStyles}
              onChange={handleChange}
              value={selectedBatch.classTimeSchedule}
            />
          </div>
        </div>
        <div css={styles.formRowStyles}>
          <label>graduation date</label>
          <Input
            type="date"
            placeholder="batch number preview"
            name="graduationDate"
            containerStyles={styles.selectContainerStyles}
            styles={styles.inputStyles}
            onChange={handleChange}
            value={selectedBatch.graduationDate}
            min={selectedBatch.classEndDate}
          />
        </div>
        <div css={styles.halfFormRowStyles}>
          <div css={[styles.formRowStyles, styles.halfWidthColumnStyles]}>
            <label>discount price</label>
            <Input
              type="number"
              placeholder="batch discount price"
              name="discountPrice"
              containerStyles={styles.selectContainerStyles}
              styles={styles.inputStyles}
              onChange={handleChange}
              value={selectedBatch.discountPrice}
            />
          </div>
          <div css={[styles.formRowStyles, styles.halfWidthColumnStyles]}>
            <label>discount end date</label>
            <Input
              type="date"
              name="discountEndDate"
              containerStyles={styles.selectContainerStyles}
              styles={styles.inputStyles}
              onChange={handleChange}
              value={selectedBatch.discountEndDate}
            />
          </div>
        </div>
        <div css={styles.formRowStyles}>
          <label>normal price</label>
          <Input
            type="text"
            placeholder="batch price"
            name="basePrice"
            containerStyles={styles.selectContainerStyles}
            styles={styles.inputStyles}
            onChange={handleChange}
            value={millionsSeparator(selectedBatch.basePrice)}
          />
        </div>
        <Button
          styles={styles.buttonSubmitStyles}
          type="submit"
          onClick={handleSubmit}
        >
          submit
        </Button>
      </form>
    </Modal>
  );
};

EditBatchModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  setModal: PropTypes.func.isRequired,
  data: PropTypes.shape({
    id: PropTypes.number,
    batch_type: PropTypes.shape({
      id: PropTypes.number,
    }),
    program: PropTypes.shape({
      id: PropTypes.number,
    }),
    campus_location: PropTypes.shape({
      id: PropTypes.number,
    }),
    code: PropTypes.string,
    registration_start_date: PropTypes.string,
    registration_end_date: PropTypes.string,
    class_preparation_start_date: PropTypes.string,
    class_preparation_end_date: PropTypes.string,
    class_start_date: PropTypes.string,
    class_end_date: PropTypes.string,
    discount_end_date: PropTypes.string,
    graduation_date: PropTypes.string,
    schedule: PropTypes.shape({}),
    discount_price: PropTypes.number,
    base_price: PropTypes.number,
  }),
  setSelectedData: PropTypes.func.isRequired,
  setDataBatches: PropTypes.func.isRequired,
  options: PropTypes.shape({
    batchOptions: PropTypes.arrayOf(PropTypes.shape({})),
    campusLocations: PropTypes.arrayOf(PropTypes.shape({})),
    programOptions: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

EditBatchModal.defaultProps = {
  data: {},
};

export default EditBatchModal;
