/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';

import {
  Modal, Select, Input, Button,
} from 'components/common';
import {
  modalStyles,
  formStyles,
  inputStyles,
  formRowContainerStyles,
  selectStyles,
} from './EditInvitationModal.styles';

const EditInvitationModal = ({
  isEditModalOpen,
  setEditModal,
  selectedData,
  handleChange,
  handleSubmit,
}) => (
  <Modal
    styles={modalStyles}
    isModalOpen={isEditModalOpen}
    setModal={setEditModal}
    closeModal={() => setEditModal(false)}
  >
    <form css={formStyles}>
      <div css={formRowContainerStyles}>
        <label>Attendance status</label>
        <Select
          options={[
            {
              label: 'show',
              value: 'show',
            },
            {
              label: 'no show',
              value: 'no_show',
            },
          ]}
          styles={selectStyles}
          value={selectedData.attendance_status}
          onChange={handleChange}
          name="attendance_status"
          placeholder="select attendance status"
        />
      </div>
      <div css={formRowContainerStyles}>
        <label>Final score</label>
        <Input
          styles={inputStyles}
          type="number`"
          value={selectedData.final_score}
          onChange={handleChange}
          name="final_score"
        />
      </div>
      <div css={formRowContainerStyles}>
        <label>Test result</label>
        <Select
          styles={selectStyles}
          options={[
            {
              label: 'recommended',
              value: 'recommended',
            },
            {
              label: 'not recommended',
              value: 'no_recommended',
            },
            {
              label: 'waiting list',
              value: 'waiting_list',
            },
          ]}
          value={selectedData.test_result}
          onChange={handleChange}
          name="test_result"
          placeholder="select test result"
        />
      </div>
      <div css={formRowContainerStyles}>
        <label>Notes</label>
        <Input
          styles={inputStyles}
          type="text"
          value={selectedData.notes}
          onChange={handleChange}
          name="notes"
        />
      </div>
      <Button
        onClick={handleSubmit}
        type="submit"
      >
        edit data
      </Button>
    </form>
  </Modal>
);

EditInvitationModal.propTypes = {
  isEditModalOpen: PropTypes.bool.isRequired,
  setEditModal: PropTypes.func.isRequired,
  selectedData: PropTypes.shape({
    attendance_status: PropTypes.string,
    final_score: PropTypes.number,
    test_result: PropTypes.string,
    notes: PropTypes.string,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default EditInvitationModal;
