/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { fetchApplicantStatusOptions } from 'helpers';
import Sidebar from 'components/layout/Sidebar/Sidebar';
import {
  CustomDatepicker as Datepicker,
  Select2,
  Select,
  Button,
} from 'components/common';
import testResultOptions from 'constants/testResultOptions';

import {
  filterConditionsContainerStyles,
  datepickerContainerStyles,
  select2ContainerStyles,
  selectContainerStyles,
  buttonContainerStyles,
  buttonStyles,
} from './Sidebar.styles';

const FTPSidebar = ({
  startDate,
  endDate,
  setDateRange,
  filterCondition,
  handleChange,
  handleSelect2Change,
  handleSubmit,
  handleReset,
  // handleDownloadCSV,
  // isCsvDownloading,
}) => {
  const [applicantStatusOptions, setApplicantStatusOptions] = useState([]);

  useEffect(() => {
    fetchApplicantStatusOptions()
      .then((data) => {
        setApplicantStatusOptions(data);
      });
  }, []);

  return (
    <Sidebar>
      <div css={filterConditionsContainerStyles}>
        <Datepicker
          containerStyles={datepickerContainerStyles}
          startDate={startDate}
          endDate={endDate}
          setDateRange={setDateRange}
          placeholder="date range"
        />
        <Select
          containerStyles={selectContainerStyles}
          name="testResult"
          options={testResultOptions}
          placeholder="test result"
          value={filterCondition.testResult}
          onChange={handleChange}
        />
        <Select2
          containerStyles={select2ContainerStyles}
          defaultValue={{ label: 'applicant status', value: '' }}
          options={applicantStatusOptions}
          placeholder="applicant status"
          onChange={(e) => handleSelect2Change(e, 'applicantStatus')}
          value={filterCondition.applicantStatus}
        />
      </div>
      <div css={buttonContainerStyles}>
        <Button type="button" onClick={handleSubmit} styles={buttonStyles}>apply</Button>
        <Button type="button" onClick={handleReset} className="reset" styles={buttonStyles}>reset filter</Button>
        {/* <Button
          disabled
          type="button"
          className="download"
          styles={buttonStyles}
          onClick={handleDownloadCSV}
        >
          {isCsvDownloading ? 'Downloading CSV...' : 'Download CSV'}
        </Button> */}
      </div>
    </Sidebar>
  );
};

FTPSidebar.propTypes = {
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  setDateRange: PropTypes.func.isRequired,
  filterCondition: PropTypes.shape({
    applicantStatus: PropTypes.oneOfType(
      [
        PropTypes.string,
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.string,
        }),
      ],
    ),
    testResult: PropTypes.string,
    program: PropTypes.string,
    attendanceStatus: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSelect2Change: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
  // handleDownloadCSV: PropTypes.func.isRequired,
  // isCsvDownloading: PropTypes.bool.isRequired,
};

FTPSidebar.defaultProps = {
  startDate: null,
  endDate: null,
};

export default FTPSidebar;
