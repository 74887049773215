/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import selectContainerStyles from './Select2.styles';

const Select2 = ({
  defaultValue,
  value,
  containerStyles,
  options,
  onChange,
  placeholder,
  ...rest
}) => (
  <Select
    classNamePrefix="select2"
    css={[selectContainerStyles, containerStyles]}
    value={value}
    defaultValue={defaultValue}
    options={options}
    onChange={onChange}
    isSearchable
    isClearable
    placeholder={placeholder}
    {...rest}
  />
);

Select2.propTypes = {
  containerStyles: PropTypes.shape({}),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  })]),
  defaultValue: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

Select2.defaultProps = {
  containerStyles: css``,
  value: { label: '', value: '' },
  defaultValue: { label: '', value: '' },
  placeholder: '',
};

export default Select2;
