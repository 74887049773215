/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import api from 'api/server';

import {
  Modal, Select, Input, Button,
} from 'components/common';
import getAllUsers from 'api/user/getAllUsers';

import {
  modalStyles,
  formStyles,
  datepickerContainerStyles,
  inputStyles,
  formRowContainerStyles,
  selectStyles,
  selectInterviewStyles,
} from './CreateInvitationModal.styles';

const CreateInvitationModal = ({
  isCreateModalOpen,
  setCreateModal,
  formData,
  handleChange,
  handleDateChange,
  handleSubmit,
}) => {
  const [campusOptions, setCampusOptions] = useState([]);
  const [users, setUsers] = useState([]);

  async function fetchCampusLocations() {
    const { data } = await api.getAllCampusLocations();
    const optionsArr = data.data.map((d) => ({
      label: d.location,
      value: d.id,
    }));
    setCampusOptions(optionsArr);
  }

  async function fetchAllusers() {
    const response = await getAllUsers();
    const modifiedArr = response.data.data.data.map((user) => ({
      label: user.email,
      value: user.email,
    }));
    setUsers(modifiedArr);
  }

  useEffect(() => {
    fetchCampusLocations();
    fetchAllusers();
  }, []);
  return (
    <Modal
      styles={modalStyles}
      isModalOpen={isCreateModalOpen}
      setModal={setCreateModal}
      closeModal={() => setCreateModal(false)}
    >
      <form css={formStyles}>
        <div css={formRowContainerStyles}>
          <label>Campus Location</label>
          <Select
            options={campusOptions}
            styles={selectStyles}
            value={formData.campus_location_id}
            onChange={handleChange}
            name="campus_location_id"
          />
        </div>
        <div css={formRowContainerStyles}>
          <label>Test type</label>
          <Select
            styles={selectStyles}
            options={[
              {
                label: 'online',
                value: 'ONLINE',
              },
              {
                label: 'on site',
                value: 'ON_SITE',
              },
            ]}
            value={formData.test_type}
            onChange={handleChange}
            name="test_type"
          />
        </div>
        <div css={formRowContainerStyles}>
          <label>Interviewer</label>
          <Select
            placeholder="Select interviewer"
            styles={selectInterviewStyles}
            name="interviewed_by"
            onChange={handleChange}
            options={users}
            value={formData.interviewed_by}
          />
        </div>
        <div css={formRowContainerStyles}>
          <label>Interview date</label>
          <div css={datepickerContainerStyles}>
            <DatePicker
              selected={formData.scheduled_test_at}
              onChange={(date) => handleDateChange(date)}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              timeCaption="Time"
              dateFormat="MMMM d, yyyy h:mm aa"
            />
          </div>
        </div>
        <div css={formRowContainerStyles}>
          <label>Online interview Link</label>
          <Input
            className="lowercase"
            styles={inputStyles}
            type="text"
            value={formData.online_interview_url}
            onChange={handleChange}
            name="online_interview_url"
          />
        </div>
        <Button
          onClick={handleSubmit}
          type="submit"
        >
          send invitation
        </Button>
      </form>
    </Modal>
  );
};

CreateInvitationModal.propTypes = {
  isCreateModalOpen: PropTypes.bool.isRequired,
  setCreateModal: PropTypes.func.isRequired,
  formData: PropTypes.shape({
    applicant_id: PropTypes.number,
    campus_location_id: PropTypes.number,
    interviewed_by: PropTypes.string,
    scheduled_test_at: PropTypes.instanceOf(Date),
    test_type: PropTypes.string,
    is_online: PropTypes.bool,
    online_interview_url: PropTypes.string,
    attendance_status: PropTypes.string,
    final_score: PropTypes.number,
    test_result: PropTypes.string,
    notes: PropTypes.string,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleDateChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default CreateInvitationModal;
