import { css } from '@emotion/react';

import color from '../color';

export const tableContainerStyles = css`
  display: flex;
  flex-direction: column;
  border-collapse: collapse;
  margin: 20px 0;
  width: 100%;
`;

export const tableStyles = css`
  color: ${color.BLACK};
  border-width: 1px 0 0 0;
  border-style: solid;
  border-color: ${color.GREY};
  border-spacing: 2px;
  height: 100vh;
  overflow: auto;
  width: 100%;
`;

export const tableHeaderContainerStyles = css`
  display: flex;
  position: sticky;
  top: 0;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: ${color.WHITE};

  // @media screen and (max-width: 1400px) {
  //   width: 1500px;
  // }
`;

export const tableHeaderStyles = (ratio) => css`
  display: flex;
  align-items: center;
  width: ${100 * ratio}%;
  padding: 0 5px;
  border-bottom: 1px solid ${color.PRIMARY_GREY};
  outline: none;
  height: 30px;
  
  span {
    width: 100%;
    font-weight: bold;
  };

  &.left {
    justify-content: flex-start;
  };

  &.center {
    justify-content: center;
  };

  &.right {
    justify-content: flex-end;
  };
`;

export const tableBodyContainerStyles = css`
  display: flex;
  flex-direction: column;
  border-left-width: 0;
  border-right-width: 0;
  width: 100%;

  // @media screen and (max-width: 1400px) {
  //   width: 1500px;
  //   overflow-x: scroll;
  // }
`;

export const tableDataContainerStyles = css`
  display: flex;
  align-items: center;
  width: 100%;

  &:hover {
    background-color: ${color.BLUE};
    span, a {
      color: ${color.WHITE};
    }
  };

  &:nth-of-type(odd) {
    background-color: ${color.GREY};

    &:hover {
      background-color: ${color.BLUE};
    };
  };
  
`;

export const linkStyles = (ratio) => css`
  width: ${100 * ratio}%;
  text-decoration: none;
  color: ${color.BLACK};
  min-height: 40px;
  display: flex;
  align-items: center;
  padding: 0 5px;

  span,p {
    width: 100%;
    display: flex;
    align-items: center;
    white-space: pre-line;
    text-transform: capitalize;
    font-size: 14px;

    &.lowercase {
      text-transform: lowercase;
    }
  };

  &.left {
    justify-content: flex-start;
  };

  &.center {
    justify-content: center;
  };

  &.right {
    justify-content: flex-end;
  };

  &.inactive {
    pointer-events: none;
    cursor: default;
  }
`;

export const tableDataStyles = (ratio) => css`
  display: flex;
  align-items: center;
  width: ${100 * ratio}%;
  height: 40px;
  text-decoration: none;

  span,p {
    display: flex;
    align-items: center;
    color: ${color.BLACK};
    white-space: pre-line;
    text-transform: capitalize;
    font-size: 12px;
    margin: 0 0 0 5px;
    
    a {
      cursor: pointer;
      color: ${color.PRIMARY_BLUE};
      text-decoration: none;
    };
  };

  div {
    &.couple-spans {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 100%;

      @media screen and (max-width: 1450px) {
        // padding: 0 10px;
      };

      button {
        border-radius: 5px;
        padding: 5px;
        margin-left: 0;
        height: 40px;
        text-transform: capitalize;
        cursor: pointer;
        
        &:nth-of-type(2) {
          margin: 0 10px;
        };

        @media screen and (max-width: 1450px) {
          padding: 5px;
          margin: 0 5px;
        };

        a {
          text-decoration: none;
          text-transform: capitalize;
          color: ${color.BLACK};
          font-size: 14px;
          font-weight: 600;

          @media screen and (max-width: 1450px) {
            font-size: 10px;
          };
        };
      };
    };
  };

  &.left {
    justify-content: flex-start;
  };

  &.center {
    justify-content: center;
  };

  &.right {
    justify-content: flex-end;
  };
`;
