/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  fetchBatchOptions,
  fetchPaymentMethodOptions,
  fetchPaymentStatusOptions,
  fetchFollowUpStatusOptions,
} from 'helpers';

import Sidebar from 'components/layout/Sidebar/Sidebar';
import {
  CustomDatepicker as Datepicker,
  Select2,
  Button,
  CreatableSelect,
} from 'components/common';
import interviewers from 'constants/interviewers';
import {
  filterConditionsContainerStyles,
  datepickerContainerStyles,
  select2ContainerStyles,
  buttonContainerStyles,
  buttonStyles,
} from './Sidebar.styles';

import * as styles from './Sidebar.styles';

const FTPSidebar = ({
  startDate,
  callDate,
  endDate,
  callEndDate,
  setDateRange,
  setCallRange,
  filterCondition,
  setFilterCondition,
  handleSelect2Change,
  handleSubmit,
  handleReset,
}) => {
  const [options, setOptions] = useState({
    batchOptions: [],
    paymentMethodOptions: [],
    paymentStatusOptions: [],
    followUpStatusOptions: [],
  });

  const getFollowUpStatusOptions = () => fetchFollowUpStatusOptions().then((resp) => resp);

  useEffect(async () => {
    const [
      batchOptions,
      paymentMethodOptions,
      paymentStatusOptions,
      followUpStatusOptions,
    ] = await Promise.all([
      fetchBatchOptions(),
      fetchPaymentMethodOptions(),
      fetchPaymentStatusOptions(),
      getFollowUpStatusOptions(),
    ]);
    setOptions((prevState) => ({
      ...prevState,
      batchOptions,
      paymentMethodOptions,
      paymentStatusOptions,
      followUpStatusOptions,
    }));
  }, []);

  return (
    <Sidebar>
      <div css={filterConditionsContainerStyles}>
        <Datepicker
          containerStyles={datepickerContainerStyles}
          startDate={startDate}
          endDate={endDate}
          setDateRange={setDateRange}
          placeholder="date range"
        />
        <Datepicker
          containerStyles={styles.datepickerContainerStyles}
          startDate={callDate}
          endDate={callEndDate}
          setDateRange={setCallRange}
          placeholder="call date range"
        />
        <Select2
          containerStyles={select2ContainerStyles}
          defaultValue={{ label: 'applied batch', value: '' }}
          options={options.batchOptions}
          placeholder="applied batch"
          onChange={(e) => handleSelect2Change(e, 'appliedBatch')}
          value={filterCondition.appliedBatch}
        />
        <Select2
          containerStyles={select2ContainerStyles}
          defaultValue={{ label: 'enrolled batch', value: '' }}
          options={options.batchOptions}
          placeholder="enrolled batch"
          onChange={(e) => handleSelect2Change(e, 'enrolledBatch')}
          value={filterCondition.enrolledBatch}
        />
        <CreatableSelect
          containerStyles={select2ContainerStyles}
          options={options.paymentMethodOptions}
          placeholder="payment type"
          value={filterCondition.paymentType}
          name="paymentType"
          filterCondition={filterCondition}
          setFilterCondition={setFilterCondition}
        />
        <CreatableSelect
          containerStyles={select2ContainerStyles}
          options={options.paymentStatusOptions}
          placeholder="payment status"
          value={filterCondition.paymentStatuses}
          name="paymentStatuses"
          filterCondition={filterCondition}
          setFilterCondition={setFilterCondition}
        />
        <CreatableSelect
          containerStyles={styles.select2ContainerStyles}
          options={options.followUpStatusOptions}
          placeholder="follow up status"
          value={filterCondition.followUpStatus}
          name="followUpStatus"
          filterCondition={filterCondition}
          setFilterCondition={setFilterCondition}
        />
        <CreatableSelect
          filterCondition={filterCondition}
          setFilterCondition={setFilterCondition}
          containerStyles={styles.select2ContainerStyles}
          options={interviewers}
          placeholder="follow up by"
          value={filterCondition.followUpBy}
          name="followUpBy"
        />
      </div>
      <div css={buttonContainerStyles}>
        <Button type="button" onClick={handleSubmit} styles={buttonStyles}>apply</Button>
        <Button type="button" onClick={handleReset} className="reset" styles={buttonStyles}>reset filter</Button>
      </div>
    </Sidebar>
  );
};

FTPSidebar.propTypes = {
  startDate: PropTypes.instanceOf(Date),
  callDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  callEndDate: PropTypes.instanceOf(Date),
  setDateRange: PropTypes.func.isRequired,
  setCallRange: PropTypes.func.isRequired,
  filterCondition: PropTypes.shape({
    appliedBatch: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
    enrolledBatch: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
    paymentType: PropTypes.arrayOf(PropTypes.shape({})),
    application_result: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
    test_result: PropTypes.string,
    followUpStatus: PropTypes.string,
    followUpBy: PropTypes.string,
    program: PropTypes.string,
    paymentStatuses: PropTypes.arrayOf(PropTypes.shape({})),
    search: PropTypes.string,
  }).isRequired,
  setFilterCondition: PropTypes.func.isRequired,
  // handleChange: PropTypes.func.isRequired,
  handleSelect2Change: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
  // handleDownloadCSV: PropTypes.func.isRequired,
  // isCsvDownloading: PropTypes.bool.isRequired,
};

FTPSidebar.defaultProps = {
  startDate: null,
  endDate: null,
  callDate: null,
  callEndDate: null,
};

export default FTPSidebar;
