/* eslint-disable */

const interviewers = [
  // {
  //   label: 'monika',
  //   value: 'monika@hacktiv8.com',
  // },
  // {
  //   label: 'devianti',
  //   value: 'devianti@hacktiv8.com',
  // },
  {
    label: 'Inggha',
    value: 'ifebyolla@hacktiv8.com',
  },
  {
    label: 'Mega',
    value: 'mpratiwi@hacktiv8.com',
  },
  // {
  //   label: 'Bukhari',
  //   value: 'bmuhamad@hacktiv8.com',
  // },
  // {
  //   label: 'Gloria',
  //   value: 'gmanurung@hacktiv8.com',
  // },
  {
    label: 'Jessica Sari',
    value: 'jsari@hacktiv8.com',
  },
  {
    label: 'Aulia',
    value: 'anuralita@hacktiv8.com',
  },
  {
    label: 'Elsya',
    value: 'ejonathan@hacktiv8.com',
  },
  {
    label: 'Rawi',
    value: 'rawi@hacktiv8.com',
  },
  {
    label: 'Enis',
    value: 'enis@hacktiv8.com',
  },
];

export default interviewers;
