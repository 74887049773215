/* eslint-disable */
import React from 'react';
import { fullCalendarFormat } from 'helpers';

const tableConfig = [
  {
    key: 'id',
    label: 'ID',
    ratio: 0.05,
    textAlign: 'left',
    component: (d) => (
      <span>
        {d}
      </span>
    ),
  },
  {
    key: 'registered_batch',
    label: 'applied batch',
    ratio: 0.15,
    textAlign: 'left',
    component: (d) => (
      <span>
        {d}
      </span>
    ),
  },
  {
    key: 'name',
    label: 'name',
    ratio: 0.2,
    textAlign: 'left',
    component: (d) => (
      <span>
        {d}
      </span>
    ),
  },
  {
    key: 'payment_option',
    label: 'payment type',
    ratio: 0.1,
    textAlign: 'left',
    component: (d) => (
      <span style={{ fontSize: 12 }}>
        {/* {fullCalendarFormat(d)} */}
        {d ? d.toUpperCase() : ''}
      </span>
    ),
  },
  {
    key: 'payment_status',
    label: 'payment status',
    ratio: 0.15,
    textAlign: 'center',
    component: (d) => (
      <span>
        {/* {fullCalendarFormat(d)} */}
        {d ? d.replace('_', ' ') : ''}
      </span>
    ),
  },
  {
    key: 'payment_due',
    label: 'payment due date',
    ratio: 0.175,
    component: (d) => (
      <span>
        {fullCalendarFormat(d)}
        {/* {d} */}
      </span>
    ),
  },
  {
    key: 'enrolled_batch',
    label: 'enrolled batch',
    ratio: 0.175,
    textAlign: 'left',
    component: (d) => (
      <span>
        {d}
      </span>
    ),
  },
  // {
  //   key: 'action',
  //   label: 'action',
  //   ratio: 0.05,
  //   textAlign: 'center',
  //   component: (d) => d,
  // },
];

export default tableConfig;
