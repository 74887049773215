import { css } from '@emotion/react';
import color from 'components/common/color';

export const navbarStyles = css`
  width: 75%;
  margin: 5px 20px 0;
  display: flex;
  flex-direction: column;
  font-family: "Roboto", sans-serif;
`;

export const breadCrumbLogoutContainerStyles = css`
  display: flex;
  // width: 100%;
  width: calc(100% + 10%);
  justify-content: space-between;
  padding: 0 10px 0 0;
`;

export const breadcrumbContainerStyles = css`
  display: flex;
  align-items: center;
  padding: 8px;
  
  span {
    text-transform: capitalize;
    font-weight: 600;
    color: ${color.BLUE};

    &:first-of-type {
      margin-right: 10px;
    }

    &:last-of-type {
      margin-left: 10px;
    }
  }
`;

export const logoutButtonStyles = css`
  width: fit-content;
  background: #B61919;
`;

export const buttonsContainerStyles = css`
  display: flex;
  width: 100%;
  margin: 5px 0 10px;
  justify-content: space-between;
  
  button {
    &:first-of-type {
      width: fit-content;
    }
  }

  div {
    width: 25%;
    justify-content: space-between;
    display: flex;
  }
`;

export const buttonModuleStyles = (str) => css`
  background-color: ${str === 'color' ? '#5B8A72' : color.BLUE};
  width: fit-content;
  padding: 0 15px;

  a {
    text-decoration: none;
    text-transform: capitalize;
    color: ${color.WHITE};
    transition: all 0.2 ease-in-out;
  };
`;

export const subButtonsContainerStyles = css`
  width: 100%;
`;

export const ftpContainerStyles = css`
  display: flex;
  margin-left: 45%;

  @media only screen and (min-width: 1800px) {
    margin-left: 50%;
  }
`;

export const ptpContainerStyles = css`
  display: flex;
  justify-content: flex-end;
  button {
    // width: fit-content;
    padding: 0 10px;
    border-radius: 5px;
    border: 0 2px 0 0;
  }
`;

export const buttonSubmoduleStyles = (str) => css`
  background-color: ${str === 'color' ? '#5B8A72' : color.GREY};
  width: fit-content;
  // width: 30%;
  padding: 5px 10px;
  margin: 0 10px 0;
  color: ${str === 'color' ? color.WHITE : color.BLUE};
`;

export const applicantModuleStyles = (program) => css`
  display: flex;
  justify-content: ${program === 'ptp' ? 'flex-end' : 'center'};
  align-items: center;
  margin: 5px 0 0;
  padding: 8px;
  width: 100%;
  height: 40px;
`;

export const buttonApplicantModuleStyles = (str) => css`
  background-color: ${str === 'color' ? '#5B8A72' : color.GREY};
  width: fit-content;
  margin: 0 10px 0;
  padding: 5px 10px;
  color: ${str === 'color' ? color.WHITE : color.BLUE};
`;
