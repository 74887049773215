import { fullCalendarTimeFormat } from './moment';
import convertPhoneNumber from './phoneNumberConverter';

async function jsonToCSV(json, headers) {
  const replacer = (_, value) => (value === null ? '' : value);
  const headersLabel = headers.map(({ label }) => label);
  const csv = [
    headersLabel.join(','),
    ...json.map((row) => headers
      .map(({ key }) => {
        if (key === 'phone_number') {
          return convertPhoneNumber(JSON.stringify(row[key], replacer));
        }
        return JSON.stringify(row[key], replacer);
      })
      .join(',')),
  ].join('\r\n');
  return csv;
}

function createDownloadLink(csv, fileName) {
  const downloadLink = document.createElement('a');
  const blob = new Blob(['\ufeff', csv]);
  const url = URL.createObjectURL(blob);
  downloadLink.href = url;
  downloadLink.download = `${fileName}_${fullCalendarTimeFormat(new Date())}.csv`;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
}

async function handleDownloadTemplate(jsonArray, headers, fileName) {
  const csv = await jsonToCSV(jsonArray, headers);
  return createDownloadLink(csv, fileName);
}

export default handleDownloadTemplate;
