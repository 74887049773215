import { css } from '@emotion/react';

import colors from '../color';

export const reactSelectContainerStyles = css`
  width: 100%;
  position: relative;

  span {
    &.placeholder-span {
      position: absolute;
      top: 30%;
      left: 5%;
      
      &::first-letter {
        text-transform: uppercase;
      }
    }
  }
`;

export const labelStyles = css`
  text-transform: capitalize;
  font-weight: 500;
  position: absolute;
  top: 27%;
  left: 5%;
  z-index: 999;
  font-size: 12px;
`;

export const alertStyles = css`
  font-weight: 500;
  position: absolute;
  bottom: -37%;
  color: ${colors.DANGER_RED};
  left: 5%;
  z-index: 999;
  font-size: 12px;
`;

export const reactSelectStyles = (isLabelAvailable) => css`
  margin: ${isLabelAvailable ? '8px 0 0 0' : '0'};
  z-index: 0;
  .select__control {
    width: 100%;
    height: unset;
    padding: 5px 0;
    border: 1px solid ${colors.GREY};
    border: none;
    border-radius: 8px;
    transition: all 0.2s ease-in-out;
    cursor: pointer;

    &:hover {
      border-color: ${colors.LIGHT_BLUE};
    }

    .select__indicator-separator {
      background-color: ${colors.GREY};
    }

    .select__indicator {
      color: ${colors.BLACK};
      padding: 0 5px;
    }

    .select__value-container {
      .select__placeholder {
        padding: 0;
        text-transform: capitalize;
        font-size: 14px;
        color: ${colors.BLACK};
        display: block;
        position: absolute;
      }

      .select__multi-value {
        display: none;
      }
    }
    .select__clear-indicator {
      svg {
        display: none;
      }
    }
  }

  .select__menu {
    position: absolute;
    right: -425px;
    top: -10px;
    z-index: 99999;
    width: fit-content;

    .select__menu-list {
      display: flex;
      flex-wrap: wrap;
      width: 400px;
    }

    .select__option {
      width: 200px;
      padding: 4px 8px;
      display: flex;
      align-items: center;

      label {
        text-transform: lowercase;
        font-size: 14px;
        max-width: 175px;
        width: 150px;

        &::first-letter {
          text-transform: uppercase;
        }
      }
    }
  }
`;
