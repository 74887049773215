/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import { fetchFollowUpStatusOptions } from 'helpers';

import {
  Table, Button, Modal, Select, Input,
} from 'components/common';
import tableConfig from 'components/config/ApplicantDetails/HistoryCall/tableConfig';
import {
  containerStyles, modalStyles, dataContainerStyles,
  inputStyles, buttonSubmitStyles, selectContainerStyles, tableContainerStyles,
} from './HistoryCall.styles';

const HistoryCall = ({
  tableData,
  isModalOpen,
  setModal,
  newCallData,
  handleChange,
  handleSubmit,
}) => {
  const [followUpStatusesOptions, setFollowUpStatusesOptions] = useState([]);

  useEffect(() => {
    fetchFollowUpStatusOptions()
      .then((data) => {
        const modifiedArr = data.map((d) => ({
          label: d.label,
          value: d.id,
        }));
        setFollowUpStatusesOptions(modifiedArr);
      });
  }, []);

  return (
    <div css={containerStyles}>
      <div css={css`width: 100%; display: flex; align-items: center; margin: 10px 0 0 0`}>
        <Table
          containerStyles={tableContainerStyles}
          tableConfig={tableConfig}
          pagination={null}
          showPagination={false}
          data={tableData}
          isHoverable={false}
        />
      </div>
      <Modal
        styles={modalStyles}
        isModalOpen={isModalOpen}
        setModal={setModal}
        closeModal={() => setModal(false)}
      >
        <form onSubmit={handleSubmit}>
          <div css={dataContainerStyles}>
            <label>follow up status</label>
            <Select
              placeholder="select follow up status"
              name="followUpStatusId"
              containerStyles={selectContainerStyles}
              onChange={handleChange}
              options={followUpStatusesOptions}
              value={newCallData.followUpStatusId}
            />
          </div>
          <div css={dataContainerStyles}>
            <label>follow up by</label>
            <Input
              className="lowercase"
              disabled
              styles={inputStyles}
              type="text"
              name="followUpBy"
              value={newCallData.followUpBy}
              onChange={handleChange}
              placeholder=""
            />
            {/* <Select
              containerStyles={selectContainerStyles}
              onChange={handleChange}
              options={followUpStatusOptions}
              value={newCallData.followUpBy}
            /> */}
          </div>
          <div css={dataContainerStyles}>
            <Input
              styles={inputStyles}
              type="text"
              name="notes"
              value={newCallData.notes}
              onChange={handleChange}
              placeholder="notes"
            />
          </div>
          <Button
            styles={buttonSubmitStyles}
            type="submit"
            onClick={handleSubmit}
          >
            submit
          </Button>
        </form>
      </Modal>
    </div>
  );
};

HistoryCall.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  setModal: PropTypes.func.isRequired,
  tableData: PropTypes.arrayOf(PropTypes.shape({
    follow_up_status: PropTypes.string,
    follow_up_by: PropTypes.string,
    contact_time: PropTypes.string,
    notes: PropTypes.string,
  })).isRequired,
  newCallData: PropTypes.shape({
    call: PropTypes.string,
    followUpStatusId: PropTypes.string,
    followUpBy: PropTypes.string,
    notes: PropTypes.string,
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default HistoryCall;
