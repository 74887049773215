/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';

import { Modal, Select } from 'components/common';
import { headingStyles, modalStyles, rowContainerStyles } from './Modal.styles';

const DetailModal = ({
  isModalOpen,
  setModal,
}) => {
  const closeModal = () => {
    setModal(false);
  };

  return (
    <Modal
      styles={modalStyles}
      isModalOpen={isModalOpen}
      closeModal={closeModal}
    >
      <h3 css={headingStyles}>details menu</h3>
      <div css={rowContainerStyles}>
        <label>test type</label>
        <Select
          options={[]}
          name="test_type"
          disabled
        />
      </div>
      <div css={rowContainerStyles}>
        <label>campus location</label>
        <Select
          options={[]}
          name="campus_location"
        />
      </div>
      <div css={rowContainerStyles}>
        <label>interview date</label>
        <Select
          options={[]}
          name="test_type"
          disabled
        />
      </div>
    </Modal>
  );
};

DetailModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  setModal: PropTypes.func.isRequired,
};

export default DetailModal;
