/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import PropTypes from 'prop-types';

import iconStyles from './Icon.styles';

const Icon = ({
  name,
  styles,
  ...rest
}) => (
  <i className={`fas fa-${name}`} css={[iconStyles, styles]} {...rest} />
);

Icon.propTypes = {
  styles: PropTypes.shape({}),
  name: PropTypes.string.isRequired,
};

Icon.defaultProps = {
  styles: css``,
};

export default Icon;
