import { css } from '@emotion/react';

export const headingStyles = css`
  text-transform: capitalize;
  padding: 0 0 5px;
  margin: 0 0 10px;
  border-bottom: 1px solid black;
`;

export const modalStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    boxShadow: '0px 3px 30px #0000000d',
    width: '400px',
    height: '60vh',
    minHeight: '40vh',
  },
};

export const rowContainerStyles = css`
  margin: 0 0 10px;

  label {
    text-transform: capitalize;
  }
`;
