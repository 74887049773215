import { css } from '@emotion/react';

import colors from 'components/common/color';

export const modalStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    boxShadow: '0px 3px 30px #0000000d',
    width: '500px',
    height: '600px',
    overflowY: 'scroll',
  },
};

export const inputStyles = css`
  // width: 100%;
  margin: 5px 0;
  height: 30px;
`;

export const selectContainerStyles = css`
  width: 100%;
`;

export const notifStyles = (type) => css`
  color: ${type === 'error' ? colors.DANGER_RED : colors.LIGHT_BLUE};
  margin: 0 0 10px;
  font-weight: 600;
  display: block;
`;

export const buttonSubmitStyles = css`
  margin: 15px 0;
`;

export const halfFormRowStyles = css`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const halfWidthColumnStyles = css`
  width: 48%;
`;

export const formRowStyles = css`
  display: flex;
  flex-direction: column;
  margin: 0 0 20px;

  label {
    font-family: "Roboto", sans-serif;
    text-transform: capitalize;
    margin: 0 0 3px;
  };
`;
