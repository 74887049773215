const Color = {
  WHITE: '#FFF',
  BLACK: '#000',
  BLUE: '#1d3d70',
  ORANGE: '#f5932e',
  GREY: '#ebebeb',
  DARK_GREY: '#D6D2C4',
  DISABLED: '#999999',
  DANGER_RED: '#FF0000',
  TRANSPARENT: 'transparent',
  LIGHT_BLUE: '#1E5BBD',
};

export default Color;
