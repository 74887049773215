import { css } from '@emotion/react';

export const layoutContainerStyles = (zIndex) => css`
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: ${zIndex};
`;

export const childrenContainerStyles = css`
  width: 100%;
  margin: 0 auto 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: none;
`;

export const buttonContainerStyles = css`
  width: 100%;
  display: flex;
  justify-content: flex-start;
`;

export const createButtonStyles = css`
  width: fit-content;
`;

export const buttonStyles = css`
  width: fit-content;
  background: none;

  &:hover {
    i {
      color: #fff;
    }
  }
`;

export const navbarContainerStyles = css`
  width: calc(90% - 40px);
  padding: 0 10px;
  border: none;
`;

export const rowContainerStyles = css`
  display: flex;
  width: 90%;
  padding: 5px 0 10px;
`;

export const labelValueContainerStyles = css`
  display: flex;
  flex-direction: column;
  width: 25%;
  margin: 0 10px;
  padding: 5px;

  // &.large-container {
  //   width: 50%;
  //   margin: 0 40px 0 10px;
  // }

  label, span {
    text-transform: capitalize;
    padding: 5px 5px 5px 0;
  }

  label {
    font-weight: 600;
    margin: 0 0 5px;
  }

`;

export const buttonRowStyles = css`
  display: flex;
  width: calc(90% - 40px);
  justify-content: flex-end;
  margin-top: 10px;

  button {
    width: fit-content;
  }
`;

export const notificationContainerStyles = css`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const notificationBannerStyles = (status) => css`
  background: ${status ? '#39A388' : '#FCD2D1'};
  width: 100%;
  display: flex;
  align-items: center;
  height: 30px;
  padding: 8px;
  margin: 20px 0;

  span {
    font-weight: 600;
    
    &:first-letter {
      text-transform: uppercase;
    }
  }
`;

export const tableContainerStyles = css`
  width: 90%;
`;

export const tableDataContainerStyles = css`
  height: 50px;
`;

export const linkStyles = css`
  text-transform: none;
  margin-left: 5px;
`;

export const tableValueStyles = css`
  margin-left: 10px;
`;
