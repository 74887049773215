import PropTypes from 'prop-types';
import Snackbar from '@mui/material/Snackbar';
// eslint-disable-next-line import/no-unresolved

const SimpleSnackbar = ({
  message,
  open,
  setOpen,
}) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={3000}
      onClose={handleClose}
      message={message}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    />
  );
};

SimpleSnackbar.propTypes = {
  message: PropTypes.string,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

SimpleSnackbar.defaultProps = {
  message: '',
};

export default SimpleSnackbar;
