/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import Layout from 'components/layout';
import { Button } from 'components/common';
import * as styles from 'components/pages/FTP/Applicants/TestInfo/TestInfo.styles';
import api from 'api/server';

const TestInfo = () => {
  const { scheduleId } = useParams();
  const [notif, setNotif] = useState({
    showNotif: false,
    status: false,
    message: '',
  });
  const [testInfo, setTestInfo] = useState({});
  const [testInvites, setTestInvites] = useState([]);

  async function fetchTestInvites(id) {
    const { data } = await api.getTestInvites(id);
    const modArr = data.data.map((d) => ({
      id: d.id,
      name: d.name,
      email: d.email,
      attendanceStatus: d.attendance_status,
      score: d.final_score,
      testResult: d.test_result,
      notes: d.notes,
    }));
    setTestInvites(modArr);
  }

  async function fetchTestInfo() {
    const { data } = await api.getTestInfo(scheduleId);
    setTestInfo(data.data);
    fetchTestInvites(data.data.id);
  }

  useEffect(() => {
    fetchTestInfo();
  }, []);

  const handleTestInfoChange = (e, i) => {
    const clonedArr = [...testInvites];
    const selectedObj = { ...testInvites[i] };
    selectedObj[e.target.name] = e.target.value;
    clonedArr[i] = selectedObj;
    setTestInvites(clonedArr);
  };

  const handleBulkEdit = async () => {
    const modArr = testInvites.map((d) => ({
      id: d.id,
      attendance_status: d.attendanceStatus,
      final_score: +d.score,
      test_result: d.testResult,
      notes: d.notes,
    }));
    const { data } = await api.editBulkTestInvites({
      applicant_tests: modArr,
    });
    setNotif({
      showNotif: true,
      status: data.status,
      message: data.data,
    });
  };

  return (
    <Layout
      childrenContainerStyles={styles.childrenContainerStyles}
      containerStyles={styles.layoutContainerStyles}
      navbarStyles={styles.navbarContainerStyles}
    >
      <div css={styles.notificationContainerStyles}>
        {
          notif.showNotif && (
            <div css={styles.notificationBannerStyles(notif.status)}>
              <span>{notif.message}</span>
            </div>
          )
        }
      </div>
      {
        testInfo.id && (
          <table css={styles.tableTestInfoStyles}>
            <thead>
              <tr>
                <th>Date and Time</th>
                <th>type</th>
                <th>location</th>
                <th>link</th>
                <th>total invitee</th>
                <th>interviewer</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{dayjs(testInfo.scheduled_test_at).subtract(7, 'hour').format('DD MMM YYYY on H:mm')}</td>
                <td>{testInfo.test_type}</td>
                <td>{testInfo.campus_location?.code}</td>
                <td>{testInfo.online_interview_url}</td>
                <td>{testInfo.aggregated_invitees}</td>
                <td>{testInfo.interviewed_by}</td>
              </tr>
            </tbody>
          </table>
        )
      }
      <div css={styles.tableTestInviteContainerStyles}>
        <span>invitee table</span>
        <table css={styles.tableInviteStyles}>
          <thead>
            <tr>
              <th>name</th>
              <th>email</th>
              <th>attendance status</th>
              <th>score</th>
              <th>final results</th>
              <th>notes</th>
            </tr>
          </thead>
          <tbody>
            {
              testInvites.map((d, i) => (
                <tr key={d.id}>
                  <td>{d.name}</td>
                  <td>{d.email}</td>
                  <td>
                    <select
                      name="attendanceStatus"
                      value={d.attendanceStatus}
                      onChange={(e) => handleTestInfoChange(e, i)}
                    >
                      <option value="">select attendance status</option>
                      <option value="no_show">no show</option>
                      <option value="show">show</option>
                      <option value="reschedule">reschedule</option>
                    </select>
                  </td>
                  <td className="score">
                    <input
                      name="score"
                      type="number"
                      value={d.score}
                      onChange={(e) => handleTestInfoChange(e, i)}
                      placeholder="fill in the score"
                    />
                  </td>
                  <td>
                    <select
                      name="testResult"
                      value={d.testResult}
                      onChange={(e) => handleTestInfoChange(e, i)}
                    >
                      <option value="">select test result</option>
                      <option value="recommended">recommended</option>
                      <option value="no_recommended">not recommended</option>
                      <option value="waiting_list">waiting list</option>
                    </select>
                  </td>
                  <td>
                    <input
                      name="notes"
                      type="text"
                      value={d.notes}
                      onChange={(e) => handleTestInfoChange(e, i)}
                      placeholder="some notes"
                    />
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
      <div css={styles.buttonContainerStyles}>
        <Button
          type="button"
          onClick={handleBulkEdit}
        >
          submit
        </Button>
      </div>
    </Layout>
  );
};

export default TestInfo;
