/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';
import { numeralTextConverter } from 'helpers';
import api from 'api/server';
import Layout from 'components/layout';
import { Button, Modal, Input } from 'components/common';
import PaymentDetail from 'components/pages/Applicant/PaymentDetail/PaymentDetail';
import * as styles from 'components/pages/Applicant/PaymentDetail/PaymentDetail.styles';

const DetailPayment = () => {
  const { id } = useParams();
  const history = useHistory();
  const [notif, setNotif] = useState({
    showNotif: false,
    status: false,
    message: '',
  });
  const [isModalOpen, setModalPayment] = useState(false);
  const [isNoteModalOpen, setModalNote] = useState(false);
  const [selectedNote, setSelectedNote] = useState({
    id: null,
    notes: null,
  });
  const [invoices, setInvoices] = useState([]);
  const [billingData, setBillingData] = useState({
    xeroInvoiceId: '',
    billAmount: '',
    paidAmount: '',
    refundableAmount: '',
    notes: '',
  });
  const [newInvoiceData, setNewInvoiceData] = useState({
    paymentStatusName: '',
    customAmount: 0,
  });
  const [zIndex, setZIndex] = useState('');

  async function fetchInvoices() {
    const { data } = await api.getAllInvoices(id);
    setInvoices(data.data);
  }

  async function fetchBillings() {
    try {
      const { data } = await api.getAllBillings(id);
      setBillingData({
        paymentStatus: data.data.payment_status,
        xeroInvoiceId: data.data.xero_invoice_id,
        billAmount: data.data.bill_amount,
        paidAmount: data.data.paid_amount,
        refundableAmount: data.data.refundable_amount,
        notes: data.notes,
      });
    } catch (error) {
      if (error.response.status === 401) {
        Cookies.remove('access_token');
        history.replace('/sign-in');
      }
    }
  }

  useEffect(() => {
    fetchInvoices();
    fetchBillings();
  }, []);

  const handleOpenNotesModal = (noteId, noteContent) => {
    setModalNote(true);
    setSelectedNote({
      id: noteId,
      notes: noteContent,
    });
  };

  const tableData = invoices.map((d) => ({
    id: d.id,
    key: d.id,
    amount: d.amount,
    subpage: 'payment-detail',
    isPaid: d.is_paid,
    paidAt: d.paid_at,
    paymentStatus: d.payment_status,
    xenditInvoiceId: d.xendit_invoice_id,
    invoiceExternalUrl: d.invoice_external_url,
    createdAt: d.created_at,
    action: (
      <span
        style={{ fontSize: '14px', cursor: 'pointer' }}
        role="button"
        tabIndex={0}
        onKeyDown={() => {}}
        onClick={() => handleOpenNotesModal(d.id, d.notes)}
      >
        {d.notes || '>'}
      </span>
    ),
  }));

  const handleChange = (e) => {
    setNewInvoiceData({
      ...newInvoiceData,
      [e.target.name]: e.target.value,
    });
  };

  const errorChecker = {
    STEP_1: {
      text: 'first payment must be more than 1 Million IDR Rupiah',
      amount: 1000000,
    },
    STEP_2: {
      text: 'second payment must be more than 5 Million IDR Rupiah',
      amount: 5000000,
    },
    RSVP: {
      text: 'RSVP payment must be more than 300 Thousands IDR Rupiah',
      amount: 300000,
    },
    PAYMENT_REPEAT: {
      text: 'payment repeat must be more than 1 Million IDR Rupiah',
      amount: 1000000,
    },
    DEPOSIT_ACCESS: {
      text: 'Deposit access must be more than 100 Thousands IDR Rupiah',
      amount: 100000,
    },
  };

  const handleNotification = (paymentStatus, amount) => {
    let isValid = true;
    console.log(numeralTextConverter(amount), errorChecker[paymentStatus]);
    if (amount && numeralTextConverter(amount) < errorChecker[paymentStatus].amount) {
      console.log('in');
      setNotif({
        showNotif: true,
        status: false,
        message: errorChecker[paymentStatus].text,
      });
      setModalPayment(false);
      isValid = false;
    }
    return isValid;
  };

  async function handleSubmit(e) {
    e.preventDefault();
    if (!newInvoiceData.paymentStatusName) {
      setNotif({
        showNotif: true,
        status: false,
        message: 'please select payment status',
      });
      setModalPayment(false);
      return false;
    }
    if (['STEP_1', 'STEP_2', 'RSVP', 'PAYMENT_REPEAT', 'DEPOSIT_ACCESS'].includes(newInvoiceData.paymentStatusName) && !newInvoiceData.customAmount) {
      setNotif({
        showNotif: true,
        status: false,
        message: 'please input amount',
      });
      setModalPayment(false);
      return false;
    }

    if (handleNotification(newInvoiceData.paymentStatusName, newInvoiceData.customAmount)) {
      const payload = {
        applicant_id: +id,
        payment_status_name: newInvoiceData.paymentStatusName,
      };
      console.log(payload);
      if (['STEP_1', 'STEP_2', 'RSVP', 'PAYMENT_REPEAT', 'DEPOSIT_ACCESS'].includes(payload.payment_status_name)) {
        if (newInvoiceData.customAmount) {
          payload.custom_amount = numeralTextConverter(newInvoiceData.customAmount);
        }
      } else if (['REGISTRATION_FORM'].includes(payload.payment_status_name)) {
        payload.custom_amount = 100000;
      }
      try {
        const response = await api.createNewInvoice(payload);
        if (response.status) {
          setNotif({
            showNotif: true,
            status: true,
            message: 'successfully request new invoice',
          });
          fetchInvoices();
        }
        setNewInvoiceData({ paymentStatusName: '', customAmount: 0 });
      } catch (error) {
        setNotif({
          showNotif: true,
          status: false,
          message: error.response.data.errors[0].detail,
        });
      } finally {
        setModalPayment(false);
      }
    }
    return null;
  }

  async function handleEditNotes(e) {
    e.preventDefault();
    const payload = {
      applicant_id: +id,
      notes: selectedNote.notes,
    };
    try {
      const response = await api.editInvoiceNote(selectedNote.id, payload);
      if (response.status) {
        setNotif({
          showNotif: true,
          status: true,
          message: 'successfully edit invoice notes',
        });
        fetchInvoices();
      }
      setSelectedNote({ id: null, notes: null });
    } catch (error) {
      setNotif({
        showNotif: true,
        status: false,
        message: error.response.data.errors[0].detail,
      });
    } finally {
      setModalNote(false);
    }
  }

  const {
    xeroInvoiceId, paymentStatus, billAmount, refundableAmount, paidAmount,
  } = billingData;

  const convertToLocaleString = (d = '') => (
    d.toLocaleString('en').replaceAll(',', '.')
  );

  useEffect(() => {
    if (isModalOpen || isNoteModalOpen) {
      setZIndex(-1);
    } else {
      setZIndex(1);
    }
  }, [isModalOpen, isNoteModalOpen]);

  return (
    <Layout
      containerStyles={styles.layoutContainerStyles(zIndex)}
      childrenContainerStyles={styles.childrenContainerStyles}
      navbarStyles={styles.navbarContainerStyles}
    >
      <div css={styles.buttonRowStyles}>
        <Button
          type="button"
          styles={styles.buttonStyles}
          onClick={() => setModalPayment(true)}
        >
          create new invoice
        </Button>
      </div>
      <div css={styles.notificationContainerStyles}>
        {
          notif.showNotif && (
            <div css={styles.notificationBannerStyles(notif.status)}>
              <span>{notif.message}</span>
            </div>
          )
        }
      </div>
      <div css={styles.tableDetailSectionStyles}>
        <table css={styles.tableDetailContainerStyles}>
          <thead css={styles.tableDetailHeaderStyles}>
            <tr>
              <th>Type</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Xero invoice id</td>
              <td>{xeroInvoiceId}</td>
            </tr>
            <tr>
              <td>Payment Status</td>
              <td>{paymentStatus}</td>
            </tr>
            <tr>
              <td>Billing amount</td>
              {/* <td>{`${bill_amount}`}</td> */}
              <td>{convertToLocaleString(billAmount)}</td>
              {/* <td>{`Rp ${billAmount.toLocaleString('en').replaceAll(',', ('.'))}` : ''}</td> */}
            </tr>
            <tr>
              <td>Refundable amount</td>
              <td>{convertToLocaleString(refundableAmount)}</td>
            </tr>
            <tr>
              <td>Paid amount</td>
              <td>{convertToLocaleString(paidAmount)}</td>
              {/* <td>{`Rp ${paidAmount.toLocaleString('en').replaceAll(',', ('.'))}`}</td> */}
            </tr>
          </tbody>
        </table>
      </div>
      <PaymentDetail
        isModalOpen={isModalOpen}
        setModal={setModalPayment}
        tableData={tableData}
        newInvoiceData={newInvoiceData}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
      />
      <Modal
        styles={styles.modalStyles}
        isModalOpen={isNoteModalOpen}
        setModal={setModalNote}
        closeModal={() => setModalNote(false)}
      >
        <form onSubmit={handleSubmit}>
          <div css={styles.dataContainerStyles}>
            <label>invoice note</label>
            <Input
              type="text"
              placeholder="invoice note"
              name="invoiceNote"
              styles={styles.inputStyles}
              onChange={(e) => setSelectedNote({ ...selectedNote, notes: e.target.value })}
              value={selectedNote.notes}
            />
          </div>
          <Button
            styles={styles.buttonSubmitStyles}
            type="submit"
            onClick={handleEditNotes}
          >
            edit notes
          </Button>
        </form>
      </Modal>
    </Layout>
  );
};

export default DetailPayment;
