import axiosInstance from 'helpers/axiosInstance';

export default {
  getAllBillings: (id) => axiosInstance().get(`billings/${id}/`),
  getAllInvoices: (id) => axiosInstance().get(`invoices/applicant/${id}`),
  getApplicantPersonalData: (id) => axiosInstance().get(`applicant/${id}`),
  getAllTestHistory: (id) => axiosInstance().get(`applicant-tests/log/${id}`),
  getAllHistoryCall: (id) => axiosInstance().get(`follow-up-logs/${id}`),
  getAllActivityLogs: (id, limit) => axiosInstance().get(`applicant-logs/${id}/?limit=${limit}`),
  getAllStudentJourneys: (id) => axiosInstance().get(`student-journeys/${id}`),
  createHistoryCall: (data) => axiosInstance().post('follow-up-logs', data),
  createNewInvoice: (data) => axiosInstance().post('invoices/xendit', data),
  createScheduledTest: (data) => axiosInstance().post('applicant-tests', data),
  editApplicantStatus: (id, data) => axiosInstance().put(`applicant/status-detail/${id}`, data),
  editPersonalData: (id, data) => axiosInstance().put(`applicant/profile/${id}`, data),
  editInvoiceNote: (id, data) => axiosInstance().put(`invoices/${id}/`, data),
};
