/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';

import Layout from 'components/layout';
import { Button } from 'components/common';
import HistoryCall from 'components/pages/Applicant/HistoryCall/HistoryCall';
import api from 'api/server/applicantDetails';
import * as styles from 'components/pages/Applicant/HistoryCall/HistoryCall.styles';

const CallHistory = () => {
  const { id } = useParams();
  const history = useHistory();
  const pic = Cookies.get('pic');
  const [notif, setNotif] = useState({
    showNotif: false,
    status: false,
    message: '',
  });
  const [isModalOpen, setModal] = useState(false);
  const [callList, setCallList] = useState([]);
  const [newCallData, setNewCallData] = useState({
    followUpStatusId: '',
    followUpBy: pic,
    notes: '',
  });

  async function fetchCallLists() {
    try {
      const { data } = await api.getAllHistoryCall(id);
      setCallList(data.data.results);
    } catch (error) {
      if (error.response.status === 401) {
        Cookies.remove('access_token');
        history.replace('/sign-in');
      }
    }
  }

  useEffect(() => {
    fetchCallLists();
  }, []);

  const tableData = callList.map((d) => ({
    key: d.id,
    call: d.call_attempt,
    follow_up_status: d.follow_up_status_id.value,
    follow_up_by: d.follow_up_by,
    contact_time: d.created_at,
    notes: d.notes,
  }));

  const handleChange = (e) => {
    setNewCallData({
      ...newCallData,
      [e.target.name]: e.target.value,
    });
  };

  async function handleSubmit(e) {
    e.preventDefault();
    let isValid = true;
    Object.keys(newCallData).forEach((key) => {
      if (!newCallData[key] && key !== 'notes') {
        isValid = false;
      }
    });
    if (!isValid) {
      setNotif({
        showNotif: true,
        status: false,
        message: 'please fill in all the required field',
      });
      setModal(false);
      return false;
    }
    const {
      followUpStatusId, followUpBy, notes,
    } = newCallData;

    const payload = {
      notes,
      applicant_id: +id,
      follow_up_status_id: +followUpStatusId,
      follow_up_by: followUpBy,
    };

    try {
      const response = await api.createHistoryCall(payload);
      if (response.status === 201) {
        setNotif({
          showNotif: true,
          status: true,
          message: 'successfully created history call',
        });
        fetchCallLists();
      }
      setNewCallData({
        applicant_id: id,
        followUpStatusId: '',
        followUpBy: pic,
        notes: '',
      });
    } catch (error) {
      setNotif({
        showNotif: true,
        status: false,
        message: error.response.data.errors[0].detail,
      });
    } finally {
      setModal(false);
    }
    return null;
  }

  return (
    <Layout
      containerStyles={styles.layoutContainerStyles(isModalOpen)}
      childrenContainerStyles={styles.childrenContainerStyles}
      navbarStyles={styles.navbarContainerStyles}
    >
      <div css={styles.buttonContainerStyles}>
        <Button
          type="button"
          styles={styles.buttonStyles}
          onClick={() => setModal(true)}
        >
          create new call history
        </Button>
      </div>
      <div css={styles.notificationContainerStyles}>
        {
          notif.showNotif && (
            <div css={styles.notificationBannerStyles(notif.status)}>
              <span>{notif.message}</span>
            </div>
          )
        }
      </div>
      <HistoryCall
        isModalOpen={isModalOpen}
        setModal={setModal}
        tableData={tableData}
        newCallData={newCallData}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
      />
    </Layout>
  );
};

export default CallHistory;
