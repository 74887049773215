import { css } from '@emotion/react';
// import color from 'components/common/color';

export const sidebarStyles = css`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 4rem);
  float: left;
  position: sticky;
  padding: 5px 0 10px;
  width: 15%;
  margin: 0 5px 0 0;
  border-right: 5px solid #ebebeb;
`;

export const logoStyles = css`
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
`;

export const sidebarContainerStyles = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 0;
  padding: 15px 0;
`;
