/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { css } from '@emotion/react';
import { useHistory } from 'react-router-dom';
import qs from 'query-string';
import { datePickerToStringFormat, handleDownloadTemplate } from 'helpers';
import api from 'api/server';
import Layout from 'components/layout';
import Sidebar from 'components/pages/PTP/Students/Sidebar/Sidebar';
import { Table, Input } from 'components/common';
import tableConfig from 'components/config/PTP/Student/tableConfig';
import { containerStyles, inputContainerStyles } from 'components/pages/FTP/Applicants/Applicant.styles';

const PART_TIME = 'PART_TIME';

const PtpStudents = () => {
  const history = useHistory();
  const queryString = history.location.search || '';
  const queryObject = qs.parse(queryString) || {};
  const [loading, setLoading] = useState(true);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [filterCondition, setFilterCondition] = useState({
    enrolledProgramCode: '',
    appliedBatch: '',
    enrolledBatch: '',
    paymentOption: '',
    paymentStatus: '',
    search: '',
  });
  const [dataStudents, setDataStudents] = useState([]);
  const [pagination, setPagination] = useState({});
  const [isCsvDownloading, setCsvDownloading] = useState(false);

  async function fetchData() {
    setLoading(true);
    try {
      const { data } = await api.getAllStudents({ ...queryObject, program_type: PART_TIME });
      const response = data.data;
      setDataStudents(response.results);
      setPagination({
        current: response.current_page,
        total: response.last_page,
        totalData: response.total,
      });
    } catch (error) {
      if (error.response.status === 401) {
        Cookies.remove('access_token');
        history.replace('/sign-in');
      }
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
    setFilterCondition({
      enrolledProgramCode: queryObject.enrolled_program_code,
      appliedBatch: queryObject.applied_batch ? { label: queryObject.applied_batch, value: queryObject.applied_batch } : '',
      enrolledBatch: queryObject.enrolled_batch ? { label: queryObject.enrolled_batch, value: queryObject.enrolled_batch } : '',
      paymentOption: queryObject.payment_option,
      paymentStatus: queryObject.payment_status,
      search: queryObject.search,
    });
    setDateRange(
      [queryObject.applied_start_date ? new Date(queryObject.applied_start_date) : null,
        queryObject.applied_end_date ? new Date(queryObject.applied_end_date) : null,
      ],
    );
  }, [queryString]);

  const applicantData = dataStudents.map((d) => ({
    key: d.applicant_id,
    page: 'ptp',
    subpage: 'personal-data',
    id: d.applicant_id,
    enrolled_batch: d.enrolled_batch,
    name: d.name,
    programCode: d.enrolled_program_code,
    program: 'ptp',
    status: d.student_status,
    payment_option: d.payment_method,
  }));

  const csvHeaders = [
    { label: 'Applicant ID', key: 'applicant_id' },
    { label: 'Name', key: 'name' },
    { label: 'Email', key: 'email' },
    { label: 'Program Code', key: 'enrolled_program_code' },
    { label: 'Enrolled Batch', key: 'enrolled_batch' },
    { label: 'Student Status', key: 'student_status' },
    { label: 'Payment Option', key: 'payment_method' },
  ];

  async function fetchDataCSV() {
    setCsvDownloading(true);
    try {
      const { data } = await
      api.getAllStudents({ ...queryObject, limit: 20000, program_type: PART_TIME });
      handleDownloadTemplate(data.data.results, csvHeaders, 'ptp_student_export');
    } catch (error) {
      console.log(error, 'error fetch data csv');
    } finally {
      setCsvDownloading(false);
    }
  }

  const handleChange = (e) => {
    setFilterCondition({
      ...filterCondition,
      [e.target.name]: e.target.value,
    });
  };

  const handleSelect2Change = (e, name) => {
    setFilterCondition({
      ...filterCondition,
      [name]: e,
    });
  };

  const handleResetFilter = () => {
    setFilterCondition({
      enrolledProgramCode: '',
      registeredBatch: '',
      enrolledBatch: '',
      paymentOption: '',
      paymentStatus: '',
      search: '',
    });
    setDateRange([null, null]);
    fetchData().then(() => {
      history.push({
        pathname: history.pathname,
      });
    });
  };

  const handleSubmitFilter = (e) => {
    e.preventDefault();
    const queryFilter = {
      enrolledProgramCode: filterCondition.enrolled_program_code,
      payment_option: filterCondition.paymentOption,
      payment_status: filterCondition.paymentStatus,
      search: filterCondition.search,
      applied_batch: filterCondition.appliedBatch.value,
      enrolled_batch: filterCondition.enrolledBatch.value,
      applied_start_date: dateRange[0] ? datePickerToStringFormat(JSON.stringify(dateRange[0])) : '',
      applied_end_date: dateRange[1] ? datePickerToStringFormat(JSON.stringify(dateRange[1])) : '',
      program_type: PART_TIME,
    };
    const queryParam = qs.stringify(queryFilter, { skipNull: true, skipEmptyString: true });
    history.push({
      pathname: history.pathname,
      search: queryParam,
    });
  };

  return (
    <Layout
      sidebar={(
        <Sidebar
          startDate={startDate}
          endDate={endDate}
          setDateRange={setDateRange}
          filterCondition={filterCondition}
          handleChange={handleChange}
          handleSelect2Change={handleSelect2Change}
          handleSubmit={handleSubmitFilter}
          handleReset={handleResetFilter}
          handleDownloadCSV={fetchDataCSV}
          isCsvDownloading={isCsvDownloading}
        />
      )}
    >
      <div css={containerStyles}>
        <span>
          total record:&nbsp;
          {pagination.totalData}
        </span>
        <form action="" css={css`width: 20%;`} onSubmit={handleSubmitFilter}>
          <Input
            containerStyles={inputContainerStyles}
            type="text"
            name="search"
            value={filterCondition.search}
            onChange={handleChange}
            placeholder="search by name / email"
          />
        </form>
      </div>
      {
        !loading && (
          <Table
            containerStyles={css`height: 80vh;`}
            tableConfig={tableConfig}
            history={history}
            pagination={pagination}
            setPagination={setPagination}
            data={applicantData}
          />
        )
      }
    </Layout>
  );
};
export default PtpStudents;
