/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Layout from 'components/layout';
import { CreateInvitationModal, EditInvitationModal } from 'components/pages/[id]/test-history';
import { Button, Table, Icon } from 'components/common';
import api from 'api/server';
import * as styles from 'components/pages/FTP/Applicants/TestHistory/TestHistory.styles';
import tableConfig from 'components/config/ApplicantDetails/TestHistory/tableConfig';

const TestHistory = () => {
  const { id } = useParams();
  const [notif, setNotif] = useState({
    showNotif: false,
    status: false,
    message: '',
  });
  const [isCreateModalOpen, setCreateModal] = useState(false);
  const [isEditModalOpen, setEditModal] = useState(false);
  const [formData, setFormData] = useState({
    applicant_id: +id,
    campus_location_id: 1,
    interviewed_by: '',
    scheduled_test_at: new Date(),
    test_type: 'ONLINE',
    is_online: true,
    online_interview_url: '',
  });
  const [testData, setTestData] = useState([]);
  const [selectedData, setSelectedData] = useState({});
  const [zIndex, setZIndex] = useState('');

  async function fetchTestApplicants() {
    const { data } = await api.getAllTestHistory(id);
    setTestData(data.data.results);
  }

  useEffect(() => {
    fetchTestApplicants();
  }, []);

  const handleSelectedDataChange = (e) => {
    setSelectedData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleDateChange = (date) => {
    setFormData((prevState) => ({
      ...prevState,
      scheduled_test_at: date,
    }));
  };

  const handleOpenEditModal = (d) => {
    setSelectedData({
      id: d.id,
      test_type: d.test_type,
      campus_location_id: d.campus_location.location,
      scheduled_test_at: d.scheduled_test_at,
      online_interview_url: d.online_interview_url,
      attendance_status: d.attendance_status,
      test_result: d.test_result,
      interviewed_by: d.interviewed_by,
      notes: d.notes,
      final_score: d.final_score,
    });
    setEditModal(true);
  };

  async function handleEditInvitation(e) {
    e.preventDefault();
    const appId = selectedData.id;
    const payload = {
      applicant_id: +id,
      campus_location_id: +selectedData.campus_location_id || 1,
      interviewed_by: selectedData.interviewed_by,
      scheduled_test_at: selectedData.scheduled_test_at,
      test_type: selectedData.test_type,
      is_online: true,
      online_interview_url: selectedData.online_interview_url,
      attendance_status: selectedData.attendance_status || 'show',
      final_score: +selectedData.final_score,
      test_result: selectedData.test_result,
      notes: selectedData.notes,
    };
    try {
      const { data } = await api.editSingleScheduleTest(appId, payload);
      if (data.status) {
        setNotif({
          showNotif: true,
          status: true,
          message: 'successfully updated scheduled test',
        });
        fetchTestApplicants(id);
      }
    } catch (error) {
      setNotif({
        showNotif: true,
        status: false,
        message: error.response.data.errors[0].detail,
      });
    } finally {
      setEditModal(false);
    }
  }

  const tableData = testData.map((d) => (
    {
      key: d.id,
      type: d.test_type,
      date: d.scheduled_test_at,
      link: d.online_interview_url,
      score: d.final_score,
      result: d.test_result,
      notes: d.notes,
      action: (
        <Button
          styles={styles.buttonStyles}
          type="button"
          onClick={() => handleOpenEditModal(d)}
        >
          <Icon name="chevron-right" />
        </Button>
      ),
    }
  ));

  async function handleCreateInvitation(e) {
    e.preventDefault();
    let time = JSON.stringify(formData.scheduled_test_at);
    time = time.split('.');
    const payload = {
      ...formData,
      campus_location_id: +formData.campus_location_id,
      final_score: +formData.final_score,
      scheduled_test_at: `${time[0].replace('"', '')}Z`,
      attendance_status: formData.attendance_status || null,
    };
    try {
      const { data } = await api.createScheduledTest(payload);
      if (data.status) {
        setFormData({
          applicant_id: +id,
          campus_location_id: 1,
          interviewed_by: '',
          scheduled_test_at: new Date(),
          test_type: 'ONLINE',
          is_online: true,
          online_interview_url: '',
          attendance_status: '',
          final_score: 0,
          test_result: '',
          notes: '',
        });
        setNotif({
          showNotif: true,
          status: true,
          message: 'Successfully created invitation',
        });
        fetchTestApplicants();
      }
    } catch (error) {
      console.log(error, 'error test history');
    } finally {
      setCreateModal(false);
    }
  }

  useEffect(() => {
    if (isCreateModalOpen || isEditModalOpen) {
      setZIndex(-1);
    } else {
      setZIndex(1);
    }
  }, [isCreateModalOpen, isEditModalOpen]);

  return (
    <Layout
      childrenContainerStyles={styles.childrenContainerStyles}
      containerStyles={styles.layoutContainerStyles(zIndex)}
      navbarStyles={styles.navbarContainerStyles}
    >
      <div css={styles.notificationContainerStyles}>
        {
          notif.showNotif && (
            <div css={styles.notificationBannerStyles(notif.status)}>
              <span>{notif.message}</span>
            </div>
          )
        }
      </div>
      {/* <div css={styles.buttonContainerStyles}>
        <Button
          type="button"
          styles={styles.createButtonStyles}
          onClick={() => setCreateModal(true)}
        >
          create new test invitation
        </Button>
      </div> */}
      {
        testData.length > 0 && (
          <Table
            containerStyles={styles.tableContainerStyles}
            dataContainerStyles={styles.tableDataContainerStyles}
            tableConfig={tableConfig}
            showPagination={false}
            data={tableData}
            isHoverable={false}
          />
        )
      }
      <CreateInvitationModal
        isCreateModalOpen={isCreateModalOpen}
        setCreateModal={setCreateModal}
        formData={formData}
        handleChange={handleChange}
        handleDateChange={handleDateChange}
        handleSubmit={handleCreateInvitation}
      />
      <EditInvitationModal
        isEditModalOpen={isEditModalOpen}
        setEditModal={setEditModal}
        selectedData={selectedData}
        handleChange={handleSelectedDataChange}
        handleSubmit={handleEditInvitation}
      />
    </Layout>
  );
};

export default TestHistory;
