import { css } from '@emotion/react';

import color from '../color';

export const inputContainerStyles = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &.lowercase {
    input[type=text] {
      text-transform: none;
    };
  };
`;

export const inputStyles = css`
  width: calc(100% - 12px);
  padding: 5px;
  border-radius: 5px;
  height: 40px;
  border: 1px solid black;
  text-transform: capitalize;

  &:hover, &:focus {
    border-color: ${color.ORANGE};
    opacity: 0.6;
  };
  
  &:disabled {
    background-color: ${color.GREY};
    cursor: not-allowed;
  };
`;

export const inputLabelStyles = css`
  text-transform: capitalize;
  font-size: 16px;
  margin: 0 0 5px;
`;
