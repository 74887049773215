import { css } from '@emotion/react';

export const modalStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    boxShadow: '0px 3px 30px #0000000d',
    width: '800px',
    overflow: 'hidden',
  },
};

export const formStyles = css`
  display: flex;
  flex-direction: column;
  padding: 10px;
`;

export const formRowContainerStyles = css`
  display: flex;
  flex-direction: column;
  margin: 0 0 12px;
`;

export const inputStyles = css`
  border-radius: 4px;
  height: 25px;
`;

export const selectStyles = css`
  height: 40px;

  option {
    text-transform: none;
  }
`;
