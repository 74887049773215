/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  fetchBatchOptions,
  fetchPaymentMethodOptions,
  fetchFollowUpStatusOptions,
  fetchPaymentStatusOptions,
  fetchApplicantStatusOptions,
} from 'helpers';
import Sidebar from 'components/layout/Sidebar/Sidebar';
import {
  CustomDatepicker as Datepicker,
  Select2,
  Select,
  Button,
  CreatableSelect,
} from 'components/common';
import testResultOptions from 'constants/testResultOptions';
import interviewers from 'constants/interviewers';

import * as styles from './Sidebar.styles';

const FTPSidebar = ({
  startDate,
  callDate,
  endDate,
  callEndDate,
  setDateRange,
  setCallRange,
  filterCondition,
  setFilterCondition,
  handleChange,
  handleSelect2Change,
  handleSubmit,
  handleReset,
  handleDownloadCSV,
  isCsvDownloading,
  alertSelect,
}) => {
  const [options, setOptions] = useState({
    batchOptions: [],
    paymentMethodOptions: [],
    paymentStatusOptions: [],
    followUpStatusOptions: [],
    applicantStatusOptions: [],
  });

  const getPaymentOptions = () => fetchPaymentMethodOptions().then((resp) => resp);

  const getPaymentStatusOptions = () => fetchPaymentStatusOptions().then((resp) => resp);

  const getBatchOptions = () => fetchBatchOptions(true).then((resp) => resp.map((d) => ({
    label: d.label,
    value: d.id,
  })));

  const getFollowUpStatusOptions = () => fetchFollowUpStatusOptions().then((resp) => resp);
  const getApplicantStatusOptions = () => fetchApplicantStatusOptions().then((resp) => resp);

  useEffect(async () => {
    const [
      paymentOptions,
      batchOptions,
      paymentStatusOptions,
      followUpStatusOptions,
      applicantStatusOptions,
    ] = await Promise.all([
      getPaymentOptions(),
      getBatchOptions(),
      getPaymentStatusOptions(),
      getFollowUpStatusOptions(),
      getApplicantStatusOptions(),
    ]);
    setOptions((prevState) => ({
      ...prevState,
      paymentOptions,
      batchOptions,
      paymentStatusOptions,
      followUpStatusOptions,
      applicantStatusOptions,
    }));
  }, []);

  return (
    <Sidebar>
      <div css={styles.filterConditionsContainerStyles}>
        <Datepicker
          containerStyles={styles.datepickerContainerStyles}
          startDate={startDate}
          endDate={endDate}
          setDateRange={setDateRange}
          placeholder="date range"
        />
        <Datepicker
          containerStyles={styles.datepickerContainerStyles}
          startDate={callDate}
          endDate={callEndDate}
          setDateRange={setCallRange}
          placeholder="call date range"
        />
        <Select2
          containerStyles={styles.select2ContainerStyles}
          defaultValue={{ label: 'batch', value: '' }}
          options={options.batchOptions}
          placeholder="batch"
          onChange={(e) => handleSelect2Change(e, 'lastBatch')}
          value={filterCondition.lastBatch}
        />
        <CreatableSelect
          filterCondition={filterCondition}
          setFilterCondition={setFilterCondition}
          containerStyles={styles.select2ContainerStyles}
          options={options.paymentOptions}
          placeholder="payment type"
          value={filterCondition.paymentType}
          alertSelect={alertSelect.paymentType}
          name="paymentType"
        />
        <CreatableSelect
          filterCondition={filterCondition}
          setFilterCondition={setFilterCondition}
          containerStyles={styles.select2ContainerStyles}
          options={options.followUpStatusOptions}
          placeholder="follow up status"
          value={filterCondition.followUpStatus}
          alertSelect={alertSelect.followUpStatus}
          name="followUpStatus"
        />
        <CreatableSelect
          filterCondition={filterCondition}
          setFilterCondition={setFilterCondition}
          containerStyles={styles.select2ContainerStyles}
          options={interviewers}
          placeholder="follow up by"
          value={filterCondition.followUpBy}
          alertSelect={alertSelect.followUpBy}
          name="followUpBy"
        />
        <Select
          containerStyles={styles.selectContainerStyles}
          name="isRegistrationPaid"
          options={[
            {
              label: 'yes',
              value: 'true',
            },
            {
              label: 'no',
              value: 'false',
            },
          ]}
          placeholder="is registration paid"
          value={filterCondition.isRegistrationPaid}
          onChange={handleChange}
        />
        <Select
          containerStyles={styles.selectContainerStyles}
          name="call"
          options={[
            {
              label: '1',
              value: '1',
            },
            {
              label: '2',
              value: '2',
            },
            {
              label: '3',
              value: '3',
            },
          ]}
          placeholder="call attempt"
          value={filterCondition.call}
          onChange={handleChange}
        />
        <CreatableSelect
          filterCondition={filterCondition}
          setFilterCondition={setFilterCondition}
          containerStyles={styles.select2ContainerStyles}
          options={testResultOptions}
          placeholder="test result"
          value={filterCondition.testResults}
          alertSelect={alertSelect.testResults}
          name="testResults"
        />
        {/* <CreatableSelect
          filterCondition={filterCondition}
          setFilterCondition={setFilterCondition}
          containerStyles={styles.select2ContainerStyles}
          defaultValue={{ label: 'applicant status', value: '' }}
          options={options.applicantStatusOptions}
          placeholder="applicant status"
          alertSelect={alertSelect.applicantStatuses}
          name="applicantStatuses"
          value={filterCondition.applicantStatuses}
        /> */}
      </div>
      <div css={styles.buttonContainerStyles}>
        <Button type="button" onClick={handleSubmit} styles={styles.buttonStyles}>apply</Button>
        <Button type="button" onClick={handleReset} className="reset" styles={styles.buttonStyles}>reset filter</Button>
        <Button
          disabled
          type="button"
          className="download"
          styles={styles.buttonStyles}
          onClick={handleDownloadCSV}
        >
          {isCsvDownloading ? 'Downloading CSV...' : 'Download CSV'}
        </Button>
      </div>
    </Sidebar>
  );
};

FTPSidebar.propTypes = {
  startDate: PropTypes.instanceOf(Date),
  callDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  callEndDate: PropTypes.instanceOf(Date),
  setDateRange: PropTypes.func.isRequired,
  setCallRange: PropTypes.func.isRequired,
  filterCondition: PropTypes.shape({
    lastBatch: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
    paymentStatus: PropTypes.string,
    paymentType: PropTypes.string,
    followUpStatus: PropTypes.string,
    attendanceStatus: PropTypes.string,
    isRegistrationPaid: PropTypes.string,
    applicantStatuses: PropTypes.arrayOf(PropTypes.shape({})),
    followUpBy: PropTypes.arrayOf(PropTypes.shape({})),
    testResults: PropTypes.arrayOf(PropTypes.shape({})),
    call: PropTypes.string,
  }).isRequired,
  setFilterCondition: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSelect2Change: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
  handleDownloadCSV: PropTypes.func.isRequired,
  isCsvDownloading: PropTypes.bool.isRequired,
  alertSelect: PropTypes.shape({
    paymentType: PropTypes.bool,
    followUpStatus: PropTypes.bool,
    testResults: PropTypes.bool,
    applicantStatuses: PropTypes.bool,
    followUpBy: PropTypes.bool,
  }).isRequired,
};

FTPSidebar.defaultProps = {
  startDate: null,
  endDate: null,
  callDate: null,
  callEndDate: null,
};

export default FTPSidebar;
