const testResultOptions = [
  {
    label: 'Recommended',
    value: 'RECOMMENDED',
  },
  {
    label: 'Not Recommended',
    value: 'no_recommended',
  },
  {
    label: 'Waiting List',
    value: 'waiting_list',
  },
];

export default testResultOptions;
