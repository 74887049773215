const Routes = {
  SIGNIN: '/sign-in',
  BATCH: '/batch',
  DASHBOARD: '/dashboard',
  APPLICANTS: '/ftp/applicants',
  BATCH_PROGRAMS: '/batch-programs',
  NEW_BATCH_PROGRAMS: '/batch-programs/new',
  BATCH_PROGRAMS_DETAIL: '/batch-programs/:batchId',
  PROGRAM_CODES: '/program-codes',
  NEW_PROGRAM_CODES: '/program-codes/new',
  PROGRAM_CODES_DETAIL: '/program-codes/:programCodeID',
  PROGRAM: '/programs',
  NEW_PROGRAM: '/programs/new',
  PROGRAM_DETAIL: '/programs/:programId',
  PROMO_CODES: '/promo-codes',
  NEW_PROMO_CODES: '/promo-codes/new',
  PROMO_CODE_DETAIL: '/promo-codes/:promoCodeId',
  ACCEPTED_CANDIDATE: '/ftp/accepted',
  SCHEDULED_TEST: '/ftp/scheduled',
  FTP_STUDENTS: '/ftp/students',
  PERSONAL_DATA: '/:id/personal-data',
  TEST_HISTORY: '/:id/test-history',
  HISTORY_CALL: '/:id/history-call',
  PAYMENT_DETAIL: '/:id/payment-detail',
  MOVE_PROGRAM: '/:id/move-batch',
  ACTIVITY_LOG: '/:id/activity-log',
  TEST_INFO: '/:scheduleId/test-info',
  PTP_APPLICANTS: '/ptp/applicants',
  PTP_CANDIDATE: '/ptp/confirmed-candidate',
  PTP_STUDENTS: '/ptp/students',
  HOME: '/',
};

export default Routes;
