/* eslint-disable */
import { Icon } from 'components/common';

const tableConfig = [
  {
    key: 'date',
    label: 'created date',
    ratio: 0.1,
    textAlign: 'left',
    component: (d) => (
      <span>
        {d}
      </span>
    ),
  },
  {
    key: 'programName',
    label: 'program name',
    ratio: 0.175,
    textAlign: 'left',
    component: (d) => <span>{d}</span>,
  },
  {
    key: 'name',
    label: 'batch number',
    ratio: 0.125,
    textAlign: 'left',
    component: (d) => d,
  },
  {
    key: 'openDate',
    label: 'open registration date',
    ratio: 0.125,
    textAlign: 'left',
    component: (d) => (
      <span>
        {d}
      </span>
    ),
  },
  {
    key: 'closedDate',
    label: 'closed registration date',
    ratio: 0.125,
    textAlign: 'left',
    component: (d) => (
      <span>
        {d}
      </span>
    ),
  },
  {
    key: 'classDate',
    label: 'class started date',
    ratio: 0.1,
    textAlign: 'left',
    component: (d) => (
      <span>
        {d}
      </span>
    ),
  },
  {
    key: 'normalPrice',
    label: 'normal price',
    ratio: 0.1,
    textAlign: 'left',
    component: (d) => d
  },
  {
    key: 'status',
    label: 'status',
    ratio: 0.1,
    textAlign: 'left',
    component: (d) => d
  },
  {
    key: 'graduationDate',
    label: 'graduation date',
    ratio: 0.1,
    textAlign: 'left',
    component: (d) => (
      <span>
        {d}
      </span>
    ),
  },
  {
    key: 'earlyBirdPrice',
    label: 'early birds price',
    ratio: 0.1,
    textAlign: 'left',
    component: (d) => (
      <span>
        {d}
      </span>
    )
  },
];

export default tableConfig;
