/* eslint-disable */
import { calendarFormat } from 'helpers';

const tableConfig = [
  {
    key: 'call',
    label: 'call attempt',
    ratio: 0.1,
    textAlign: 'left',
    component: (d) => (
      <span style={{ marginLeft: '10px' }}>
        {d}
      </span>
    ),
  },
  {
    key: 'follow_up_status',
    label: 'follow up status',
    ratio: 0.15,
    textAlign: 'left',
    component: (d) => (
      <span style={{ marginLeft: '10px' }}>
        {d ? d.replace('_', ' ') : ''}
      </span>
    ),
  },
  {
    key: 'follow_up_by',
    label: 'follow up by',
    ratio: 0.15,
    textAlign: 'center',
    component: (d) => (
      <span style={{ marginLeft: '10px', textTransform: 'lowercase' }}>
        {d}
      </span>
    ),
  },
  {
    key: 'contact_time',
    label: 'contact time',
    ratio: 0.4,
    textAlign: 'left',
    component: (d) => (
      <span style={{ }}>
        {calendarFormat(d)}
      </span>
    ),
  },
  {
    key: 'notes',
    label: 'notes',
    ratio: 0.2,
    textAlign: 'center',
    component: (d) => (
      <span style={{ marginLeft: '10px' }}>
        {d}
      </span>
    ),
  },
];

export default tableConfig;
