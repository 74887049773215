import { css } from '@emotion/react';

import color from 'components/common/color';

export const layoutContainerStyles = css`
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const childrenContainerStyles = css`
  border: none;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;

export const navbarContainerStyles = css`
  width: calc(90% - 40px);
  padding: 0 10px;
  border: none;
`;

export const containerStyles = css`
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: 1px solid ${color.BLACK};
  margin: 35px auto 0;
  border-radius: 5px;
  box-shadow: 0px 0px 11px 0px rgba(0,0,0,0.5);
  -webkit-box-shadow: 1px 0px 2px 0px rgba(0,0,0,0.5);
  width: 30%;
`;

export const notificationContainerStyles = css`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const notificationBannerStyles = (status) => css`
  background: ${status ? '#39A388' : '#FCD2D1'};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  padding: 8px;
  margin: 20px 0;

  span {
    font-weight: 600;
    
    &:first-letter {
      text-transform: uppercase;
    }
  }
`;

export const tableContainerStyles = css`
  margin: 20px auto 0;
`;

export const rowContainerStyles = css`
  display: flex;
  flex-direction: column;
  margin: 5px 0 10px;
  width: 100%;

  &.current-container {
    label {
      font-size: 20px;
    }
  };

  label {
    font-size: 16px;
    font-weight: 500;
    &::first-letter {
      text-transform: uppercase;
    }
  }
`;

export const inputContainerStyles = css`
  
  input {
    width: calc(100% - 20px);
    height: 30px;
    padding: 5px 10px;

    &:hover {
      border-color: black;
    }
  }
`;

export const textareaStyles = css`
  resize: none;
  margin: 5px 0 0;
  border: 1px solid ${color.GREY};
  border-radius: 5px;
  padding: 10px 10px 5px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: "Roboto", sans-serif;

  &:hover {
    border-color: ${color.ORANGE};
  }
`;

export const radioContainerStyles = css`
  display: flex;
  justify-content: space-between;
  width: calc(80% - 40px);
`;

export const radioOptionsStyles = css`
  display: flex;

  label {
    margin: 0 5px 0 0;
  }
`;
