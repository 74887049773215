/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';
import { calendarFormat } from 'helpers';
import api from 'api/server';
import Layout from 'components/layout';
import { Button, Input } from 'components/common';
import * as styles from 'components/pages/Applicant/PersonalData/PersonalData.styles';

const PersonalData = () => {
  const { id } = useParams();
  const history = useHistory();
  const [banner, setBanner] = useState({
    show: false,
    status: true,
    message: '',
  });
  const [personalData, setPersonalData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    gender: '',
    date_of_birth: '',
    last_education: '',
    major: '',
    address: '',
    postal_code: '',
    city: '',
    motivation: '',
    activity: '',
    emergency_name: '',
    emergency_phone_number: '',
    expectation: '',
    place_of_birth: '',
    ref_code: '',
    reference: '',
    last_academic_institution_name: '',
  });

  async function fetchPersonalData() {
    try {
      const { data } = await api.getApplicantPersonalData(id);
      // console.log(data.data, 43);
      const response = data.data;
      Object.keys(personalData).forEach((key) => {
        setPersonalData((prevState) => ({
          ...prevState,
          [key]: response[key],
        }));
      });
    } catch (error) {
      if (error.response.status === 401) {
        Cookies.remove('access_token');
        history.replace('/sign-in');
      }
    }
  }

  useEffect(() => {
    fetchPersonalData();
  }, []);

  const handleChange = (e) => {
    setPersonalData({
      ...personalData,
      [e.target.name]: e.target.value,
    });
  };

  async function handleEditData(e) {
    e.preventDefault();
    try {
      const response = await api.editPersonalData(id, {
        ...personalData,
      });
      if (response.status) {
        setBanner({
          show: true,
          status: true,
          message: 'personal data has been updated',
        });
      }
    } catch (error) {
      setBanner({
        show: true,
        status: false,
        message: error.response.data.errors[0].detail,
      });
    }
  }

  return (
    <Layout
      childrenContainerStyles={styles.childrenContainerStyles}
      containerStyles={styles.layoutContainerStyles}
      navbarStyles={styles.navbarContainerStyles}
    >
      {
        banner.show && (
          <div css={styles.notificationBannerStyles(banner.status)}>
            <span>{banner.message}</span>
          </div>
        )
      }
      <div css={styles.formContainerStyles}>
        <div css={styles.rowContainerStyles}>
          <div css={styles.columnContainerStyles}>
            <Input
              containerStyles={styles.inputContainerStyles}
              label="first name"
              type="text"
              value={personalData.first_name}
              name="first_name"
              onChange={handleChange}
            />
          </div>
          <div css={styles.columnContainerStyles}>
            <Input
              containerStyles={styles.inputContainerStyles}
              label="last name"
              type="text"
              value={personalData.last_name}
              name="last_name"
              onChange={handleChange}
            />
          </div>
        </div>
        <div css={styles.rowContainerStyles}>
          <div css={styles.columnContainerStyles}>
            <Input
              label="email"
              className="lowercase"
              containerStyles={styles.inputContainerStyles}
              type="text"
              value={personalData.email}
              name="email"
              onChange={handleChange}
            />
          </div>
          <div css={styles.columnContainerStyles}>
            <Input
              label="phone"
              containerStyles={styles.inputContainerStyles}
              type="text"
              value={personalData.phone_number}
              name="phone_number"
              onChange={handleChange}
            />
          </div>
        </div>
        <div css={styles.rowContainerStyles}>
          <div css={styles.columnContainerStyles} className="value-only-container">
            <label>Gender</label>
            <span>{personalData.gender}</span>
          </div>
          <div css={styles.columnContainerStyles} className="value-only-container">
            <label>date of birth</label>
            <span>{calendarFormat(personalData.date_of_birth)}</span>
          </div>
        </div>
        <div css={styles.rowContainerStyles}>
          <div css={styles.columnContainerStyles} className="value-only-container">
            <label>last education</label>
            <span>{personalData.last_education}</span>
          </div>
          <div css={styles.columnContainerStyles}>
            <label>reference</label>
            <span>{personalData.reference}</span>
          </div>
        </div>
        <div css={styles.rowContainerStyles}>
          <div css={styles.columnContainerStyles}>
            <Input
              containerStyles={styles.inputContainerStyles}
              label="address"
              type="text"
              value={personalData.address}
              name="address"
              onChange={handleChange}
            />
          </div>
          <div css={styles.columnContainerStyles}>
            <Input
              containerStyles={styles.inputContainerStyles}
              label="postal code"
              type="text"
              value={personalData.postal_code}
              name="postal_code"
              onChange={handleChange}
            />
          </div>
        </div>
        <div css={styles.rowContainerStyles}>
          <div css={styles.columnContainerStyles}>
            <Input
              containerStyles={styles.inputContainerStyles}
              label="emergency name"
              type="text"
              value={personalData.emergency_name}
              name="emergency_name"
              onChange={handleChange}
            />
          </div>
          <div css={styles.columnContainerStyles}>
            <Input
              containerStyles={styles.inputContainerStyles}
              label="emergency contact"
              type="text"
              value={personalData.emergency_phone_number}
              name="emergency_phone_number"
              onChange={handleChange}
            />
          </div>
        </div>
        <div css={styles.rowContainerStyles}>
          <Input
            containerStyles={styles.inputContainerStyles}
            label="city"
            type="text"
            value={personalData.city}
            name="city"
            onChange={handleChange}
          />
        </div>
        <div css={styles.rowContainerStyles}>
          <Input
            containerStyles={styles.inputContainerStyles}
            label="motivasi join hacktiv8"
            type="text"
            value={personalData.motivation}
            name="motivation"
            onChange={handleChange}
          />
        </div>
        <div css={styles.rowContainerStyles}>
          <Input
            containerStyles={styles.inputContainerStyles}
            label="daily activity"
            type="text"
            value={personalData.activity}
            name="activity"
            onChange={handleChange}
          />
        </div>
        <Button
          type="button"
          onClick={handleEditData}
        >
          Submit
        </Button>
        {/* </div> */}
      </div>
    </Layout>
  );
};

export default PersonalData;
