import { Switch, BrowserRouter } from 'react-router-dom';

import ProtectedRoute from './ProtectedRoute';
import ROUTES from './Router.routes';
import {
  dashboard as Dashboard,
  batch as Batch,

  home as Home,

  // FTP
  ftpApplicants as FTPApplicants,
  ftpAccepted as FTPAccepted,
  ftpScheduled as FTPScheduled,
  ftpStudents as FTPStudents,
  ptpApplicants as PTPApplicants,
  ptpStudents as PTPStudents,
  personalData as PersonalData,
  testHistory as TestHistory,
  historyCall as HistoryCall,
  activityLog as ActivityLog,
  moveProgram as MoveProgram,
  paymentDetail as PaymentDetail,
  signin as Signin,
  testInfo as TestInfo,

  // ptp
  // ptpApplicants,
  // ptpStudents,
} from '../pages';

const Router = () => (
  <BrowserRouter>
    <Switch>
      <ProtectedRoute
        exact
        path={ROUTES.HOME}
        component={Home}
      />
      <ProtectedRoute
        exact
        path={ROUTES.DASHBOARD}
        component={Dashboard}
      />
      <ProtectedRoute
        exact
        path={ROUTES.BATCH}
        component={Batch}
      />
      <ProtectedRoute
        exact
        path={ROUTES.APPLICANTS}
        component={FTPApplicants}
      />
      <ProtectedRoute
        exact
        path={ROUTES.ACCEPTED_CANDIDATE}
        component={FTPAccepted}
      />
      <ProtectedRoute
        exact
        path={ROUTES.SCHEDULED_TEST}
        component={FTPScheduled}
      />
      <ProtectedRoute
        exact
        path={ROUTES.FTP_STUDENTS}
        component={FTPStudents}
      />
      <ProtectedRoute
        exact
        path={ROUTES.PTP_APPLICANTS}
        component={PTPApplicants}
      />
      <ProtectedRoute
        exact
        path={ROUTES.PTP_STUDENTS}
        component={PTPStudents}
      />
      <ProtectedRoute
        exact
        path={ROUTES.PERSONAL_DATA}
        component={PersonalData}
      />
      <ProtectedRoute
        exact
        path={ROUTES.TEST_HISTORY}
        component={TestHistory}
      />
      <ProtectedRoute
        exact
        path={ROUTES.HISTORY_CALL}
        component={HistoryCall}
      />
      <ProtectedRoute
        exact
        path={ROUTES.ACTIVITY_LOG}
        component={ActivityLog}
      />
      <ProtectedRoute
        exact
        path={ROUTES.MOVE_PROGRAM}
        component={MoveProgram}
      />
      <ProtectedRoute
        exact
        path={ROUTES.PAYMENT_DETAIL}
        component={PaymentDetail}
      />
      <ProtectedRoute
        exact
        path={ROUTES.TEST_INFO}
        component={TestInfo}
      />
      <ProtectedRoute
        exact
        path={ROUTES.SIGNIN}
        component={Signin}
      />
    </Switch>
  </BrowserRouter>
);

export default Router;
