import { css } from '@emotion/react';

import color from 'components/common/color';

export const buttonStyles = css`
  color: ${color.ORANGE};
  font-size: 18px;

  &:hover {
    color: ${color.GREY};
    font-weight: 600;
  }

  background-color: ${color.TRANSPARENT};
`;

export const containerStyles = css`
  display: flex;
  align-items: center;
  padding: 0 15px;
  justify-content: space-between;

  span {
    &::first-letter {
      text-transform: capitalize;
    }
  }

`;

export const inputContainerStyles = css`
  height: 40px;
  width: 100%;

  input {
    height: 30px;
    width: 100%;
  }
`;
