import { css } from '@emotion/react';

import color from '../color';

const SelectContainerStyles = css`
  height: 40px;
  width: 100%;
  font-size: 12px;
  display: flex;
  justify-content: center;
  position: relative;

  .select2__control {
    width: 100%;
    border: none;

    &:hover, &:focus {
      cursor: pointer;
      border-color: ${color.ORANGE};
    };

    .select2__value-container {
      height: 100%;
      padding: 8px;

      .select2__single-value {
        text-transform: capitalize;
      }
    };

    .select2__indicator {
      padding: 5px;

      svg {
        color: ${color.BLACK};
        height: 17px;
        width: 17px;
      };
    };

    .select2__placeholder {
      text-transform: capitalize;
      font-size: 12px;
      color: ${color.BLACK};
    };
  };

  .select2__menu {
    position: absolute;
    right: -200px;
    top: 0;
  }
`;

export default SelectContainerStyles;
