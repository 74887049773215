import axios from 'axios';

const signIn = (data) => (
  axios({
    method: 'POST',
    url: `${process.env.REACT_APP_USER_SERVICE_URL}/auth/login`,
    data,
  })
);

export default signIn;
