/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { css } from '@emotion/react';
import { useHistory } from 'react-router-dom';
import qs from 'query-string';
import { datePickerToStringFormat, handleDownloadTemplate } from 'helpers';
import Layout from 'components/layout';
import api from 'api/server';
import Sidebar from 'components/pages/FTP/Scheduled/Sidebar/Sidebar';
import CreateInterviewModal from 'components/pages/FTP/Scheduled/CreateInterviewModal/CreateInterviewModal';
import { Table, Input, Button } from 'components/common';
import tableConfig from 'components/config/FTP/ScheduledTest/tableConfig';
import { containerStyles, inputContainerStyles } from 'components/pages/FTP/Scheduled/Scheduled.styles';

const Scheduled = () => {
  const history = useHistory();
  const queryString = history.location.search;
  const queryObject = qs.parse(queryString) || {};
  const [isModalOpen, setModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [filterCondition, setFilterCondition] = useState({
    program: '',
    testResult: '',
    applicantStatus: '',
    attendanceStatus: '',
    search: '',
  });
  const [dataScheduled, setDataScheduled] = useState([]);
  const [pagination, setPagination] = useState({});
  const [isCsvDownloading, setCsvDownloading] = useState(false);

  const csvHeaders = [
    { label: 'Applicant ID', key: 'applicant_id' },
    { label: 'Name', key: 'name' },
    { label: 'Test Date', key: 'scheduled_test_at' },
    { label: 'Test Type', key: 'test_type' },
    { label: 'Test Result', key: 'test_result' },
    { label: 'Attendance', key: 'attendance_status' },
    { label: 'Applicant Status', key: 'applicant_status' },
    { label: 'Interviewer', key: 'interviewed_by' },
  ];

  async function fetchData() {
    const { data } = await api.getAllScheduled(queryObject);
    setDataScheduled(data.data.results);
    setPagination({
      current: data.data.current_page,
      total: data.data.last_page,
      totalData: data.data.total,
    });
    setLoading(false);
  }

  async function fetchDataCSV() {
    setCsvDownloading(true);
    try {
      const { data } = await api.getAllScheduled({ ...queryObject, limit: 20000 });
      handleDownloadTemplate(data.data.results, csvHeaders, 'scheduled_export');
    } catch (error) {
      console.log(error, 'error fetch csv scheduled');
    } finally {
      setCsvDownloading(false);
    }
  }

  useEffect(() => {
    fetchData();
    setFilterCondition(() => ({
      program: queryObject.program,
      testResult: queryObject.test_result,
      attendanceStatus: queryObject.attendance_status,
      applicantStatus: queryObject.applicant_status ? { label: queryObject.applicant_status, value: queryObject.applicant_status } : '',
      search: queryObject.search,
    }));
    setDateRange(
      [queryObject.schedule_test_start_date ? new Date(queryObject.schedule_test_start_date) : null,
        queryObject.schedule_test_end_date ? new Date(queryObject.schedule_test_end_date) : null,
      ],
    );
  }, [queryString]);

  const applicantData = dataScheduled.map((d) => ({
    key: d.id,
    id: d.id,
    subpage: 'test-info',
    date: dayjs(d.scheduled_test_at).subtract(7, 'hour').format('DD MMM YYYY on H:mm'),
    type: d.test_type,
    location: d.campus_location?.code,
    link: d.online_interview_url,
    totalInvite: d.aggregated_invitees,
    interviewer: d.interviewed_by,
  }));

  const handleChange = (e) => {
    setFilterCondition({
      ...filterCondition,
      [e.target.name]: e.target.value,
    });
  };

  const handleSelect2Change = (e, name) => {
    setFilterCondition({
      ...filterCondition,
      [name]: e,
    });
  };

  const handleResetFilter = () => {
    setFilterCondition({
      program: '',
      registeredBatch: '',
      testResult: '',
      attendanceStatus: '',
      applicantStatus: '',
      search: '',
      is_full_time: true,
    });
    setDateRange([null, null]);
    fetchData().then(() => {
      history.push({
        pathname: history.pathname,
        search: '',
      });
    });
  };

  const handleSubmitFilter = (e) => {
    e.preventDefault();
    const queryFilter = {
      schedule_test_start_date: dateRange[0] ? datePickerToStringFormat(JSON.stringify(dateRange[0])) : '',
      schedule_test_end_date: dateRange[1] ? datePickerToStringFormat(JSON.stringify(dateRange[1])) : '',
      attendance_status: filterCondition.attendanceStatus,
      test_result: filterCondition.testResult,
      applicant_status: filterCondition.applicantStatus?.value,
      search: filterCondition.search,
      is_full_time: true,
    };
    setDateRange(
      [queryFilter.schedule_test_start_date ? new Date(queryFilter.schedule_test_start_date) : null,
        queryFilter.schedule_test_end_date ? new Date(queryFilter.schedule_test_end_date) : null,
      ],
    );
    const queryParam = qs.stringify(queryFilter, { skipNull: true, skipEmptyString: true });
    history.push({
      pathname: history.pathname,
      search: queryParam,
    });
  };

  return (
    <Layout
      containerStyles={isModalOpen ? 'z-index: -1;' : ''}
      sidebar={(
        <Sidebar
          startDate={startDate}
          endDate={endDate}
          setDateRange={setDateRange}
          filterCondition={filterCondition}
          handleChange={handleChange}
          handleSelect2Change={handleSelect2Change}
          handleSubmit={handleSubmitFilter}
          handleReset={handleResetFilter}
          handleDownloadCSV={fetchDataCSV}
          isCsvDownloading={isCsvDownloading}
        />
      )}
    >
      <div css={containerStyles}>
        <span>
          total record: &nbsp;
          {pagination.totalData}
        </span>
        <form onSubmit={handleSubmitFilter} css={css`width: 20%;`}>
          <Input
            containerStyles={inputContainerStyles}
            type="text"
            name="search"
            value={filterCondition.search}
            onChange={handleChange}
            placeholder="search by name / email"
          />
        </form>
      </div>
      <Button
        type="button"
        onClick={() => setModal(true)}
        styles={css`width: fit-content`}
      >
        create new scheduled test
      </Button>
      {
        !loading
        && (
        <Table
          containerStyles={css`height: 80vh;`}
          tableConfig={tableConfig}
          history={history}
          pagination={pagination}
          setPagination={setPagination}
          data={applicantData}
        />
        )
      }
      <CreateInterviewModal
        isCreateModalOpen={isModalOpen}
        setCreateModal={setModal}
        fetchData={fetchData}
      />
    </Layout>
  );
};
export default Scheduled;
