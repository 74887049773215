import { css } from '@emotion/react';

import color from 'components/common/color';

export const filterConditionsContainerStyles = css`
  margin-bottom: 35px;
  width: calc(100% - 40px);
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const datepickerContainerStyles = css`
  margin-bottom: 20px;
  width: 100%;
`;

export const select2ContainerStyles = css`
  margin-bottom: 20px;
  width: 100%;
`;

export const labelStyles = css`
  margin: 0;
`;

export const selectContainerStyles = css`
  width: 100%;
  display: flex;
  margin-bottom: 20px;
`;

export const buttonContainerStyles = css`
  width: calc(100% - 40px);
  padding: 0 15px;
`;

export const buttonStyles = css`
  &.reset {
    margin: 10px 0;
    background-color: #D83A56;
  };

  &.download {
    color: ${color.BLUE};
    background: ${color.GREY};
  };
`;
