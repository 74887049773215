import { css } from '@emotion/react';

import color from 'components/common/color';

export const tableHeaderContainerStyles = css`
  width: 2500px;
`;

export const tableBodyStyles = css`
  width: 2500px;
`;

export const buttonStyles = css`
  width: 30%;
  align-items: center;
  justify-content: center;
  display: flex;
  color: ${color.ORANGE};
  font-size: 18px;
  background-color: ${color.TRANSPARENT};

  &:hover {
    color: ${color.GREY};
    font-weight: 600;
  }
`;

export const containerStyles = css`
  display: flex;
  align-items: center;
  padding: 0 50px 0 20px;
  justify-content: space-between;

  span {
    font-size: 18px;
    &::first-letter {
      text-transform: capitalize;
    }
  }
`;

export const inputContainerStyles = css`
  height: 40px;
  width: 100%;

  input {
    height: 30px;
    width: 100%;
    text-transform: none;
  }
`;

export const buttonModalStyles = css`
  margin: 40px 0 0;
`;
