import { css } from '@emotion/react';

export const layoutContainerStyles = css`
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  `;

export const childrenContainerStyles = css`
  border: none;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const navbarContainerStyles = css`
  width: calc(90% - 40px);
  padding: 0 10px;
  border: none;
`;

export const formContainerStyles = css`
  margin: 20px 0 0;
  width: 40%;
  border-radius: 5px;
  border: 1px solid black;
  box-shadow: 11px 11px 11px 0px rgba(0,0,0,0.25);
  -webkit-box-shadow: 0px 0px 11px 0px rgba(0,0,0,0.25);
  padding: 10px 25px 20px;
`;

export const notificationBannerStyles = (stat) => css`
  width: 40%;
  border-radius: 4px;
  padding: 20px;
  background: ${stat ? '#D4ECDD' : '#FF7777'};

  span {
    text-transform: capitalize;
    font-size: 18px;
  }
`;

export const rowContainerStyles = css`
  display: flex;
  width: 100%;
  padding: 5px 0 10px;

  &:last-of-type {
    margin: 0 0 20px;
  }
`;

export const columnContainerStyles = css`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0 5px;

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }

  &.value-only-container {
    font-size: 16px;
    padding: 0 5px 8px;
  }

  label {
    text-transform: capitalize;
    font-weight: 600;
  }
`;

export const buttonContainerStyles = css`
  display: flex;
  justify-content: flex-end;
  margin: 20px 0 0 0;

  button {
    padding: 5px 10px;
    align-items: center;
    width: 10%;
  }
`;

export const inputContainerStyles = css`
  width: 100%;
  display: flex;
  align-items: flex-start;

  input {
    height: 20px;
    border-radius: 4px;
  }
`;
