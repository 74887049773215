/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';
import Layout from 'components/layout';
import { Button } from 'components/common';
import api from 'api/server';
import * as styles from 'components/pages/Applicant/ActivityLog/ActivityLog.styles';
import LogActivityCard from 'components/pages/Applicant/ActivityLog/ActivityLog';

const ActivityLog = () => {
  const { id } = useParams();
  const history = useHistory();
  const [logActivities, setLogActivities] = useState([]);
  const [limit, setLimit] = useState(5);

  async function fetchLogActivities() {
    try {
      const { data } = await api.getAllActivityLogs(id, limit);
      setLogActivities(data.data.results);
    } catch (error) {
      if (error.response.status === 401) {
        Cookies.remove('access_token');
        history.replace('/sign-in');
      }
    }
  }

  useEffect(() => {
    fetchLogActivities();
  }, [limit]);

  const loadMore = () => {
    setLimit(limit + 5);
  };

  return (
    <Layout
      containerStyles={styles.containerStyles}
      childrenContainerStyles={styles.childrenContainerStyles}
      navbarStyles={styles.navbarContainerStyles}
    >
      <div css={styles.activitiesContainerStyles}>
        <LogActivityCard
          data={logActivities}
        />
      </div>
      <Button
        type="button"
        onClick={loadMore}
        styles={styles.loadMoreButtonStyles}
      >
        load more
      </Button>
    </Layout>
  );
};

export default ActivityLog;
