const arrayToStringParser = (arr) => {
  const result = [];
  if (arr) {
    if (arr.length) {
      arr.forEach((d) => {
        result.push(d.value);
      });
    }
  }
  return result;
};

export default arrayToStringParser;
