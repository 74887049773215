import jwt from 'jsonwebtoken';

import Layout from '../../components/layout';
import {
  layoutContainerStyles,
  childrenContainerStyles,
  navbarContainerStyles,
  videoContainerStyles,
} from '../../components/pages/dashboard/dashboard.styles';

const Dashboard = () => {
  const metabaseSecretKey = process.env.REACT_APP_METABASE_SECRET_KEY;
  const metabaseSiteUrl = process.env.REACT_APP_METABASE_SITE_URL;

  const payload = {
    resource: { dashboard: 64 },
    params: {},
    exp: Math.round(Date.now() / 1000) + (10 * 60), // 10 minute expiration
  };
  const token = jwt.sign(payload, metabaseSecretKey);

  const iframeUrl = `${metabaseSiteUrl}/embed/dashboard/${token}#bordered=true&titled=true`;

  return (
    <Layout
      childrenContainerStyles={childrenContainerStyles}
      containerStyles={layoutContainerStyles}
      navbarStyles={navbarContainerStyles}
    >
      <div css={videoContainerStyles}>
        <iframe
          title="Dashboard"
          src={iframeUrl}
          frameBorder={0}
          width={1600}
          height={980}
          allowTransparency
        />
      </div>
    </Layout>
  );
};

export default Dashboard;
