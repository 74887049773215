import { css } from '@emotion/react';
import Color from 'components/common/color';

export const layoutContainerStyles = css`
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const childrenContainerStyles = css`
  width: 100%;
  margin: 0 auto 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: none;
`;

export const buttonContainerStyles = css`
  width: 90%;
  display: flex;
  justify-content: flex-end;
  margin: 40px 0 0;

  button {
    width: fit-content;
  }
`;

export const createButtonStyles = css`
  width: fit-content;
`;

export const navbarContainerStyles = css`
  width: calc(90% - 40px);
  padding: 0 10px;
  border: none;
`;

export const rowContainerStyles = css`
  display: flex;
  width: 90%;
  padding: 5px 0 10px;
`;

export const labelValueContainerStyles = css`
  display: flex;
  flex-direction: column;
  width: 25%;
  margin: 0 10px;
  padding: 5px;

  // &.large-container {
  //   width: 50%;
  //   margin: 0 40px 0 10px;
  // }

  label, span {
    text-transform: capitalize;
    padding: 5px 5px 5px 0;
  }

  label {
    font-weight: 600;
    margin: 0 0 5px;
  }

`;

export const buttonRowStyles = css`
  display: flex;
  width: calc(90% - 40px);
  justify-content: flex-end;
  margin-top: 10px;

  button {
    width: fit-content;
  }
`;

export const notificationContainerStyles = css`
  width: 90%;
  display: flex;
  justify-content: center;
`;

export const notificationBannerStyles = (status) => css`
  background: ${status ? '#39A388' : '#FCD2D1'};
  width: 100%;
  display: flex;
  align-items: center;
  height: 30px;
  padding: 8px;
  margin: 20px 0;

  span {
    font-weight: 600;
    
    &:first-letter {
      text-transform: uppercase;
    }
  }
`;

export const tableTestInfoStyles = css`
  width: 90%;
  margin: 40px 0 50px;
  border: 1px solid ${Color.BLACK};
  border-collapse: collapse;

  th, td {
    border: 1px solid black;
    border-collapse: collapse;
    padding: 5px;
  }
  
  th {
    text-align: left;
    text-transform: capitalize;

    &:nth-of-type(1) {
      text-transform: none;
    }

    &:nth-of-type(4) {
      width: 30%;
    }
  }
`;

export const tableTestInviteContainerStyles = css`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 90%;

  span {
    text-transform: capitalize;
    font-weight: bold;
    margin: 0 0 10px;
    font-size: 18px;
  }
`;

export const tableInviteStyles = css`
  width: 100%;
  border: 1px solid ${Color.BLACK};
  border-collapse: collapse;

  th, td {
    border: 1px solid black;
    border-collapse: collapse;
    padding: 5px;
  }

  th {
    text-align: left;
    text-transform: capitalize;
  }

  select {
    border: none;
    width: 100%;
    font-size: 16px;
    padding: 5px;
  }

  td {
    &.score {
      border: none;
      display: flex;
      justify-content: center;

      input {
        width: 50px;
        text-align: right;
      }
    }
  }

  input {
    padding: 5px;

    &:last-of-type {
      width: calc(100% - 15px);
    }
  }

`;
