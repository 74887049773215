import { css } from '@emotion/react';

import color from '../color';

export const datepickerContainerStyles = css`
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;

  .react-datepicker__month-container {
    // right: -450px;
    // top: -60px;
  }
  .react-datepicker__tab-loop {
    .react-datepicker-popper {
      transform: translate3d(200px, 15px, 0px)!important;
    }
  }
  
  .react-datepicker-wrapper {
    width: 100%;

    .react-datepicker__input-container {
      width: 100%;
      // position: relative;

      button {
        &.react-datepicker__close-icon {
          padding: 0;

          &:after {
            position: absolute;
            right: 10px;
            bottom: 10px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }

      .date-picker {
        width: calc(100% - 16px);
        padding: 5px;
        height: 30px;
        border-radius: 5px;
        border: 1px solid ${color.GREY};
        font-size: 12.5px;
        color: ${color.BLACK};
        outline: none;

        &::placeholder {
          text-transform: capitalize;
          font-size: 12px;
        }

        &:disabled {
          background-color: ${color.GREY};
        }
      }
    }
  }
`;
