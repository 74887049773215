import { css } from '@emotion/react';

import color from '../color';

export const paginationContainerStyle = (cur, total) => css`
  display: flex;
  width: 100%;
  margin: 20px 0 0 0 0;
  padding: 5px 0;

  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0;

    li {
      margin: 0 10px;
      cursor: pointer;
      font-size: 20px;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      color: ${color.BLUE};
      list-style-type: none;

      a {
        &:focus {
          outline: none;
        }
      };

      &:hover {
        color: ${color.ORANGE};
        transition: all 0.2s ease-in-out;
      };
      
      &::marker {
        color: ${color.WHITE};
      };

      &.next, &.previous {
        text-transform: capitalize;
        font-size: 14px;
      };

      &.previous {
        color: ${cur <= 1 ? color.GREY : color.ORANGE};
        cursor: ${cur === 1 ? 'not-allowed' : ''};
      };

      &.next {
        color: ${cur < total ? color.ORANGE : color.GREY};
        cursor: ${cur === total ? 'not-allowed' : ''};
      };

      &.selected {
        background-color: ${color.BLUE};
        color: ${color.WHITE};
        
        a {
          &:focus {
            outline: none;
          }
        };

        &:hover {
          color: ${color.PRIMARY_ORANGE};
        };
      };
    };
  };
`;

export const tabStyle = css`
  background-color: teal;
`;
