import PropTypes from 'prop-types';
// import { css } from '@emotion/react';
import Modal from 'react-modal';

// import Icon from '../Icon/Icon';

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    zIndex: 999,
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    boxShadow: '0px 3px 30px #0000000d',
  },
};

// const iconStyles = css`
//   position: absolute;
//   right: 10px;
//   top: 10px;

//   &:hover {
//     cursor: pointer;
//   }
// `;

const CustomModal = ({
  isModalOpen,
  closeModal,
  children,
  styles,
}) => (
  <Modal
    isOpen={isModalOpen}
    onRequestClose={closeModal}
    style={styles || customStyles}
    contentLabel="Example Modal"
    ariaHideApp={false}
  >
    {children}
  </Modal>
);

CustomModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  styles: PropTypes.shape({}),
};

CustomModal.defaultProps = {
  styles: {},
};

export default CustomModal;
