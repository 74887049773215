/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { css } from '@emotion/react';
import { useHistory } from 'react-router-dom';
import qs from 'query-string';
import { CommonLoading } from 'react-loadingg';
import api from 'api/server';
import {
  arrayToStringParser,
  datePickerToStringFormat,
  handleDownloadTemplate,
  stringToArrayParser,
} from 'helpers';
import Layout from 'components/layout';
import DetailModal from 'components/pages/FTP/Accepted/DetailModal/Modal';
import Sidebar from 'components/pages/FTP/Students/Sidebar/Sidebar';
import {
  Table, Button, Input, Icon,
} from 'components/common';
import tableConfig from 'components/config/FTP/Student/tableConfig';
import { buttonStyles, containerStyles, inputContainerStyles } from 'components/pages/FTP/Students/Student.styles';

const FULL_TIME = 'FULL_TIME';

const Students = () => {
  const history = useHistory();
  const [isModalDetailOpen, setModalDetail] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const queryString = history.location.search || '';
  const queryObject = qs.parse(queryString) || {};
  const modObj = {
    ...queryObject,
    payment_types: typeof (queryObject.payment_types) === 'string' ? [queryObject.payment_types] : queryObject.payment_types,
    program_type: FULL_TIME,
  };
  const [loading, setLoading] = useState(true);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [filterCondition, setFilterCondition] = useState({
    appliedProgramCode: '',
    appliedBatch: '',
    enrolledBatch: '',
    paymentType: [],
    paymentStatus: '',
    studentStatus: '',
    search: '',
  });
  const [dataStudents, setDataStudents] = useState([]);
  const [pagination, setPagination] = useState({});
  const [isCsvDownloading, setCsvDownloading] = useState(false);

  const csvHeaders = [
    { label: 'Applicant ID', key: 'applicant_id' },
    { label: 'Name', key: 'name' },
    { label: 'Email', key: 'email' },
    { label: 'Registered Batch', key: 'applied_batch' },
    { label: 'Enrolled Batch', key: 'enrolled_batch' },
    { label: 'Payment Type', key: 'payment_type' },
    { label: 'Payment Status', key: 'payment_status' },
    { label: 'Payment Due Date', key: 'next_payment_due_date' },
    { label: 'Student Status', key: 'student_status' },
  ];

  async function fetchData() {
    try {
      setLoading(true);
      const { data } = await api.getAllStudents(modObj);
      const response = data.data;
      setDataStudents(response.results);
      setPagination({
        current: response.current_page,
        total: response.last_page,
        totalData: response.total,
      });
    } catch (error) {
      if (error.response.status === 401) {
        Cookies.remove('access_token');
        history.replace('/sign-in');
      }
    } finally {
      setLoading(false);
    }
  }

  async function fetchDataCSV() {
    setCsvDownloading(true);
    try {
      const { data } = await api.getAllStudents({ ...modObj, limit: 20000 });
      handleDownloadTemplate(data.data.results, csvHeaders, 'student_export');
    } catch (error) {
      console.log(error, 'error fetch data csv for students');
    } finally {
      setCsvDownloading(false);
    }
  }

  const handleOpenAcceptedModal = (d) => {
    setModalDetail(true);
    setSelectedData(d);
  };

  useEffect(() => {
    fetchData();
    setFilterCondition(() => ({
      appliedProgramCode: queryObject.applied_program_code,
      appliedBatch: queryObject.applied_batch ? { label: queryObject.applied_batch, value: queryObject.applied_batch } : '',
      enrolledBatch: queryObject.enrolled_batch ? { label: queryObject.enrolled_batch, value: queryObject.enrolled_batch } : '',
      paymentType: stringToArrayParser(queryObject.payment_types),
      paymentStatus: queryObject.payment_status,
      studentStatus: queryObject.student_status,
      search: queryObject.search,
    }));
    setDateRange(
      [queryObject.applied_start_date ? new Date(queryObject.applied_start_date) : null,
        queryObject.applied_end_date ? new Date(queryObject.applied_end_date) : null,
      ],
    );
  }, [queryString]);

  const applicantData = dataStudents.map((d) => ({
    key: d.id,
    page: d.applicant_id,
    subpage: 'personal-data',
    id: d.applicant_id,
    registered_batch: d.applied_batch,
    enrolled_batch: d.enrolled_batch,
    name: d.name,
    payment_type: d.payment_type,
    payment_status: d.payment_status,
    payment_due: d.next_payment_due_date,
    status: d.student_status,
    action: (
      <div>
        <Button
          styles={buttonStyles}
          type="button"
          onClick={() => handleOpenAcceptedModal(d)}
        >
          <Icon name="chevron-right" />
        </Button>
      </div>
    ),
  }));

  const handleChange = (e) => {
    setFilterCondition({
      ...filterCondition,
      [e.target.name]: e.target.value,
    });
  };

  const handleSelect2Change = (e, name) => {
    setFilterCondition({
      ...filterCondition,
      [name]: e,
    });
  };

  const handleResetFilter = () => {
    setFilterCondition({
      appliedProgramCode: '',
      appliedBatch: '',
      enrolledBatch: '',
      paymentType: [],
      paymentStatus: '',
      studentStatus: '',
      search: '',
    });
    setDateRange([null, null]);
    fetchData().then(() => {
      history.push({
        pathname: history.pathname,
        search: '',
      });
    });
  };

  const handleSubmitFilter = (e) => {
    e.preventDefault();
    const queryFilter = {
      applied_program_code: filterCondition.appliedProgramCode,
      payment_types: arrayToStringParser(filterCondition.paymentType),
      payment_status: filterCondition.paymentStatus,
      student_status: filterCondition.studentStatus,
      applied_batch: filterCondition.appliedBatch?.value,
      enrolled_batch: filterCondition.enrolledBatch?.value,
      applied_start_date: dateRange[0] ? datePickerToStringFormat(JSON.stringify(dateRange[0])) : '',
      applied_end_date: dateRange[1] ? datePickerToStringFormat(JSON.stringify(dateRange[1])) : '',
      search: filterCondition.search,
      program_type: 'FULL_TIME',
    };
    const queryParam = qs.stringify(queryFilter, { skipNull: true, skipEmptyString: true });
    history.push({
      pathname: history.pathname,
      search: queryParam,
    });
  };

  return (
    <Layout
      containerStyles={isModalDetailOpen ? 'z-index: -1;' : ''}
      sidebar={(
        <Sidebar
          startDate={startDate}
          endDate={endDate}
          setDateRange={setDateRange}
          filterCondition={filterCondition}
          setFilterCondition={setFilterCondition}
          handleChange={handleChange}
          handleSelect2Change={handleSelect2Change}
          handleSubmit={handleSubmitFilter}
          handleReset={handleResetFilter}
          handleDownloadCSV={fetchDataCSV}
          isCsvDownloading={isCsvDownloading}
        />
      )}
    >
      <div css={containerStyles}>
        <span>
          total record: &nbsp;
          {pagination.totalData}
        </span>
        <form onSubmit={handleSubmitFilter} css={css`width: 20%;`}>
          <Input
            containerStyles={inputContainerStyles}
            type="text"
            name="search"
            value={filterCondition.search}
            onChange={handleChange}
            placeholder="search by name / email"
          />
        </form>
      </div>
      {
        loading ? <CommonLoading /> : (
          <Table
            containerStyles={css`height: 80vh;`}
            tableConfig={tableConfig}
            history={history}
            pagination={pagination}
            setPagination={setPagination}
            data={applicantData}
          />
        )
      }
      <DetailModal
        isModalOpen={isModalDetailOpen}
        setModal={setModalDetail}
        data={selectedData}
      />
    </Layout>
  );
};
export default Students;
