/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import Pagination from '../Pagination/Pagination';
import {
  tableContainerStyles,
  tableStyles,
  tableHeaderContainerStyles,
  tableHeaderStyles,
  tableBodyContainerStyles,
  tableDataContainerStyles,
  linkStyles,
  tableDataStyles,
} from './Table.styles';

const Table = ({
  history,
  containerStyles,
  tableHeaderContainerCustomStyles,
  styles,
  tableBodyStyles,
  tableConfig,
  showPagination,
  pagination,
  setPagination,
  data,
  dataContainerStyles,
  isHoverable,
}) => (
  <div css={[tableContainerStyles, containerStyles]}>
    <div css={[tableStyles, styles]}>
      <div css={[tableHeaderContainerStyles, tableHeaderContainerCustomStyles]}>
        {
          tableConfig.map(({
            key,
            label,
            ratio,
            textAlign,
          }) => (
            <div key={key} css={tableHeaderStyles(ratio)} className={textAlign}>
              <span style={{ fontSize: 14, textTransform: 'capitalize' }}>{label}</span>
            </div>
          ))
      }
      </div>
      <div css={[tableBodyContainerStyles, tableBodyStyles]}>
        {
          data.map((d) => (
            <div key={d.key} css={[tableDataContainerStyles, dataContainerStyles]}>
              {
                tableConfig.map(({
                  key,
                  ratio,
                  textAlign,
                  component,
                }) => (
                  key === 'action' || key === 'name' || key === 'id' ? (
                    <div key={d.key} css={tableDataStyles(ratio)} className={textAlign}>
                      {component(d[key])}
                    </div>
                  ) : (
                    <a
                      key={key}
                      css={linkStyles(ratio)}
                      className={isHoverable ? textAlign : `${textAlign} inactive`}
                      href={isHoverable ? `/${d.id}/${d.subpage ? d.subpage : 'personal-data'}${d.program ? `?program=${d.program || 'ftp'}` : ''}` : ''}
                    >
                      {component(d[key])}
                    </a>
                  )
                ))
              }
            </div>
          ))
        }
      </div>
    </div>
    {
      showPagination && (
        <Pagination
          history={history}
          pagination={pagination}
          setPagination={setPagination}
        />
      )
    }
  </div>
);

Table.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  containerStyles: PropTypes.shape({}),
  tableHeaderContainerCustomStyles: PropTypes.shape({}),
  dataContainerStyles: PropTypes.shape({}),
  styles: PropTypes.shape({}),
  tableBodyStyles: PropTypes.shape({}),
  tableConfig: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      label: PropTypes.string,
      ratio: PropTypes.number,
      textAlign: PropTypes.string,
    }),
  ).isRequired,
  showPagination: PropTypes.bool,
  pagination: PropTypes.shape({
    current: PropTypes.number,
    total: PropTypes.number,
  }),
  setPagination: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isHoverable: PropTypes.bool,
};

Table.defaultProps = {
  history: { push: () => {} },
  showPagination: true,
  tableHeaderContainerCustomStyles: css``,
  containerStyles: css``,
  dataContainerStyles: css``,
  styles: css``,
  tableBodyStyles: css``,
  isHoverable: true,
  pagination: {},
  setPagination: () => {},
};

export default Table;
