/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import ReactSelect from 'react-select';
import api from 'api/server';
import { Modal, Select, Button } from 'components/common';
import useDebounce from 'hooks/useDebounce';

import * as styles from './CreateInterviewModal.styles';

const CreateInterviewModal = ({ fetchData, isCreateModalOpen, setCreateModal }) => {
  const [campusOptions, setCampusOptions] = useState([]);
  const pic = Cookies.get('pic');
  const [users, setUsers] = useState([]);
  const [formData, setFormData] = useState({
    testType: 'online',
    campusLocation: 1,
    interviewDate: new Date(),
    interviewUrl: '',
    interviewer: '',
    applicants: [],
  });
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  async function fetchCampusLocations() {
    const { data } = await api.getAllCampusLocations();
    const optionsArr = data.data.map((d) => ({
      label: d.location,
      value: d.id,
    }));
    setCampusOptions(optionsArr);
  }

  async function fetchAllApplicants() {
    const { data } = await api.getInterviewApplicants({
      search: debouncedSearchTerm, is_full_time: true,
    });
    const modifiedArr = data.data.results.map((user) => ({
      label: `${user.first_name} ${user.last_name} - ${user.id}`,
      value: user.id,
    }));
    setUsers(modifiedArr);
  }

  useEffect(() => {
    fetchCampusLocations();
    fetchAllApplicants();
  }, []);

  useEffect(() => {
    fetchAllApplicants();
  }, [debouncedSearchTerm]);

  const handleChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleDateChange = (d) => {
    setFormData((prevState) => ({
      ...prevState,
      interviewDate: d,
    }));
  };

  const handleApplicants = (d) => {
    setFormData((prevState) => ({
      ...prevState,
      applicants: d,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const applicantArr = formData.applicants.map(({ value }) => value);
      const payload = {
        campus_location_id: +formData.campusLocation,
        interviewed_by: pic,
        scheduled_test_at: dayjs(formData.interviewDate).format('YYYY-MM-DDTHH:mm:ssZ'),
        test_type: formData.testType,
        online_interview_url: formData.interviewUrl,
        applicant_ids: applicantArr,
      };
      const { data } = await api.createTestInvites(payload);
      if (data.status) await fetchData();
    } catch (error) {
      console.log(error, 'error create test invites');
    } finally {
      setCreateModal(false);
    }
  };

  return (
    <Modal
      styles={styles.modalStyles}
      isModalOpen={isCreateModalOpen}
      setModal={setCreateModal}
      closeModal={() => setCreateModal(false)}
    >
      <form onSubmit={handleSubmit} css={styles.formStyles}>
        <div css={styles.formRowContainerStyles}>
          <label>Test type</label>
          <Select
            styles={styles.selectStyles}
            options={[
              {
                label: 'online',
                value: 'online',
              },
              {
                label: 'on site',
                value: 'on_site',
              },
            ]}
            value={formData.testType}
            onChange={handleChange}
            name="testType"
          />
        </div>
        <div css={styles.formRowContainerStyles}>
          <label>Campus Location</label>
          <Select
            options={campusOptions}
            styles={styles.selectStyles}
            value={formData.campusLocation}
            onChange={handleChange}
            name="campusLocation"
          />
        </div>
        <div css={styles.formRowContainerStyles}>
          <label>Interview date</label>
          <div css={styles.datepickerContainerStyles}>
            <DatePicker
              selected={formData.interviewDate}
              onChange={handleDateChange}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              timeCaption="Time"
              dateFormat="MMMM d, yyyy h:mm aa"
            />
          </div>
        </div>
        <div css={styles.formRowContainerStyles}>
          <label>Online interview Link</label>
          <textarea
            rows={4}
            className="url"
            css={styles.inputStyles}
            type="text"
            value={formData.interviewUrl}
            onChange={handleChange}
            name="interviewUrl"
          />
        </div>
        <div css={styles.formRowContainerStyles}>
          <label>Applicants</label>
          <ReactSelect
            isMulti
            name="applicants"
            options={users}
            className="applicant-select"
            classNamePrefix="applicant-select"
            placeholder="Add applicants"
            onChange={handleApplicants}
            onInputChange={(e) => setSearchTerm(e)}
            value={formData.applicants}
          />
        </div>
        <Button type="submit">submit</Button>
      </form>
    </Modal>
  );
};

CreateInterviewModal.propTypes = {
  fetchData: PropTypes.func.isRequired,
  isCreateModalOpen: PropTypes.bool.isRequired,
  setCreateModal: PropTypes.func.isRequired,
};

export default CreateInterviewModal;
