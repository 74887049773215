import { css } from '@emotion/react';

import color from '../color';

export const selectContainerStyles = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 40px;
`;

export const selectStyles = css`
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  cursor: pointer;
  line-height: 1.5;
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  text-transform: capitalize;
  border: 1px solid ${color.GREY};
  font-family: "Roboto", sans-serif;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='20' viewBox='0 0 25 10' width='20' xmlns='http://www.w3.org/2000/svg'><path d='M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 98%;
  background-position-y: 5px;
  -webkit-appearance: none;
  line-height: 13px;

  &:disabled {
    cursor: not-allowed;
    background-color: ${color.GREY};
    color: black;
    border-color: ${color.BLACK};
  };

  option {
    font-family: "Roboto",sans-serif;
    text-transform: capitalize;
  };

  &:hover, &:focus {
    border-color: ${color.ORANGE};
    font-family: "Roboto", sans-serif;
  };
  
  &:disabled {
    background-color: ${color.GREY};
    cursor: not-allowed;
  };
`;

export const selectLabelStyles = css`
  text-transform: capitalize;
  font-size: 16px;
  // margin: 0 0 10px;
`;
