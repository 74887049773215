const tableConfig = [
  {
    key: 'batch',
    label: 'batch',
    ratio: 0.2,
    textAlign: 'left',
    component: (d) => (
      <span>
        {d}
      </span>
    ),
  },
  {
    key: 'final_score',
    label: 'final score',
    ratio: 0.2,
    textAlign: 'left',
    component: (d) => (
      <span>
        {d}
      </span>
    ),
  },
  {
    key: 'phase',
    label: 'phase',
    ratio: 0.2,
    textAlign: 'left',
    component: (d) => (
      <span>
        {d}
      </span>
    ),
  },
  {
    key: 'instructor',
    label: 'instructor',
    ratio: 0.2,
    textAlign: 'left',
    component: (d) => (
      <span>
        {d}
      </span>
    ),
  },
  {
    key: 'status',
    label: 'status',
    ratio: 0.2,
    textAlign: 'left',
    component: (d) => (
      <span>
        {d}
      </span>
    ),
  },
];

export default tableConfig;
