/** @jsxImportSource @emotion/react */
/* eslint-disable react/button-has-type */
import { css } from '@emotion/react';
import PropTypes from 'prop-types';

import { buttonStyles } from './Button.styles';

const Button = ({
  type,
  styles,
  onClick,
  children,
  disabled,
  className,
}) => (
  <button
    className={className}
    css={[buttonStyles, styles]}
    onClick={onClick}
    type={type}
    disabled={disabled}
  >
    {children}
  </button>
);

Button.propTypes = {
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  styles: PropTypes.shape({}),
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

Button.defaultProps = {
  type: 'button',
  styles: css``,
  onClick: () => {},
  disabled: false,
  className: '',
};

export default Button;
