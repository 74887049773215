import { css } from '@emotion/react';
import color from 'components/common/color';

export const containerStyles = css`
  padding: 0 50px;
`;

export const searchContainerStyles = css`
  display: flex;
  justify-content: space-between;
  width: calc(100% - 20px);
  padding: 0 10px;
  align-items: center;
`;

export const inputStyles = css`
  text-transform: none;
  padding-left: 10px;
  height: 30px;
  width: 250px;
`;

export const buttonStyles = css`
  width: fit-content;
  margin: 20px 0 0;
`;

export const tableHeaderContainerStyles = css`
  width: 100%;
`;

export const tableBodyStyles = css`
  width: 100%;
`;

export const buttonNameStyles = css`
  color: ${color.BLACK};
  font-size: 14px!important;

  &:hover {
    cursor: pointer;
    color: ${color.ORANGE}!important;
  }
`;
