import axios from 'axios';
import Cookies from 'js-cookie';

const signOut = () => {
  const token = Cookies.get('access_token');
  return (
    axios({
      method: 'POST',
      url: `${process.env.REACT_APP_USER_SERVICE_URL}/auth/logout`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  );
};

export default signOut;
