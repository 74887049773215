/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { datepickerContainerStyles } from './Datepicker.styles';

const CustomDatePicker = ({
  containerStyles,
  startDate,
  endDate,
  placeholder,
  setDateRange,
}) => (
  <div css={[datepickerContainerStyles, containerStyles]}>
    <DatePicker
      selectsRange
      className="date-picker"
      startDate={startDate}
      endDate={endDate}
      onChange={(update) => {
        setDateRange(update);
      }}
      isClearable
      placeholderText={placeholder}
    />
  </div>
);

CustomDatePicker.propTypes = {
  containerStyles: PropTypes.shape({}),
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  placeholder: PropTypes.string,
  setDateRange: PropTypes.func.isRequired,
};

CustomDatePicker.defaultProps = {
  startDate: null,
  endDate: null,
  containerStyles: '',
  placeholder: '',
};

export default CustomDatePicker;
