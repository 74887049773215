/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import { css } from '@emotion/react';
import Cookies from 'js-cookie';
import { useParams, useHistory } from 'react-router-dom';
import Layout from 'components/layout';
import { Table } from 'components/common';
import * as styles from 'components/pages/Applicant/MoveProgram/MoveProgram.styles';
import tableConfig from 'components/config/ApplicantDetails/MoveProgram/tableConfig';
import api from 'api/server/applicantDetails';
import MoveProgramComponent from 'components/pages/Applicant/MoveProgram/MoveProgram';

const MoveProgram = () => {
  // const picName = Cookies.get('pic');
  const history = useHistory();
  const { id } = useParams();
  const [notif, setNotif] = useState({
    code: 200,
    showNotif: false,
    status: false,
    message: '',
  });
  const [newProgram, setNewProgram] = useState({
    currentBatchId: '',
    updatedBatchId: '',
    paymentTypeId: '',
    paymentStatus: '',
    applicantStatusId: '',
    studentStatusId: '',
    pic: '',
    notes: '',
  });
  const [tableData, setTableData] = useState([]);

  async function fetchStudentJourneys() {
    try {
      const { data } = await api.getAllStudentJourneys(id);
      const modifiedArr = data.data.student_journey.map((d) => (
        {
          key: d.id,
          batch: d.batch_detail.code,
          final_score: d.final_score,
          phase: d.phase,
          instructor: d.buddy_detail.full_name,
          status: d.status,
        }
      ));
      setTableData(modifiedArr);
    } catch (error) {
      if (error.response.status === 404) {
        setNotif({
          code: 404,
          showNotif: true,
          status: false,
          message: error.response.data.errors[0],
        });
      } else if (error.response.status === 401) {
        Cookies.remove('access_token');
        history.replace('/sign-in');
      }
    }
  }

  useEffect(() => {
    fetchStudentJourneys();
  }, []);

  const handleChange = (e) => {
    setNewProgram({
      ...newProgram,
      [e.target.name]: e.target.value,
    });
  };

  const handleSelect2Change = (e) => {
    setNewProgram({
      ...newProgram,
      updatedBatchId: e,
    });
  };

  async function handleSubmit(e) {
    e.preventDefault();
    setNotif({
      showNotif: false,
      status: true,
      message: '',
    });
    const {
      updatedBatchId,
      currentBatchId,
      paymentTypeId,
      applicantStatusId,
      studentStatusId,
      notes,
      pic,
      paymentStatus,
    } = newProgram;

    const payload = {
      notes,
      pic,
      payment_status: paymentStatus,
      updated_batch_id: updatedBatchId ? +updatedBatchId.value : +currentBatchId,
      payment_type_id: +paymentTypeId,
      applicant_status_id: +applicantStatusId,
      student_status_id: +studentStatusId,
    };

    if (
      !payload.updated_batch_id
        || !payload.applicant_status_id
        || !payload.payment_type_id
        || !payload.pic
    ) {
      setNotif({
        showNotif: true,
        status: false,
        message: 'must fill updated batch, applicant status, PIC and payment type',
      });
      return false;
    }

    Object.keys(payload).forEach((key) => {
      if (!payload[key]) {
        delete payload[key];
      }
    });
    try {
      const { data } = await api.editApplicantStatus(id, payload);
      if (data.status) {
        setNotif({
          showNotif: true,
          status: true,
          message: 'successfully updated applicant status',
        });
        fetchStudentJourneys();
      }
    } catch (error) {
      setNotif({
        showNotif: true,
        status: false,
        message: error.response.data.errors[0].detail,
      });
    }
    return true;
  }

  return (
    <Layout
      containerStyles={styles.layoutContainerStyles}
      childrenContainerStyles={styles.childrenContainerStyles}
      navbarStyles={styles.navbarContainerStyles}
    >
      <div css={styles.notificationContainerStyles}>
        {
          notif.showNotif && notif.code !== 404 && (
            <div css={styles.notificationBannerStyles(notif.status)}>
              <span>{notif.message}</span>
            </div>
          )
        }
      </div>
      <MoveProgramComponent
        data={newProgram}
        setData={setNewProgram}
        handleChange={handleChange}
        handleSelect2Change={handleSelect2Change}
        handleSubmit={handleSubmit}
      />
      <div css={css`width: 100%; display: flex; align-items: center; margin: 10px 0 0 0`}>
        {
          notif.code === 404 ? (
            <div css={styles.notificationBannerStyles(notif.status)}>
              <span>{notif.message}</span>
            </div>
          ) : (
            <Table
              containerStyles={styles.tableContainerStyles}
              tableConfig={tableConfig}
              showPagination={false}
              data={tableData}
              isHoverable={false}
            />
          )
        }
      </div>
    </Layout>
  );
};

export default MoveProgram;
