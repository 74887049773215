/* eslint-disable */
import React from 'react';

const tableConfig = [

  {
    key: 'id',
    label: 'ID',
    ratio: 0.05,
    textAlign: 'left',
    component: (d) => (
      <span style={{ padding: 5 }} >
        {d}
      </span>
    ),
  },
  {
    key: 'name',
    label: 'name',
    ratio: 0.3,
    textAlign: 'left',
    component: (d) => (
      <span style={{ padding: '0 5px' }}>
        {d}
      </span>
    ),
  },
  {
    key: 'programCode',
    label: 'program',
    ratio: 0.3,
    textAlign: 'left',
    component: (d) => (
      <span style={{ padding: '0 5px' }}>
        {d}
      </span>
    ),
  },
  {
    key: 'enrolled_batch',
    label: 'enrolled batch',
    ratio: 0.3,
    textAlign: 'left',
    component: (d) => (
      <span>
        {d}
      </span>
    ),
  },
  // {
  //   key: 'program_location',
  //   label: 'program location',
  //   ratio: 0.15,
  //   textAlign: 'center',
  //   component: (d) => (
  //     <span>
  //       {d}
  //     </span>
  //   ),
  // },
  // {
  //   key: 'price',
  //   label: 'price',
  //   ratio: 0.125,
  //   component: (d) => (
  //     <span>
  //       {d}
  //       {/* {d} */}
  //     </span>
  //   ),
  // },
  // {
  //   key: 'payment_option',
  //   label: 'payment option',
  //   ratio: 0.1,
  //   textAlign: 'left',
  //   component: (d) => (
  //     <span style={{ padding: '0 5px' }}>
  //       {/* {fullCalendarFormat(d)} */}
  //       {d ? d.toUpperCase() : ''}
  //     </span>
  //   ),
  // },
  {
    key: 'voucher',
    label: 'voucher code',
    ratio: 0.3,
    textAlign: 'left',
    component: (d) => (
      <span style={{  }}>
        {/* {fullCalendarFormat(d)} */}
        {d}
      </span>
    ),
  },
];

export default tableConfig;
