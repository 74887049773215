/* eslint-disable */
import { fullCalendarFormat } from 'helpers';
import { tableValueStyles, linkStyles } from 'components/pages/FTP/Applicants/TestHistory/TestHistory.styles';

const tableConfig = [
  {
    key: 'type',
    label: 'interview type',
    ratio: 0.15,
    textAlign: 'center',
    component: (d) => (
      <span css={tableValueStyles}>
        {d}
      </span>
    ),
  },
  {
    key: 'date',
    label: 'interview date',
    ratio: 0.15,
    textAlign: 'center',
    component: (d) => (
      <span>
        {fullCalendarFormat(d)}
      </span>
    ),
  },
  {
    key: 'link',
    label: 'link',
    ratio: 0.35,
    textAlign: 'center',
    component: (d) => (
      <span css={linkStyles} className="lowercase">
        { d ? d.split('|')[0] : ''}
      </span>
    ),
  },
  {
    key: 'score',
    label: 'score',
    ratio: 0.05,
    textAlign: 'center',
    component: (d) => (
      <span css={tableValueStyles}>
        {d}
      </span>
    ),
  },
  {
    key: 'result',
    label: 'test results',
    ratio: 0.15,
    textAlign: 'center',
    component: (d) => (
      <span css={tableValueStyles}>
        {d ? d.replace('_', ' ') : ''}
      </span>
    ),
  },
  {
    key: 'notes',
    label: 'notes',
    ratio: 0.1,
    textAlign: 'center',
    component: (d) => (
      <span css={tableValueStyles}>
        {d}
      </span>
    ),
  },
  {
    key: 'action',
    label: 'action',
    ratio: 0.05,
    textAlign: 'right',
    component: (d) => d,
  },
];

export default tableConfig;
