import axios from 'axios';
import Cookies from 'js-cookie';

const getAllUsers = () => {
  const token = Cookies.get('access_token');
  return (
    axios({
      method: 'GET',
      url: `${process.env.REACT_APP_USER_SERVICE_URL}/companies/users?role=admission&limit=50`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  );
};

export default getAllUsers;
