import Cookies from 'js-cookie';

const getSession = () => {
  const jwt = Cookies.get('access_token');
  if (jwt) {
    return jwt;
  }
  return null;
};

export default getSession;
