/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import PropTypes from 'prop-types';

import { inputContainerStyles, inputStyles, inputLabelStyles } from './Input.styles';

const Input = ({
  containerStyles,
  styles,
  type,
  label,
  labelStyles,
  name,
  value,
  onChange,
  disabled,
  className,
  placeholder,
  ...rest
}) => (
  <div className={className} css={[inputContainerStyles, containerStyles]}>
    <label css={[inputLabelStyles, labelStyles]}>{label}</label>
    <input
      css={[inputStyles, styles]}
      onChange={onChange}
      disabled={disabled}
      name={name}
      type={type}
      value={value}
      placeholder={placeholder}
      {...rest}
    />
  </div>
);

Input.propTypes = {
  containerStyles: PropTypes.shape({}),
  styles: PropTypes.shape({}),
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  labelStyles: PropTypes.shape({}),
  type: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  placeholder: PropTypes.string,
};

Input.defaultProps = {
  containerStyles: css``,
  styles: css``,
  label: '',
  value: '',
  labelStyles: css``,
  disabled: false,
  className: '',
  placeholder: '',
};

export default Input;
