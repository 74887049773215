import dayjs from 'dayjs';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';

TimeAgo.addDefaultLocale(en);

const timeAgo = new TimeAgo('en-US');

const monthList = {
  '01': 'January',
  '02': 'February',
  '03': 'March',
  '04': 'April',
  '05': 'May',
  '06': 'June',
  '07': 'July',
  '08': 'August',
  '09': 'September',
  10: 'October',
  11: 'November',
  12: 'December',
};

export const fullCalendarFormat = (d) => {
  if (d) {
    return dayjs(d).format('DD MMM YYYY');
  }
  return '';
};

export const fullCalendarTimeFormat = (d) => dayjs(d).format('DD MMMM YYYY, h:mm:ss A');

export const previousCalendarFormat = (d) => timeAgo.format(new Date(d).getTime());

export const calendarFormat = (d) => {
  if (d === '0001-01-01T00:00:00Z') {
    return '';
  }
  if (d) {
    const calendar = d.split('T')[0];
    // const time = d.split('T')[1].split('.')[0];
    const year = calendar.split('-')[0];
    const month = monthList[calendar.split('-')[1]];
    const date = calendar.split('-')[2];
    // const hour = time.split(':')[0];
    // const minute = time.split(':')[1];

    return `${date} ${month} ${year}`;
  }
  return '';
};

const numberChecker = (num) => {
  if (num < 10) {
    return `0${num}`;
  }
  return num;
};

export const datePickerToStringFormat = (d) => {
  if (!d) {
    return '';
  }
  const modifiedDate = dayjs(d.split('T')[0].replace('"', '')).add(1, 'day');
  const day = numberChecker(modifiedDate.get('D'));
  const month = numberChecker(modifiedDate.get('M') + 1);
  const year = numberChecker(modifiedDate.get('y'));
  return `${year}-${month}-${day}`;
};

export const stringDateToTableDate = (d) => {
  if (!d) {
    return '';
  }
  const time = d.split('T')[1].split('.')[0].split(':');
  const hour = time[0];
  const minute = time[1];

  return `${calendarFormat(d)} at ${hour}:${minute}`;
};
