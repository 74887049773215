const formValidator = (obj, action = 'create') => {
  let isValid = true;
  const resp = {
    type: 'error',
    message: '',
  };
  if (!obj.program_id) {
    resp.message = 'program is required';
    isValid = false;
  }
  if (!obj.campus_location_id) {
    resp.message = 'campus location is required!';
    isValid = false;
  }
  if (!obj.batch_type_id && action === 'create') {
    resp.message = 'batch type is required!';
    isValid = false;
  }
  if (!obj.base_price) {
    resp.message = 'base price is required!';
    isValid = false;
  }
  if (!obj.registration_start_date) {
    resp.message = 'registration start date is required!';
    isValid = false;
  }
  if (!obj.registration_end_date) {
    resp.message = 'registration end date is required!';
    isValid = false;
  }
  if (!obj.class_start_date) {
    resp.message = 'class start date is required!';
    isValid = false;
  }
  if (!obj.class_end_date) {
    resp.message = 'class end date is required!';
    isValid = false;
  }
  if (!obj.schedule) {
    resp.message = 'schedule is required!';
    isValid = false;
  }
  if (isValid) {
    return 'valid';
  }
  return resp;
};

export default formValidator;
