/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { fetchPaymentStatusOptions, millionsSeparator } from 'helpers';

import {
  Table, Button, Modal, Select, Input,
} from 'components/common';
import tableConfig from 'components/config/ApplicantDetails/PaymentDetail/tableConfig';
import {
  containerStyles, modalStyles, dataContainerStyles,
  buttonSubmitStyles, selectContainerStyles, tableContainerStyles,
  inputStyles, tableHeaderContainerStyles, tableBodyStyles,
} from './PaymentDetail.styles';

const PaymentDetail = ({
  tableData,
  isModalOpen,
  setModal,
  newInvoiceData,
  handleChange,
  handleSubmit,
}) => {
  const [paymentStatusOptions, setPaymentStatusOptions] = useState([]);

  useEffect(() => {
    fetchPaymentStatusOptions()
      .then((data) => {
        console.log(data);
        const options = data.filter((d) => d.value !== 'CUSTOM');
        setPaymentStatusOptions(options);
      });
  }, []);

  const handlePlaceholder = (value) => {
    switch (value) {
      case 'RSVP':
        return 'Min. Rp. 300.000';
      case 'PAYMENT_REPEAT':
        return 'Min. Rp. 1.000.000';
      case 'DEPOSIT_ACCESS':
        return 'Min. Rp. 100.000';
      case 'STEP_1':
        return 'Min. Rp. 1.000.000';
      case 'STEP_2':
        return 'Min. Rp. 5.000.000';
      default:
        return null;
    }
  };

  return (
    <div css={containerStyles}>
      <Table
        containerStyles={tableContainerStyles}
        tableHeaderContainerCustomStyles={tableHeaderContainerStyles}
        tableBodyStyles={tableBodyStyles}
        tableConfig={tableConfig}
        pagination={null}
        showPagination={false}
        data={tableData}
      />
      <Modal
        styles={modalStyles}
        isModalOpen={isModalOpen}
        setModal={setModal}
        closeModal={() => setModal(false)}
      >
        <form onSubmit={handleSubmit}>
          <div css={dataContainerStyles}>
            <label>create new invoice</label>
            <Select
              placeholder="select invoice status"
              name="paymentStatusName"
              containerStyles={selectContainerStyles}
              onChange={handleChange}
              options={paymentStatusOptions}
              value={newInvoiceData.paymentStatusName}
            />
          </div>
          {
            (newInvoiceData.paymentStatusName === 'STEP_1'
             || newInvoiceData.paymentStatusName === 'STEP_2'
             || newInvoiceData.paymentStatusName === 'RSVP'
             || newInvoiceData.paymentStatusName === 'PAYMENT_REPEAT'
             || newInvoiceData.paymentStatusName === 'DEPOSIT_ACCESS'
            )
            //  || newInvoiceData.paymentStatusName === 'STEP_2')
             && (
             <>
               <div css={dataContainerStyles}>
                 <label>invoice custom amount (optional)</label>
                 <Input
                   placeholder={handlePlaceholder(newInvoiceData.paymentStatusName)}
                   type="text"
                   name="customAmount"
                   styles={inputStyles}
                   onChange={handleChange}
                   value={millionsSeparator(newInvoiceData.customAmount)}
                 />
               </div>
             </>
             )
          }
          <Button
            styles={buttonSubmitStyles}
            type="submit"
            onClick={handleSubmit}
          >
            submit
          </Button>
        </form>
      </Modal>
    </div>
  );
};

PaymentDetail.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  setModal: PropTypes.func.isRequired,
  tableData: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    amount: PropTypes.number,
    is_paid: PropTypes.string,
    paid_at: PropTypes.string,
    payment_status: PropTypes.string,
    xendit_invoice_id: PropTypes.string,
    invoice_external_url: PropTypes.string,
  })).isRequired,
  newInvoiceData: PropTypes.shape({
    paymentStatusName: PropTypes.string,
    customAmount: PropTypes.number,
    notes: PropTypes.string,
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default PaymentDetail;
