import ftp from './ftp';
import ptp from './ptp';
import batches from './batches';
import batchTypes from './batchTypes';
import constants from './constants';
import applicantDetails from './applicantDetails';
import testInfo from './testinfo';

export default {
  ...ftp,
  ...ptp,
  ...batches,
  ...batchTypes,
  ...constants,
  ...applicantDetails,
  ...testInfo,
};
