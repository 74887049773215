import axiosInstance from '../../../helpers/axiosInstance';

export default {
  getAllFollowUpStatuses: () => axiosInstance().get('follow-up-statuses'),
  getAllPaymentTypes: () => axiosInstance().get('payment-types'),
  getAllPaymentStatuses: () => axiosInstance().get('payment-statuses'),
  getAllApplicantStatuses: () => axiosInstance().get('applicant-statuses'),
  getAllStudentStatuses: () => axiosInstance().get('student-statuses'),
  getAllCampusLocations: () => axiosInstance().get('campus-locations'),
  getAllProgramCodes: (qs = '') => axiosInstance().get('program-types', { params: qs }),
  getAllPrograms: (qs = '') => axiosInstance().get('programs', { params: qs }),
};
