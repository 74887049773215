const stringToArrayParser = (val) => {
  if (typeof (val) === 'object') {
    const arr = val.map((d) => ({
      label: d,
      value: d,
    }));
    return arr;
  }
  if (typeof (val) === 'string') {
    return [{ label: val.toLowerCase(), value: val }];
  }
  return [];
};

export default stringToArrayParser;
