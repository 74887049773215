/* eslint-disable */
import React from 'react';
import { fullCalendarTimeFormat } from 'helpers';

const tableConfig = [
  {
    key: 'id',
    label: 'ID',
    ratio: 0.05,
    textAlign: 'left',
    component: (d) => (
      <span style={{ fontSize: 12, margin: '0 5px' }}>
        {d}
      </span>
    ),
  },
  {
    key: 'date',
    label: 'applied date',
    ratio: 0.15,
    textAlign: 'left',
    component: (d) => (
      <span>
        {fullCalendarTimeFormat(d)}
      </span>
    ),
  },
  {
    key: 'followUpStatus',
    label: 'Follow up Status',
    ratio: 0.15,
    textAlign: 'left',
    component: (d) => (
      <span>
        {d}
      </span>
    ),
  },
  {
    key: 'lastContactedDate',
    label: 'contacted date',
    ratio: 0.2,
    textAlign: 'left',
    component: (d) => (
      <span>
        {d}
      </span>
    ),
  },
  {
    key: 'name',
    label: 'name',
    ratio: 0.2,
    textAlign: 'left',
    component: (d) => (
      <span>
        {d}
      </span>
    ),
  },
  // {
  //   key: 'email',
  //   label: 'email',
  //   ratio: 0.15,
  //   textAlign: 'left',
  //   component: (d) => (
  //     <span style={{ textTransform: 'none' }}>
  //       {d}
  //     </span>
  //   ),
  // },
  {
    key: 'phone',
    label: 'phone',
    ratio: 0.1,
    textAlign: 'left',
    component: (d) => (
      <span>
        {d}
      </span>
    ),
  },
  {
    key: 'batch',
    label: 'batch',
    ratio: 0.15,
    textAlign: 'left',
    component: (d) => (
      <span>
        {d}
      </span>
    ),
  },
  {
    key: 'price',
    label: 'price',
    ratio: 0.1,
    textAlign: 'left',
    component: (d) => (
      <span>
        {`Rp ${d.toLocaleString('en').replaceAll(',', '.')}`}
      </span>
    ),
  },
  {
    key: 'paymentStatus',
    label: 'payment status',
    ratio: 0.1,
    textAlign: 'left',
    component: (d) => (
      <span style={{ }}>
        {d?.replaceAll('_', ' ')}
      </span>
    ),
  },
  {
    key: 'payment',
    label: 'payment',
    ratio: 0.075,
    textAlign: 'left',
    component: (d) => (
      <span style={{ marginLeft: '5px' }}>
        {d?.toUpperCase()}
      </span>
    ),
  },
  // {
  //   key: 'code',
  //   label: 'code',
  //   ratio: 0.075,
  //   textAlign: 'left',
  //   component: (d) => (
  //     <span style={{ marginLeft: '5px' }}>
  //       {d}
  //     </span>
  //   ),
  // },
];

export default tableConfig;
