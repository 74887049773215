import { css } from '@emotion/react';

export const layoutContainerStyles = (isModalOpen) => css`
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: ${isModalOpen ? -1 : ''};
`;

export const childrenContainerStyles = css`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const navbarContainerStyles = css`
  width: calc(90% - 40px);
  padding: 0 10px;
  border: none;
`;

export const buttonContainerStyles = css`
  width: 100%;
  display: flex;
  justify-content: flex-start;
`;

export const notificationContainerStyles = css`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const notificationBannerStyles = (status) => css`
  background: ${status ? '#39A388' : '#FCD2D1'};
  width: 100%;
  display: flex;
  align-items: center;
  height: 30px;
  padding: 8px;
  margin: 20px 0;

  span {
    font-weight: 600;
    
    &:first-letter {
      text-transform: uppercase;
    }
  }
`;

export const containerStyles = css`
  display: flex;
  width: 100%;
  padding: 5px 0 10px;
  flex-direction: column;
`;

export const buttonStyles = css`
  width: fit-content;
`;

export const tableContainerStyles = css`
  margin: 20px auto 0;
`;

export const tableBodyDataStyles = css`
  display: flex;
  justify-content: center;
`;

export const modalStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    boxShadow: '0px 3px 30px #0000000d',
    width: '300px',
    overflow: 'hidden',
  },
};

export const dataContainerStyles = css`
  display: flex;
  flex-direction: column;
  margin: 0 0 10px;

  label {
    font-family: "Roboto", sans-serif;
    text-transform: capitalize;
    margin: 0 0 3px;
  };
`;

export const inputStyles = css`
  // width: 100%;
  margin: 5px 0;
  height: 25px;
`;

export const selectContainerStyles = css`
  width: 100%;
`;

export const buttonSubmitStyles = css`
  margin: 15px 0;
`;
