/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import Cookies from 'js-cookie';
import 'firebase/auth';
import { useHistory } from 'react-router-dom';
import { Button } from 'components/common';
import signIn from 'api/user/signin';

import {
  layoutContainerStyles,
  textStyles,
  buttonGoogleStyles,
  iconContainerStyles,
  textContainerStyles,
} from 'components/pages/signin/signin.stlyes';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const Signin = () => {
  const history = useHistory();
  const [errMessage, setErrMessage] = useState({
    show: false,
    text: '',
  });

  useEffect(() => {
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
    } else {
      firebase.app(); // if already initialized, use that one
    }
  }, []);

  const googleSignin = async () => {
    setErrMessage({
      show: false,
      text: '',
    });
    const provider = new firebase.auth.GoogleAuthProvider();
    try {
      const { user } = await firebase.auth().signInWithPopup(provider);
      firebase.auth().onAuthStateChanged(async (u) => {
        try {
          if (u) {
            if (firebase.auth().currentUser) {
              const token = await firebase.auth().currentUser.getIdToken();
              const rToken = await firebase.auth().currentUser.refreshToken;
              const payload = {
                grant_type: 'mfa_google',
                token_id: token,
                refresh_token: rToken,
              };
              const { data } = await signIn(payload);
              Cookies.set('pic', user.email);
              Cookies.set('access_token', data.data.access_token);
              Cookies.set('refresh_token', data.data.refresh_token);
              if (data.code === 200) history.push('/ftp/applicants');
            }
          }
        } catch (error) {
          setErrMessage({
            show: true,
            text: error.response.data.message,
          });
        }
      });
    } catch (error) {
      setErrMessage({
        show: true,
        text: error.response.data.message,
      });
    }
  };

  return (
    <div
      css={layoutContainerStyles}
    >
      <span css={textStyles}>Sign in to continue to Admission Backoffice</span>
      <Button
        type="button"
        onClick={googleSignin}
        styles={buttonGoogleStyles}
      >
        <div css={iconContainerStyles}>
          <i className="fab fa-google" />
        </div>
        <div css={textContainerStyles}>
          sign in with google
        </div>
      </Button>
      {
        errMessage.show && <span className="error" css={textStyles}>{errMessage.text}</span>
      }
    </div>
  );
};

export default Signin;
